import React, { useState } from "react";
import { saveBankCode } from "../../service/BankCodeRequest";

import {
  TextField,
  Button,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";

import { BankCodeFormBottonControl } from "./style/BankCodeStyle";

const userStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 10,
  },
});
export default function BankCodeAddForm({ handleAddClose, handleSubmit }) {
  const classes = userStyles();

  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankType, setBankType] = useState("");

  const handleSubmitData = async (e) => {
    e.preventDefault();

    let saveBankCodeDto = {
      bankName: bankName,
      bankCode: bankCode,
      bankType: bankType,
    };
    await saveBankCode(saveBankCodeDto);

    console.log("===save====success");

    handleSubmit();
  };
  return (
    <div>
      <Container>
        <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
          <Box>
            <TextField
              onChange={(e) => setBankName(e.target.value)}
              className={classes.field}
              id="standard-disabled"
              label="Bank Name"
              variant="standard"
            />

            <TextField
              id="bankCode"
              className={classes.field}
              label="Bank Code"
              variant="standard"
              onChange={(e) => setBankCode(e.target.value)}
            />
            <TextField
              id="bankType"
              className={classes.field}
              label="Bank Type"
              onChange={(e) => setBankType(e.target.value)}
              variant="standard"
            />
          </Box>
          <BankCodeFormBottonControl>
            <Button type="submit" variant="outlined" className="btn right">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={handleAddClose}
              className="btn right"
            >
              close
            </Button>
          </BankCodeFormBottonControl>
        </form>
      </Container>
    </div>
  );
}
