import React, { useState } from "react";
import { updateStoreAcquirerSettlementSummaryDto } from "../../service/StoreAcquirerSettlementSummaryRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    StoreAcquirerSettlementSummaryFormBottonControl,
} from './style/StoreAcquirerSettlementSummaryStyle';



const storeacquirersettlementsummaryStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function StoreAcquirerSettlementSummaryEditForm({handleEditClose, handleEditSubmit, editStoreAcquirerSettlementSummaryDto}) {
    const classes = storeacquirersettlementsummaryStyles();

    const [id, setId] = useState(editStoreAcquirerSettlementSummaryDto.id);

    const [status, setStatus] = useState(editStoreAcquirerSettlementSummaryDto.status);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('StoreAcquirerSettlementSummaryForm====');
        console.log('status===', status);


        const saveStoreAcquirerSettlementSummaryDto = {
            'id': id,
            'status': status
        };

        updateStoreAcquirerSettlementSummaryDto(saveStoreAcquirerSettlementSummaryDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"
                            value={status}

                        />
                    </Box>


                    <StoreAcquirerSettlementSummaryFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </StoreAcquirerSettlementSummaryFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

