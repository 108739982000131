import BankDetailEditForm from "./BankDetailEditForm";
import BankDetailForm from "./BankDetailForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  makeStyles,
  Grid,
  Input,
} from "@material-ui/core";

import {
  pageBankDetailDto,
  searchBankDetailDto,
} from "../../service/BankDetailRequest";

import { BankDetailAddButtonControl } from "./style/BankDetailStyle";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function BankDetailTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editBankDetailDto, setEditBankDetailDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [bankNameLike, setBankNameLike] = useState();

  const [bankAccountLike, setBankAccountLike] = useState();

  const [bankCodeLike, setBankCodeLike] = useState();


  const classes = useStyles();
  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      bankCodeLike: '',
      bankNameLike:'',
      bankAccountLike:'',
    }

    
  });

  const searchBankDetail = () => {
    console.log("======searchBankDetail", searchName);
    setSearchRefreshFlag(true);
  };

  const addBankDetail = () => {
    console.log("======handle add addBankDetail");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
     

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleSubmitData = () => {
 
    console.log("==handleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
 
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    
    
    page = page + 1;

    let bankDetailQueryVo = {
      index:1,
      pageSize: page,
      bankNameLike: bankNameLike ? bankNameLike : null,
      bankCodeLike: bankCodeLike ? bankCodeLike : null,
      bankAccountLike: bankAccountLike ? bankAccountLike : null,
  }
    initData(bankDetailQueryVo);
  };



  const bankDetailSubmit = (data) => {
    console.log("==bankDetailSubmit==data", data);

      setBankCodeLike(data.bankCodeLike);
      setBankNameLike(data.bankNameLike);
      setBankAccountLike(data.bankAccountLike);


      let bankDetailQueryVo = {
          index:1,
          pageSize: 10,
          bankNameLike: data.bankNameLike ? data.bankNameLike : null,
          bankCodeLike: data.bankCodeLike ? data.bankCodeLike : null,
          bankAccountLike: data.bankAccountLike ? data.bankAccountLike : null,
      }

      initData(bankDetailQueryVo);

  }

  const bankDetailReset = ()=>{
    reset();
    setBankAccountLike();
    setBankNameLike();
    setBankCodeLike();
  }
  const editBankDetailData = (tableMeta) => {

    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editBankDetailDto = data[row];
    setEditBankDetailDto(editBankDetailDto);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData =  async (bankDetailQueryVo) => {
    setIsLoading(true);
    

    try{
      console.log("bankDetailQueryVo==request==parameter:",bankDetailQueryVo);
      
     let bankDetailResposneDto = await pageBankDetailDto(bankDetailQueryVo);

      console.log("bankDetailResposneDto====",bankDetailResposneDto);
     

      console.log("==bankDetailResposneDto==data", bankDetailResposneDto.data);
      if (bankDetailResposneDto && bankDetailResposneDto.data) {
        setData(bankDetailResposneDto.data.records);
        setCount(bankDetailResposneDto.data.total);
      }



    }catch(e){
      console.log("initData==bankDetailQueryVo==error",e);
    }
    
   
    setPage(bankDetailQueryVo.index);
    setIsLoading(false);
  };

  const searchBankDetailData = (index, pageSize, searchName) => {
    setIsLoading(true);
    debugger;
    searchBankDetailDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let bankDetailQueryVo = {
      index:1,
      pageSize: 10,
  }
    initData(bankDetailQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
   
    
    console.log("===searchBankDetailData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchBankDetailData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      {
        name: "bankName",
        label: "Bank Name",
        options: {},
      },
      {
        name: "bankCode",
        label: "Bank Code",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            console.dir(tableMeta);
            return value;
          },
        },
      },
      {
        name: "bankAccount",
        label: "Bank Account",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editBankDetailData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
    <form >
    <Grid container spacing={3}>
          <Grid item xs={4}>

            <Paper className={classes.paper}>Bank name:
              <Controller
              name="bankNameLike"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
            </Paper>
          </Grid>

          <Grid item xs={4}>

            <Paper className={classes.paper}>Bank code:
              <Controller
              name="bankCodeLike"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
            </Paper>
          </Grid>


          <Grid item xs={4}>

          <Paper className={classes.paper}>Bank account:
            <Controller
            name="bankAccountLike"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>


          <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(bankDetailSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => bankDetailReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>

        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => addBankDetail()} variant={"outlined"} color="primary">Add</Button></Paper>
        </Grid>
       </Grid>
    </form>

     

      <MUIDataTable
        title={
          <Typography variant="h6">
            BankDetail list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit BankDetail</DialogTitle>
        <DialogContent>
          <BankDetailEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editBankDetailDto={editBankDetailDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle>Add BankDetail</DialogTitle>
        <DialogContent>
          <Paper>
            <BankDetailForm
              handleSubmit={handleSubmitData}
              handleAddClose={handleAddClose}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
