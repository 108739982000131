import React, { useState } from "react";
import { updateBusinessSettlementSummaryDto } from "../../service/BusinessSettlementSummaryRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    BusinessSettlementSummaryFormBottonControl,
} from './style/BusinessSettlementSummaryStyle';



const businesssettlementsummaryStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function BusinessSettlementSummaryEditForm({handleEditClose, handleEditSubmit, editBusinessSettlementSummaryDto}) {
    const classes = businesssettlementsummaryStyles();

    const [id, setId] = useState(editBusinessSettlementSummaryDto.id);

    const [status, setStatus] = useState(editBusinessSettlementSummaryDto.status);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('BusinessSettlementSummaryForm====');
        console.log('status===', status);


        const saveBusinessSettlementSummaryDto = {
            'id': id,
            'status': status
        };

        updateBusinessSettlementSummaryDto(saveBusinessSettlementSummaryDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"
                            value={status}

                        />
                    </Box>


                    <BusinessSettlementSummaryFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </BusinessSettlementSummaryFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

