import React, { useState } from "react";
import { updateDisplayMessageDto } from "../../service/DisplayMessageRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    DisplayMessageFormBottonControl,
} from './style/DisplayMessageStyle';



const displaymessageStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function DisplayMessageEditForm({handleEditClose, handleEditSubmit, editDisplayMessageDto}) {
    const classes = displaymessageStyles();

    const [id, setId] = useState(editDisplayMessageDto.id);

    const [failureReasonCode, setFailureReasonCode] = useState(editDisplayMessageDto.failureReasonCode);
    const [failureMessage, setFailureMessage] = useState(editDisplayMessageDto.failureMessage);
    const [displayMessage, setDisplayMessage] = useState(editDisplayMessageDto.displayMessage);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('DisplayMessageForm====');
        console.log('failureReasonCode===', failureReasonCode);
        console.log('failureMessage===', failureMessage);
        console.log('displayMessage===', displayMessage);


        const saveDisplayMessageDto = {
            'id': id,
            'failureReasonCode': failureReasonCode,
            'failureMessage': failureMessage,
            'displayMessage': displayMessage
        };

        updateDisplayMessageDto(saveDisplayMessageDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setFailureReasonCode(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Failure Reason Code"
                            variant="standard"
                            value={failureReasonCode}

                        />

                        <TextField id="failureMessage" className={classes.field} label="Failure Message" variant="standard" onChange={((e)=> setFailureMessage(e.target.value))} value= {failureMessage} />
                        <TextField id="displayMessage" className={classes.field} label="DisplayMessage" onChange={((e)=> setDisplayMessage(e.target.value))} variant="standard" value={displayMessage} />
                    
                    </Box>


                    <DisplayMessageFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </DisplayMessageFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

