import React, { useState } from "react";
import { updateEmailJobDto } from "../../service/EmailJobRequest";
import { SignalWifiStatusbar4BarSharp } from "@mui/icons-material";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    EmailJobFormBottonControl,
} from './style/EmailJobStyle';



const emailjobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function EmailJobEditForm({handleEditClose, handleEditSubmit, editEmailJobDto}) {
    const classes = emailjobStyles();

    const [id, setId] = useState(editEmailJobDto.id);

    const [status, setStatus] = useState(editEmailJobDto.status);
    const [emailType, setEmailType] = useState(editEmailJobDto.emailType);
    const [emailFormat, setEmailFormat] = useState(editEmailJobDto.emailFormat);
    const [emailAddress, setEmailAddress] = useState(editEmailJobDto.emailAddress);
    const [emailTitle, setEmailTitle] = useState(editEmailJobDto.emailTitle);
    const [emailBody, setEmailBody] = useState(editEmailJobDto.emailBody);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('EmailJobForm====');
        console.log('status===', status);
        console.log('emailType===', emailType);
        console.log('emailFormat===', emailFormat);
        console.log('emailAddress===', emailAddress);
        console.log('emailTitle===', emailTitle);
        console.log('emailBody===', emailBody);


        const saveEmailJobDto = {
            'id': id,
            'status': status,
            'emailType': emailType,
            'emailFormat': emailFormat,
            'emailAddress': emailAddress,
            'emailTitle': emailTitle,
            'emailBody': emailBody
        };

        updateEmailJobDto(saveEmailJobDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                    <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"
                            value={status}

                        />
                        <TextField
                            onChange={((e)=> setEmailType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Email Type"
                            variant="standard"
                            value={emailType}

                        />

                        <TextField id="emailFormat" className={classes.field} label="Email Format" variant="standard" onChange={((e)=> setEmailFormat(e.target.value))} value= {emailFormat} />
                        <TextField id="emailAddress" className={classes.field} label="Email Address" onChange={((e)=> setEmailAddress(e.target.value))} variant="standard" value={emailAddress} />
                        <TextField id="emailTitle" className={classes.field} label="Email Title" variant="standard" onChange={((e)=> setEmailTitle(e.target.value))} value= {emailTitle} />
                        <TextField id="emailBody" className={classes.field} label="Email Body" onChange={((e)=> setEmailBody(e.target.value))} variant="standard" value={emailBody} />
                    
                    </Box>


                    <EmailJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </EmailJobFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

