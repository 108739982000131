import React, { useState } from "react";
import { saveEmailJob } from "../../service/EmailJobRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    EmailJobFormBottonControl,
} from './style/EmailJobStyle';



const emailJobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function EmailJobForm({handleAddClose, handleSubmit}) {
    const classes = emailJobStyles();


    const [status, setStatus] = useState("");
    const [emailType, setEmailType] = useState("");
    const [emailFormat, setEmailFormat] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailTitle, setEmailTitle] = useState("");
    const [emailBody, setEmailBody] = useState("");

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('EmailJobForm====');
        console.log('status===', status);
        console.log('emailType===', emailType);
        console.log('emailFormat===', emailFormat);
        console.log('emailAddress===', emailAddress);
        console.log('emailTitle===', emailTitle);
        console.log('emailBody===', emailBody);


        const saveEmailJobDto = {
            'status': status,
            'emailType': emailType,
            'emailFormat': emailFormat,
            'emailAddress': emailAddress,
            'emailTitle': emailTitle,
            'emailBody': emailBody
        };
        await saveEmailJob(saveEmailJobDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                    <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"

                        />
                        <TextField
                            onChange={((e)=> setEmailType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Email Type"
                            variant="standard"

                        />

                        <TextField id="emailFormat" className={classes.field} label="Email Format" variant="standard" onChange={((e)=> setEmailFormat(e.target.value))} />
                        <TextField id="emailAddress" className={classes.field} label="Email Address" onChange={((e)=> setEmailAddress(e.target.value))} variant="standard" />
                        <TextField id="emailTitle" className={classes.field} label="Email Title" variant="standard" onChange={((e)=> setEmailTitle(e.target.value))} />
                        <TextField id="emailBody" className={classes.field} label="Email Body" onChange={((e)=> setEmailBody(e.target.value))} variant="standard" />
                    
                    </Box>

                    <EmailJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </EmailJobFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

