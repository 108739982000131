import styled from "styled-components";

export const AcquirerStoreAddButtonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:10px;
column-gap: 20px;
.btn{

}


.right{
   
  
}`;


export const AcquirerStoreFormBottonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:30px;
column-gap: 20px;
 
.btn{

}


.right{
    
}`;

export const AcquirerStoreCloseBottonControl = styled.div`
  margin-left: 50%;
  display: flex;

  margin-bottom: 30px;
  column-gap: 20px;

  .btn {
  }

  .right {
  }
`;