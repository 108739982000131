import login from "./login/LoginSlice";
import thunk from "redux-thunk";
import transaction from "./transaction/TransactionSlice";
import { combineReducers } from "redux";
import { applyMiddleware, configureStore } from "@reduxjs/toolkit";

const reducer = combineReducers({
  
  login,
  transaction,
});

const store = configureStore({
  reducer,
   
});

export default store;
