import request from "./ConfigRequest";

export const pageAcquireStoreSettlementDetailSettlement = (acquireStoreSettlementDetailQueryVo)=>{
    return request({
        url: 'acquireStoreSettlementDetail/pageAcquireStoreSettlementDetailSettlement',
        params: {
            index:acquireStoreSettlementDetailQueryVo.index,
            pageSize:acquireStoreSettlementDetailQueryVo.pageSize,
            id:acquireStoreSettlementDetailQueryVo.id,
            settlementMethod: acquireStoreSettlementDetailQueryVo.settlementMethod,
            transactionId: acquireStoreSettlementDetailQueryVo.transactionId,
            acquirerType: acquireStoreSettlementDetailQueryVo.acquirerType,
            creationTimeStart: acquireStoreSettlementDetailQueryVo.creationTimeStart,
            creationTimeEnd: acquireStoreSettlementDetailQueryVo.creationTimeEnd  ,
            settlementTimeStart: acquireStoreSettlementDetailQueryVo.settlementTimeStart  ,
            settlementTimeEnd: acquireStoreSettlementDetailQueryVo.settlementTimeEnd  ,
            storeId: acquireStoreSettlementDetailQueryVo.storeId  ,
            creationTime: acquireStoreSettlementDetailQueryVo.creationTime,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};

 

export const selectAcquireStoreSettlementDetailSettlementExportDtoList = async (acquireStoreSettlementDetailQueryVo) => {
    return request({
      url: "acquireStoreSettlementDetail/selectAcquireStoreSettlementDetailSettlementExportDtoList",
      params: {
        index:acquireStoreSettlementDetailQueryVo.index,
        pageSize:acquireStoreSettlementDetailQueryVo.pageSize,
        id:acquireStoreSettlementDetailQueryVo.id,
        settlementMethod: acquireStoreSettlementDetailQueryVo.settlementMethod,
        transactionId: acquireStoreSettlementDetailQueryVo.transactionId,
        acquirerType: acquireStoreSettlementDetailQueryVo.acquirerType,
        creationTimeStart: acquireStoreSettlementDetailQueryVo.creationTimeStart,
        creationTimeEnd: acquireStoreSettlementDetailQueryVo.creationTimeEnd  ,
        settlementTimeStart: acquireStoreSettlementDetailQueryVo.settlementTimeStart  ,
        settlementTimeEnd: acquireStoreSettlementDetailQueryVo.settlementTimeEnd  ,
        storeId: acquireStoreSettlementDetailQueryVo.storeId  ,
        creationTime: acquireStoreSettlementDetailQueryVo.creationTime,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      'responseType': 'blob'
    });
  };


  export const reconcileAcquireStoreSettlementDetailSettlementExportDtoList = async (acquireStoreSettlementDetailQueryVo) => {
    return request({
      url: "acquireStoreSettlementDetail/reconcileAcquireStoreSettlementDetailSettlementExportDtoList",
      params: {
        index:acquireStoreSettlementDetailQueryVo.index,
        pageSize:acquireStoreSettlementDetailQueryVo.pageSize,
        creationTime: acquireStoreSettlementDetailQueryVo.creationTime,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      'responseType': 'blob'
    });
  };