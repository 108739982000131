import React, { useState } from "react";
import { updateBankCode } from "../../service/BankCodeRequest";

import {
  TextField,
  Button,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";

import { BankCodeFormBottonControl } from "./style/BankCodeStyle";

const userStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 10,
  },
  hidden: {
    display: "none",
  },
});
export default function BankCodeEditForm({
  handleEditClose,
  handleEditSubmit,
  editBankCodeDto,
}) {
  const classes = userStyles();

  console.log("editBankCodeDto==", editBankCodeDto.bankName);
  const [id, setId] = useState(editBankCodeDto.id);
  const [bankName, setBankName] = useState(editBankCodeDto.bankName);
  const [bankCode, setBankCode] = useState(editBankCodeDto.bankCode);
  const [bankType, setBankType] = useState(editBankCodeDto.bankType);

  const handleSubmitData = (e) => {
    e.preventDefault();

    let updateBankCodeDto = {
      id: id,
      bankName: bankName,
      bankCode: bankCode,
      bankType: bankType,
    };
    updateBankCode(updateBankCodeDto);

    handleEditSubmit();
  };
  return (
    <div>
      <Container>
        <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
          <TextField id="id" hidden value={id} className={classes.hidden} />

          <Box>
            <TextField
              onChange={(e) => setBankName(e.target.value)}
              className={classes.field}
              id="standard-disabled"
              label="Bank Name"
              variant="standard"
              value={bankName}
            />

            <TextField
              id="bankCode"
              className={classes.field}
              label="Bank Code"
              variant="standard"
              onChange={(e) => setBankCode(e.target.value)}
              value={bankCode}
            />
            <TextField
              id="bankType"
              className={classes.field}
              label="Bank Type"
              onChange={(e) => setBankType(e.target.value)}
              variant="standard"
              value={bankType}
            />
          </Box>
          <BankCodeFormBottonControl>
            <Button type="submit" variant="outlined" className="btn right">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={handleEditClose}
              className="btn right"
            >
              close
            </Button>
          </BankCodeFormBottonControl>
        </form>
      </Container>
    </div>
  );
}
