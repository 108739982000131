import React, { useState } from "react";
import { saveDeviceToken } from "../../service/DeviceTokenRequest";
import { DeviceTokenFormBottonControl } from "./style/DeviceTokenStyle";

import {
  TextField,
  Button,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";

import {TERMINAL_STATUS} from "../../components/common/constant/ManageConstant";

const userStyles = makeStyles({
  field: {
    marginTop: 20,
    marginBottom: 10,
  },
});
export default function DeviceTokenForm({
  handleAddClose,
  handleSubmit,
  parameterStoreId,
}) {
  const classes = userStyles();

  
  const [storeId, setStoreId] = useState(parameterStoreId);


  console.log("=DeviceTokenForm==storeId==", storeId);

  const handleSubmitData = async (e) => {
    e.preventDefault();
    console.log("DeviceTokenAddForm====");
    

    console.log("storeId===", storeId);
    

    let saveDeviceTokenDto = {
      status: TERMINAL_STATUS,
      storeId: storeId,
    };
    await saveDeviceToken(saveDeviceTokenDto);

    console.log("===save====");

    handleSubmit();
  };
  return (
    <div>
      <Container>
        <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
          <Box>

            
            <TextField
              id="storeId"
              className={classes.field}
              label="Store Id"
              onChange={(e) => setStoreId(e.target.value)}
              variant="standard"
              value={storeId}
            />
          </Box>

          

          <DeviceTokenFormBottonControl>
            <Button type="submit" variant="outlined" className="btn right">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={handleAddClose}
              className="btn right"
            >
              close
            </Button>
          </DeviceTokenFormBottonControl>
        </form>
      </Container>
    </div>
  );
}
