import request from './ConfigRequest';
export const pageRoleAccessDto = (index, size)=>{
    return request({
        url: 'roleAccess/pageRoleAccessDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveRoleAccess = (roleAccessDto)=>{
    return request({
        url: 'roleAccess/saveRoleAccessDto',
        method: 'post',
        data: roleAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateRoleAccessDto = (roleAccessDto)=>{
    return request({
        url: 'roleAccess/updateRoleAccessDto',
        method: 'put',
        data: roleAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchRoleAccessDto = (index, size, roleName)=>{
    return request({
        url: 'roleAccess/pageRoleAccessDto',
        params: {
            index,
            size,
            roleName,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
