import request from "./ConfigRequest";

export const pageBankCodeDto = (bankAccountQueryVo) => {
  return request({
    url: "bankCode/pageBankCodeDto",
    params: {
      index:bankAccountQueryVo.index,
      pageSize:bankAccountQueryVo.size,
      bankNameLike:bankAccountQueryVo.bankNameLike,
      bankCodeLike:bankAccountQueryVo.bankCodeLike,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const saveBankCode = (bankCodeDto) => {
  return request({
    url: "bankCode/saveBankCode",
    method: "post",
    data: bankCodeDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateBankCode = (bankCodeDto) => {
  return request({
    url: "bankCode/updateBankCodeDto",
    method: "put",
    data: bankCodeDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchBankCodeDto = (index, size, bankName) => {
  return request({
    url: "bankCode/pageBankCodeDto",
    params: {
      index,
      size,
      bankName,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};


export const searchLikeBankCodeDto = (index, size, searchLikeKey) => {
  return request({
    url: "bankCode/searchLikeBankCodeDto",
    params: {
      index,
      size,
      searchLikeKey,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};
