import React, { useState } from "react";
import { updateRefundDto } from "../../service/RefundRequest";

import {TextField, Button,Box, Container,makeStyles
  } from "@material-ui/core";
import  {
    RefundFormBottonControl
    } from './style/RefundStyle';



  const userStyles = makeStyles({
      field:{
          marginTop:20,
          marginBottom:10,
        
      },
      hidden:{
        display:"none",
      }
  })
export  default function RefundEditForm ({handleEditClose,handleEditSubmit,editRefundDto}){
   
        const classes = userStyles();

        debugger
        console.log("editRefundDto==",editRefundDto.id);
        const [id,setId] = useState(editRefundDto.id);
        const [refundStatus,setRefundStatus] = useState(editRefundDto.refundStatus);
        const [refundAmount,setRefundAmount] = useState(editRefundDto.refundAmount);
        
 
      
        const handleSubmitData=(e) =>{
            e.preventDefault();
            console.log("RefundEditForm====")

            console.log("refundStatus===",refundStatus)
            console.log("refundAmount===",refundAmount)
            

            
        
            let saveRefundDto = {
                    "id":id,
                    "refundStatus":refundStatus,
                    "refundAmount":refundAmount
            }
            updateRefundDto(saveRefundDto);

            handleEditSubmit();
        }
        return (
            <div>


                <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                    <TextField
                        onChange={(e=> setRefundStatus(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Refund Status"
                        variant="standard"
                        value={refundStatus}
                       
                        />
                    <TextField id="refundAmount"  className={classes.field} label="Refund Amount" variant="standard"   onChange={(e=> setRefundAmount(e.target.value))} value= {refundAmount} />
                    
                    </Box>
            
                    
                    <RefundFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </RefundFormBottonControl>
                       
                    </form>
                </Container>
            </div>
        );
   
}

  