import request from "./ConfigRequest";

export const userLogin = (userVo) => {
  return request({
    url: "user/userLogin",
    method: "post",
    data: userVo,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
