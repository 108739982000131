import request from "./ConfigRequest";

export const pageTerminalDto = (terminalQueryVo)=>{
    return request({
        url: 'terminal/pageTerminalDto',
        params:{
            id:terminalQueryVo.id,
            index:terminalQueryVo.index,
            pageSize:terminalQueryVo.pageSize,
            serialNumberLike:terminalQueryVo.serialNumberLike,
            storeId:terminalQueryVo.storeId,
            deviceType:terminalQueryVo.deviceType,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveTerminal = (terminalDto)=>{
    return request({
        url: 'terminal/saveTerminalDto',
        method: 'post',
        data: terminalDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateTerminalDto = (terminalDto)=>{
    return request({
        url: 'terminal/updateTerminalDto',
        method: 'put',
        data: terminalDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchTerminalDto = (index, size, serialNumber)=>{
    return request({
        url: 'terminal/pageTerminalDto',
        params: {
            index,
            size,
            serialNumber,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
