import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import RefundEditForm from "./RefundEditForm";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Grid,
  Input,
} from "@material-ui/core";

import { pageRefundDto, searchRefundDto } from "../../service/RefundRequest";

import { RefundAddButtonControl } from "./style/RefundStyle";
import { BigNumber } from "bignumber.js";

import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function RefundTable() {

  const classes = useStyles();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [data, setData] = useState(["Loading Data..."]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editRefundDto, setEditRefundDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
  const [createTimeStart,setCreateTimeStart] = useState();
  const [createTimeEnd,setCreateTimeEnd] = useState();
  const [id,setId] = useState();
  const [acquirerRefundId,setAcquirerRefundId] = useState();
  const [transactionId,setTransactionId] = useState();
  const [refundExternalTransactionId,setRefundExternalTransactionId] = useState();
  const [transactionExternalTransactionId,setTransactionExternalTransactionId] = useState();
  const [clientRefundId,setClientRefundId] = useState();
  const [refundStatus,setRefundStatus] = useState();
  const [storeId,setStoreId] = useState();
  const [recordStatus,setRecordStatus] = useState();


  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: '',
      acquirerRefundId:'',
      transactionId:'',
      refundExternalTransactionId:'',
      transactionExternalTransactionId:'',
      clientRefundId:'',
      refundStatus:'',
      storeId:'',
      recordStatus:'',
    }

    
  });



  const searchRefund = () => {
    console.log("======searchRefund", searchName);
    setSearchRefreshFlag(true);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
     

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
   
    console.log("===page", page);
    page = page + 1;
    let refundTransactionQueryVo  = {

      index:page,
      pageSize:10,
      id:id ? id : null,
      acquirerRefundId: acquirerRefundId ? acquirerRefundId : null,
      transactionId: transactionId ? transactionId : null,
      refundExternalTransactionId: refundExternalTransactionId ? refundExternalTransactionId : null,
      transactionExternalTransactionId: transactionExternalTransactionId ? transactionExternalTransactionId : null,
      clientRefundId: clientRefundId ? clientRefundId : null,
      refundStatus: refundStatus ? refundStatus : null,
      storeId: storeId ? storeId : null,
      recordStatus : recordStatus ? recordStatus : null,
    };

    initRefundData(refundTransactionQueryVo);
  };

  const editRefundData = (tableMeta) => {
    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editRefundDto = data[row];
    setEditRefundDto(editRefundDto);
  };
  
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initRefundData = async (refundTransactionQueryVo) => {
    setIsLoading(true);
    

    let initResponseDto = await pageRefundDto(refundTransactionQueryVo);

    console.log("initRefundData====initResponseDto==",initResponseDto);

    if(initResponseDto && initResponseDto.data){
      setData(initResponseDto.data.records);
      setCount(initResponseDto.data.total);
    }

    setIsLoading(false);

    setPage(refundTransactionQueryVo.index);
  };

  const searchRefundData = (index, pageSize, searchName) => {
    setIsLoading(true);
  
    searchRefundDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);

      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };

  const refundOnSubmit = (data)=>{
    setId(data.id);
    setAcquirerRefundId(data.acquirerRefundId);
    setTransactionId(data.transactionId);
    setRefundExternalTransactionId(data.refundExternalTransactionId);
    setTransactionExternalTransactionId(data.transactionExternalTransactionId);
    setClientRefundId(data.clientRefundId);
    setRefundStatus(data.refundStatus);
    setStoreId(data.storeId);
    setRecordStatus(data.recordStatus);

    let refundTransactionQueryVo  = {

      index:1,
      pageSize:10,
      id:data.id ? data.id : null,
      acquirerRefundId: data.acquirerRefundId ? data.acquirerRefundId : null,
      transactionId: data.transactionId ? data.transactionId : null,
      refundExternalTransactionId: data.refundExternalTransactionId ? data.refundExternalTransactionId : null,
      transactionExternalTransactionId: data.transactionExternalTransactionId ? data.transactionExternalTransactionId : null,
      clientRefundId: data.clientRefundId ? data.clientRefundId : null,
      refundStatus: data.refundStatus ? data.refundStatus : null,
      storeId: data.storeId ? data.storeId : null,
      recordStatus : data.recordStatus ? data.recordStatus : null,
    };

    initRefundData(refundTransactionQueryVo)
  }


  const createTimeStartChange =(startTime) => {
    console.log("createTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreateTimeStart(startTime);
  }

  const createTimeEndChange =(startTime) => {
    console.log("createTimeEndChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreateTimeEnd(startTime);
  }

  const refundReset = ()=>{
    reset();
    setCreateTimeStart();
    setCreateTimeEnd();
    setId();
    setAcquirerRefundId();
    setTransactionId();
    setRefundExternalTransactionId();
    setTransactionExternalTransactionId();
    setClientRefundId();
    setRefundStatus();
    setStoreId();
    setRecordStatus();
     
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let  refundTransactionQueryVo = {
      index:1,
      pageSize:10,
    };
    initRefundData(refundTransactionQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {

    console.log("===searchRefundData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchRefundData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            console.log("value=====customBodyRender====", value);
            if(value){
              return new BigNumber(value).toString();
            }
            
            return value;
          },
        },
      },
      {
        name: "storeName",
        label: "Store Name",
        options: {},
      },{
        name: "businessName",
        label: "Company Name",
        options: {},
      },
      {
        name: "transactionId",
        label: "Transaction Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            console.log("value=====customBodyRender====", value);
            if(value){
              return new BigNumber(value).toString();
            }
            
            return value;
          },
        },
      },
      {
        name: "netAmount",
        label: "Net amount",
        options: {},
      },
      {
        name: "serviceCharge",
        label: "Mdr",
        options: {},
      },
      {
        name: "serviceCharges",
        label: "Service charge",
        options: {
           
        },
      },
      {
        name: "refundAmount",
        label: "Refund Amount",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            if(value){
              return new BigNumber(value).toString();
            }
            
            return value;
          },
        },
      },

      
      
      {
        name: "refundStatus",
        label: "Refund Status",
        options: {},
      },
      {
        name: "acquirerRefundId",
        label: "Acquirer Refund Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

      
          
            if(value){
              return value.toString();
            }
            
            return "";
          },
        },
      },
      {
        name: "externalTransactionId",
        label: "Refund External Transaction Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

      
          
            if(value){
              return value.toString();
            }
            
            return "";
          },
        },
      },{
        name: "transactionExternalTransactionId",
        label: "Transaction External Transaction Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

      
          
            if(value){
              return value.toString();
            }
            
            return "";
          },
        },
      },
      {
        name: "clientRefundId",
        label: "Client Refund Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

             
          
            
            if(value){
              return value.toString();
            }
            
            return value;
          },
        },
      },
      {
        name: "recordStatus",
        label: "Record Status",
        options: {},
      },  {
        name: "refundSource",
        label: "Refund Source",
        options: {},
      },{
        name: "acquirerType",
        label: "Acquirer type",
        options: {},
      },
      {
        name: "creationTime",
        label: "Creation Time",
        options: {},
      },
      {
        name: "lastUpdateTime",
        label: "Last Update Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editRefundData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
      <form >
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>Id:
          <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
      
        <Grid item xs={4}>
          <Paper className={classes.paper}>AcquirerRefundId:

          <Controller
            name="acquirerRefundId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>TransactionId:

          <Controller
            name="transactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>RefundExternalTransactionId:

          <Controller
            name="refundExternalTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>TransactionExternalTransactionId:

          <Controller
            name="transactionExternalTransactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>ClientRefundId:

          <Controller
            name="clientRefundId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>RefundStatus:

          <Controller
            name="refundStatus"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>StoreName:

          <Controller
            name="storeName"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
      

        <Grid item xs={4}>
          <Paper className={classes.paper}>RecordStatus:

          <Controller
            name="recordStatus"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}> 

          <TextField
            id="creationTimeStart"
            name="creationTimeStart"
            label="Refund time start"
            control={control}
            type="date"
            defaultValue={createTimeStart}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => createTimeStartChange(e.target.value)}
          />
            </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}> 

          <TextField
            id="creationTimeEnd"
            name="creationTimeEnd"
            label="Refund time end"
            control={control}
            type="date"
            defaultValue={createTimeEnd}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => createTimeEndChange(e.target.value)}
          />
            </Paper>
        </Grid>
        </Grid >
        
        <Grid item xs={4} style={{ padding: 15 }}   >
        <Paper className={classes.buttonPaper}><Button onClick={handleSubmit(refundOnSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>

        <Grid item xs={4} style={{ padding: 15 }}   >
        <Paper className={classes.buttonPaper}><Button onClick={() => refundReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid> 

      </Grid>
      
    </div>
    </form>
      {/* <RefundAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchRefund}
          color="primary"
        >
          Search
        </Button>
      </RefundAddButtonControl> */}

      <MUIDataTable
        title={
          <Typography variant="h6">
            Refund list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Refund</DialogTitle>
        <DialogContent>
          <RefundEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editRefundDto={editRefundDto}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
