import request from "./ConfigRequest";

export const pageUserDto = (userQueryVo)=>{
    return request({
        url: 'user/pageUserDto',
        params:userQueryVo,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveUser = (userDto)=>{
    return request({
        url: 'user/saveUserDto',
        method: 'post',
        data: userDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const updateUserDto = (userDto)=>{
    return request({
        url: 'user/updateUserDto',
        method: 'put',
        data: userDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchUserDto = (index, size, firstName)=>{
    return request({
        url: 'user/pageUserDto',
        params: {
            index,
            size,
            firstName,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};

export const searchUserLikeDto = (index, size, searchLikeKey) => {
    return request({
      url: "user/searchLikeUserDto",
      params: {
        index,
        size,
        searchLikeKey,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  };