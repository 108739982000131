import AddressIdSelect from "../common/AddressIdSelect";
import BankDetailIdSelect from "../common/BankDetailIdSelect";
import React, { useState } from "react";
import UserIdSelect from "../common/UserIdSelect";
import { updateBusinessDto } from "../../service/BusinessRequest";
import { BusinessFormBottonControl } from "./style/BusinessStyle";

import {
  TextField,
  Button,
  Box,
  Container,
  makeStyles,
  Paper,
  Grid
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  errorMessageStyle:{
    maxWidth: 800,
    margin: `${theme.spacing(1)}px auto`,
    color:"red",
  }
}));
export default function BusinessEditForm({
  handleEditClose,
  handleEditSubmit,
  editBusinessDto,
}) {
  const classes = useStyles();

 
  console.log("editBusinessDto==", editBusinessDto.name);
  const [id, setId] = useState(editBusinessDto.id);
  const [name, setName] = useState(editBusinessDto.name);
  const [foreignName, setForeignName] = useState(editBusinessDto.foreignName);
  const [businessDescription, setBusinessDescription] = useState(
    editBusinessDto.businessDescription,
  );

  const [isApiPartner, setIsApiPartner] = useState(editBusinessDto.isApiPartner);
  const [settlementEmails, setSettlementEmails] = useState(editBusinessDto.settlementEmails);
  const [contractFilePath, setContractFilePath] = useState(editBusinessDto.contractFilePath);

  const [defaultLanguage, setDefaultLanguage] = useState(editBusinessDto.defaultLanguage);
  const [defaultBankDetailId, setDefaultBankDetailId] = useState(editBusinessDto.defaultBankDetailId);
  const [addressId, setAddressId] = useState(editBusinessDto.addressId);
  const [userId, setUserId] = useState(editBusinessDto.userId);
  const [address, setAddress] = useState(editBusinessDto.address);
  const [bank, setBank] = useState(editBusinessDto.bankName);
  const [user, setUser] = useState(editBusinessDto.firstName);
  const [contactName,setContactName] = useState(editBusinessDto.contactName);

 
  const [bankName,setBankName] = useState(editBusinessDto.bankName);
  const [bankAccount,setBankAccount] = useState(editBusinessDto.bankAccount);
  const [phoneNumber,setPhoneNumber] = useState(editBusinessDto.phoneNumber);
  

  const handleSubmitData = (e) => {
    e.preventDefault();
    console.log("BusinessEditForm====");
    console.log("name===", name);
    console.log("foreignName===", foreignName);
    console.log("businessDescription===", businessDescription);

    console.log("isApiPartner===", isApiPartner);
    console.log("settlementEmails===", settlementEmails);
    console.log("contractFilePath===", contractFilePath);

    console.log("defaultLanguage===", defaultLanguage);
    console.log("defaultBankDetailId===", defaultBankDetailId);
    console.log("addressId===", addressId);

    const saveBusinessDto = {
      id: id,
      name: name,
      foreignName: foreignName,
      businessDescription: businessDescription,
      isApiPartner: isApiPartner,
      settlementEmails: settlementEmails,
      contractFilePath: contractFilePath,
      defaultLanguage: defaultLanguage,
      defaultBankDetailId: defaultBankDetailId,
      addressId: addressId,
      userId: userId,
    };
    updateBusinessDto(saveBusinessDto);

    handleEditSubmit();
  };

  const setAddressIdChange = (addressText) => {
    console.log("setAddressIdChange===", addressText);
    if (addressText) {
      setAddressId(addressText.id);
    }
  };

  const setBankDetailIdChange = (bankDetailText) => {
    console.log("setBankDetailIdChange===", bankDetailText);
    if (bankDetailText) {
      setDefaultBankDetailId(bankDetailText.id);
    }
  };

  const setUserIdChange = (userText) => {
    console.log("setUserIdChange===", userText);
    if (userText) {
      setUserId(userText.id);
    }
  };

  return (
    <div className={classes.root}>
      
        <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
          {false &&<TextField id="id" hidden value={id}  />}
          <Grid container item xs={12} spacing={3}>

                <Grid item xs={4}>
                <Paper className={classes.paper}>
                <TextField
                required
                    onChange={(e) => setName(e.target.value)}
                    className={classes.field}
                    id="standard-disabled"
                    label="CompanyName"
                    variant="standard"
                    value={name}
                  />

                  </Paper>
                </Grid>
              
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                  <TextField
                      id="Phone"
                      className={classes.field}
                      label="Contact"
                      variant="standard"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      InputProps={{
                        inputProps: {
                          margin: 10,
                          style: { textAlign: "left" },
                        },
                      }}
                    />
                  </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>  
                <TextField
                
                  id="Description"
                  className={classes.field}
                  label="Remark"
                  onChange={(e) => setBusinessDescription(e.target.value)}
                  variant="standard"
                />
                </Paper>
              </Grid>
                <Grid item xs={4}>
            
                        <TextField
                    id="settlementEmails"
                    className={classes.field}
                    label="SettlementEmails"
                    variant="standard"
                    onChange={(e) => setSettlementEmails(e.target.value)}
                    value={settlementEmails}
                  />
                </Grid>
                <Grid item xs={4}>

                <TextField
                    id="businessDescription"
                    className={classes.field}
                    label="BusinessDescription"
                    onChange={(e) => setBusinessDescription(e.target.value)}
                    variant="standard"
                    value={businessDescription || ""}
                  />
                </Grid>  
          </Grid>
          
          <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
         
            {/* <AddressIdSelect
              setAddressIdChange={setAddressIdChange}
              address={address}
            /> */}
          </Grid>
          <Grid item xs={4}>
          {/* <BankDetailIdSelect
              setBankDetailIdChange={setBankDetailIdChange}
              bank={bank}
            /> */}
          
          </Grid>
          <Grid item xs={4}>
          

            
          </Grid>
          </Grid>
          

          
            {/* <TextField
              id="defaultLanguage"
              className={classes.field}
              label="DefaultLanguage"
              variant="standard"
              onChange={(e) => setDefaultLanguage(e.target.value)}
              value={defaultLanguage}
            /> */}
           

         

        
            {/* <UserIdSelect
              setUserIdChange={setUserIdChange}
              user={user}
            /> */}
         

          <BusinessFormBottonControl>
            <Button type="submit" variant="outlined" className="btn right">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={handleEditClose}
              className="btn right"
            >
              close
            </Button>
          </BusinessFormBottonControl>
        </form>
       
    </div>
  );
}
