import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
 
import {
  pageAcquireStoreSettlementDetailSettlement,
  selectAcquireStoreSettlementDetailSettlementExportDtoList,
  reconcileAcquireStoreSettlementDetailSettlementExportDtoList,
} from "../../service/AcquireStoreSettlementDetailRequest";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  makeStyles,
  Grid,
  Input,
 
} from "@material-ui/core";

import Autocomplete from '@mui/material/Autocomplete';

import { BigNumber } from "bignumber.js";

import StoreIdSelect from "../common/StoreIdSelect";
 
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
export default function AcquireStoreSettlementDetailTable() {

  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [
    editStoreAcquirerSettlementSummaryDto,
    setEditStoreAcquirerSettlementSummaryDto,
  ] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
  const [id,setId] = useState();
  const [settlementMethod,setSettlementMethod] = useState();
  const [acquirerType,setAcquirerType] = useState();
  const [transactionId,setTransactionId] = useState();
  const [creationTimeStart,setCreationTimeStart] = useState();
  const [creationTimeEnd,setCreationTimeEnd] = useState();
  const [settlementTimeStart,setSettlementTimeStart] = useState();
  const [settlementTimeEnd,setSettlementTimeEnd] = useState();
  const [storeId,setStoreId] = useState();
  const [storeLabelText,setStoreLabelText] = useState("");
  const [store, setStore] = useState();

  const [creationTime,setCreationTime] = useState();

  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: '',
      settlementMethod:'',
      acquirerType:'',
      transactionId:'',
      creationTimeStart:'',
      creationTimeEnd:'',
      settlementTimeStart:'',
      settlementTimeEnd:'',
      creationTime:'',
   
    }

    
  });


  const searchStoreAcquirerSettlementSummary = () => {
    console.log("======searchStoreAcquirerSettlementSummary", searchName);
    setSearchRefreshFlag(true);
  };

  const addStoreAcquirerSettlementSummary = () => {
    console.log("======handle add addStoreAcquirerSettlementSummary");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
   

    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };


  const creationTimeStartChange =(startTime) => {
    console.log("creationTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreationTimeStart(startTime);
  }

  const creationTimeEndChange =(endTime) => {
    console.log("creationTimeEndChange==", endTime);
    endTime = endTime + " 00:00:00";
    setCreationTimeEnd(endTime);
  }


  const settlementTimeStartChange =(startTime) => {
    console.log("settlementTimeStartChange==", startTime);
    startTime = startTime + " 00:00:00";
    setSettlementTimeStart(startTime);
  }

  const settlementTimeEndChange =(endTime) => {
    console.log("settlementTimeEndChange==", endTime);
    endTime = endTime + " 00:00:00";
    setSettlementTimeEnd(endTime);
  }

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
     
    console.log("===page", page);
    page = page + 1;
    let acquireStoreSettlementDetailQueryVo = {
      index:page,
      pageSize: pageSize,
    };


    initData(acquireStoreSettlementDetailQueryVo);
  };

  const editStoreAcquirerSettlementSummaryData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editStoreAcquirerSettlementSummaryDto = data[row];
    setEditStoreAcquirerSettlementSummaryDto(
      editStoreAcquirerSettlementSummaryDto,
    );
  };
 
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async (acquireStoreSettlementDetailQueryVo) => {
    setIsLoading(true);
 
    let pageAcquireResponse = await pageAcquireStoreSettlementDetailSettlement(acquireStoreSettlementDetailQueryVo);

    console.log("pageAcquireResponse===",pageAcquireResponse);

    if(pageAcquireResponse && pageAcquireResponse.data){
      setData(pageAcquireResponse.data.records);
      setCount(pageAcquireResponse.data.total);
    }

    setIsLoading(false);

    setPage(acquireStoreSettlementDetailQueryVo.index);
    
  };

  const settlementSubmit = async (formData) => {
    setId(formData.id);
    setSettlementMethod(formData.settlementMethod);
    setTransactionId(formData.transactionId);
    setAcquirerType(formData.acquirerType);
    

    let acquireStoreSettlementDetailQueryVo = {
      index:1,
      pageSize:10,
      id:formData.id ? formData.id : null,
      settlementMethod: formData.settlementMethod ? formData.settlementMethod : null,
      transactionId: formData.transactionId ? formData.transactionId : null,
      acquirerType: formData.acquirerType ? formData.acquirerType : null,
      creationTimeStart: creationTimeStart ? creationTimeStart : null,
      creationTimeEnd: creationTimeEnd ? creationTimeEnd : null,
      settlementTimeStart: settlementTimeStart ? settlementTimeStart : null,
      settlementTimeEnd: settlementTimeEnd ? settlementTimeEnd : null,
      storeId: storeId ? storeId.toString() : null,
      creationTime: creationTime ? creationTime : null,
    };

    initData(acquireStoreSettlementDetailQueryVo);

  }

  const settlementReset = async () => {
    reset();
    setId();
    setSettlementMethod();
    setTransactionId();
    setAcquirerType();
    setCreationTimeStart();
    setCreationTimeEnd();
    setSettlementTimeStart();
    setSettlementTimeEnd();
    setCreationTime();
  }

  const setStoreIdChange = (storeText) => {
    
    console.log("setStoreIdChange===", storeText);
    if (storeText) {
      setStoreId(storeText.id);
      
    }
  };

  const clearSelectStoreId =() => { 
    console.log("Clear storeId");
    setStoreId();
  }

  const storeNameSelect = async (event,value) =>{
    console.log("storeNameSelect==", value);
  }


  const exportSettlementTransactionXls = async() => {

    console.log("exportSettlementTransactionXls");

    try{
      let acquireStoreSettlementDetailQueryVo = {
        index:1,
        pageSize:10,
        id:id ? id : null,
        settlementMethod: settlementMethod ? settlementMethod : null,
        transactionId: transactionId ? transactionId : null,
        acquirerType: acquirerType ? acquirerType : null,
        creationTimeStart: creationTimeStart ? creationTimeStart : null,
        creationTimeEnd: creationTimeEnd ? creationTimeEnd : null,
        settlementTimeStart: settlementTimeStart ? settlementTimeStart : null,
        settlementTimeEnd: settlementTimeEnd ? settlementTimeEnd : null,
        storeId: storeId ? storeId.toString() : null,
        creationTimeEnd: creationTimeEnd ? creationTime : null,
        creationTime: creationTime ? creationTime : null,
      };
      let settlementExportList = await selectAcquireStoreSettlementDetailSettlementExportDtoList(acquireStoreSettlementDetailQueryVo);

     
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([settlementExportList]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "settlement.xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportSettlementTransactionXls===error",e);
    }

  }

  const creationTimeChange =(startTime) => {
    console.log("creationTimeChange==", startTime);
    startTime = startTime + " 00:00:00";
    setCreationTime(startTime);
  }
  const searchStoreAcquirerSettlementSummaryData = (
    index,
    pageSize,
    searchName,
  ) => {
    setIsLoading(true);
  }


  const exportReconcileSettlementTransactionXls = async() => {

    console.log("exportSettlementTransactionXls");

    try{
      let acquireStoreSettlementDetailQueryVo = {
        index:1,
        pageSize:10,
        creationTime: creationTime ? creationTime : null,
      };
      let settlementExportList = await reconcileAcquireStoreSettlementDetailSettlementExportDtoList(acquireStoreSettlementDetailQueryVo);

     
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([settlementExportList]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "reconcile-settlement.xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportReconcileSettlementTransactionXls===error",e);
    }

  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let acquireStoreSettlementDetailQueryVo = {
      index:page,
      pageSize:pageSize,
    };
    initData(acquireStoreSettlementDetailQueryVo);
  }, [refreshFlag]);

  useEffect(() => {
 
    console.log("===searchStoreAcquirerSettlementSummaryData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchStoreAcquirerSettlementSummaryData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if(value){
              return new BigNumber(value).toString();
            }

            return "";
            
          },
        },
      },

      {
        name: "acquirerType",
        label: "Acquirer type",
        options: {},
      },
      {
        name: "transactionId",
        label: "Transaction id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if(value){
              return new BigNumber(value).toString();
            }

            return "";
            
          },
        },
      },
      {
        name: "actualSettlementMethod",
        label: "Actual settlement method",
        options: {},
      },
      {
        name: "businessName",
        label: "Company name",
        options: {},
      },
      {
        name: "storeName",
        label: "Store name",
        options: {},
      },
      {
        name: "capturedTime",
        label: "Pay time",
        options: {},
      },
      {
        name: "amount",
        label: "Amount",
        options: {},
      }, {
        name: "serviceCharge",
        label: "Service Charge",
        options: {},
      }, {
        name: "netAmount",
        label: "Net amount",
        options: {},
      },{
        name: "mdr",
        label: "Mdr",
        options: {},
      },
      {
        name: "creationTime",
        label: "Settlement Time",
        options: {},
      },
      {
        name: "settlementMethod",
        label: "Settlement method",
        options: {},
      },
    
      
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) =>
                    editStoreAcquirerSettlementSummaryData(tableMeta)
                  }
                >
                  View
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
       

       <form >

       <Grid container spacing={3}>

       <Grid item xs={4}>
          <Paper className={classes.paper}>Id:
            <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>CompanyName:
            <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>


        <Grid item xs={4}>
        <Paper className={classes.paper}>   
        <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store}
                        clearSelectStoreId={clearSelectStoreId} 
                        storeLabelText ={storeLabelText}/>
          </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>SettlementMethod:
            <Controller
            name="settlementMethod"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>AcquirerType:
            <Controller
            name="acquirerType"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>TransactionId:
            <Controller
            name="transactionId"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>



        <Grid item xs={4}>
          <Paper className={classes.paper}> 
              <TextField
              id="creationTimeStart"
              name="creationTimeStart"
              label="Settlement time start"
              control={control}
              type="date"
              defaultValue={creationTimeStart}
              
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => creationTimeStartChange(e.target.value)}
            />
       </Paper>
      </Grid>


      <Grid item xs={4}>
          <Paper className={classes.paper}>
            
            <TextField
            id="creationTimeEnd"
            name="creationTimeEnd"
            label="Settlement time end"
            type="date"
            defaultValue={creationTimeEnd}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => creationTimeEndChange(e.target.value)}
            
          />
            </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}> 
              <TextField
              id="settlementTimeStart"
              name="settlementTimeStart"
              label="Pay time start"
              control={control}
              type="date"
              defaultValue={settlementTimeStart}
              
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => settlementTimeStartChange(e.target.value)}
            />
       </Paper>
      </Grid>
        


      <Grid item xs={4}>
          <Paper className={classes.paper}> 
              <TextField
              id="settlementTimeEnd"
              name="settlementTimeEnd"
              label="Pay time end"
              control={control}
              type="date"
              defaultValue={settlementTimeEnd}
              
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => settlementTimeEndChange(e.target.value)}
            />
       </Paper>
      </Grid>



      <Grid item xs={4}>
          <Paper className={classes.paper}> 
              <TextField
              id="creationTime"
              name="creationTime"
              label="Settlement time"
              control={control}
              type="date"
              defaultValue={creationTime}
              
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => creationTimeChange(e.target.value)}
            />
       </Paper>
      </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(settlementSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => settlementReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>

        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => exportSettlementTransactionXls()} variant={"outlined"} color="primary">Export  excel </Button></Paper>
        </Grid>

        {/* <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => exportReconcileSettlementTransactionXls()} variant={"outlined"} color="primary">Reconcile  excel </Button></Paper>
        </Grid> */}
       </Grid>
       </form>

      <MUIDataTable
        title={
          <Typography variant="h6">
            Store Acquirer Settlement Summary list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      {/* <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Store Acquirer Settlement Summary</DialogTitle>
        <DialogContent>
          <StoreAcquirerSettlementSummaryEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editStoreAcquirerSettlementSummaryDto={
              editStoreAcquirerSettlementSummaryDto
            }
          />
        </DialogContent>
      </Dialog> */}
    </div>
  );
}
