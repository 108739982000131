import React, { useState } from "react";
import { saveRegion } from "../../service/RegionRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    RegionFormBottonControl,
} from './style/RegionStyle';



const regionStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function RegionForm({handleAddClose, handleSubmit}) {
    const classes = regionStyles();

    const [name, setName] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [defaultLanguage, setDefaultLanguage] = useState('');



    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('RegionForm====');
        console.log('name===', name);
        console.log('shortCode===', shortCode);
        console.log('defaultCurrency===', defaultCurrency);
        console.log('defaultLanguage===', defaultLanguage);



        const saveRegionDto = {
            'name': name,
            'shortCode': shortCode,
            'defaultCurrency': defaultCurrency,
            'defaultLanguage': defaultLanguage,
        };
        await saveRegion(saveRegionDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Name"
                            variant="standard"

                        />

                        <TextField id="shortCode" className={classes.field} label="Short Code" variant="standard" onChange={((e)=> setShortCode(e.target.value))} />
                        <TextField id="defaultCurrency" className={classes.field} label="Default Currency" onChange={((e)=> setDefaultCurrency(e.target.value))} variant="standard"/>
                        <TextField id="defaultLanguage" className={classes.field} label="Default Language" onChange={((e)=> setDefaultLanguage(e.target.value))} variant="standard"/>
                    </Box>


                    <RegionFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </RegionFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

