import React, { useState } from "react";
import StoreIdSelect from "../common/StoreIdSelect";
import { saveAcquirerStore } from "../../service/AcquirerStoreRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    AcquirerStoreFormBottonControl,
} from './style/AcquirerStoreStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function AcquirerStoreForm({
    handleAddClose,
    handleSubmit,
    parameterStoreId,}) {
    const classes = userStyles();

    const [storeCurrency, setStoreCurrency] = useState('');
    const [parameterJson, setParameterJson] = useState('');
    const [chargeRateId, setChargeRateId] = useState('');
    const [settlementConfig, setSettlementConfig] = useState('');
    const [storeId, setStoreId] = useState(parameterStoreId);
    const [acquirerType, setAcquirerType] = useState('');
    const [parameterOne, setParameterOne] = useState('');
    const [parameterTwo, setParameterTwo] = useState('');

    const [activeStatus, setActiveStatus] = useState('');

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('AcquirerStoreEditForm====');
        console.log('storeCurrency===', storeCurrency);
        console.log('parameterJson===', parameterJson);
        console.log('chargeRateId===', chargeRateId);
        console.log('settlementConfig===', settlementConfig);
        console.log('storeId===', storeId);
        console.log('parameterOne===', parameterOne);
        console.log('parameterTwo===', parameterTwo);
        console.log('acquirerType===', acquirerType);


        const saveAcquirerStoreDto = {
            'storeCurrency': storeCurrency,
            'parameterJson': parameterJson,
            'chargeRateId': chargeRateId,
            'settlementConfig': settlementConfig,
            'storeId': storeId,
            'parameterOne': parameterOne,
            'parameterTwo': parameterTwo,
            'acquirerType': acquirerType,
        };
        await saveAcquirerStore(saveAcquirerStoreDto);

        console.log('===save====');

        handleSubmit();
    };

    const setStoreIdChange = (storeText) => {
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
        }
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setStoreCurrency(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Store Currency"
                            variant="standard"

                        />

                        <TextField id="parameterJson" className={classes.field} label="Parameter Json" variant="standard" onChange={((e)=> setParameterJson(e.target.value))}/>
                        <TextField id="chargeRateId" className={classes.field} label="Charge Rate Id" onChange={((e)=> setChargeRateId(e.target.value))} variant="standard" />
                    </Box>


                    <Box>
                        <TextField id="settlementConfig" className={classes.field} label="Settlement Config" variant="standard" onChange={((e)=> setSettlementConfig(e.target.value))}/>
                        <TextField id="parameterOne" className={classes.field} label="Para1" variant="standard" onChange={((e)=> setParameterOne(e.target.value))}/>
                        <TextField id="parameterTwo" className={classes.field} label="Para2" variant="standard" onChange={((e)=> setParameterTwo(e.target.value))}/>
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" variant="standard" onChange={((e)=> setAcquirerType(e.target.value))}/>
                    </Box>

                    <Box>
                        <StoreIdSelect setStoreIdChange={setStoreIdChange} />
                    </Box>


                    <AcquirerStoreFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </AcquirerStoreFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

