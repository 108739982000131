import React, { useState } from "react";
import { updateTerminalDto } from "../../service/TerminalRequest";

import {TextField, Button,Box, Container,makeStyles,
    CircularProgress,
    Typography,
 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
 
    Paper,
 
 
    Grid,
    Input,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
  } from "@material-ui/core";
import  {
    TerminalFormBottonControl
    } from './style/TerminalStyle';




const userStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    
    divider: {
        margin: theme.spacing(2, 0),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    }));
export  default function TerminalEditForm ({handleEditClose,handleEditSubmit,editTerminalDto}){
   
        const classes = userStyles();

        debugger
        console.log("editTerminalDto==",editTerminalDto.id);
        const [id,setId] = useState(editTerminalDto.id);
        const [ownership,setOwnership] = useState(editTerminalDto.ownership ? editTerminalDto.ownership :"");
        const [serialNumber,setSerialNumber] = useState(editTerminalDto.serialNumber ? editTerminalDto.serialNumber : "");
        const [modelNumber,setModelNumber] = useState(editTerminalDto.modelNumber ? editTerminalDto.modelNumber :"");
        const [simNumber,setSimNumber] = useState(editTerminalDto.simNumber ? editTerminalDto.simNumber : "");
        const [deviceType,setDeviceType] = useState(editTerminalDto.deviceType ? editTerminalDto.deviceType :"");
        const [status,setStatus] = useState(editTerminalDto.status ? editTerminalDto.status : "");
        const [terminalLanguage,setTerminalLanguage] = useState(editTerminalDto.terminalLanguage ? editTerminalDto.terminalLanguage : "");
        const [userId,setuserId] = useState(editTerminalDto.userId ? editTerminalDto.userId : "");
        const [storeId,setStoreId] = useState(editTerminalDto.storeId ? editTerminalDto.storeId : "");
        const [storeName,setStoreName] = useState(editTerminalDto.storeName ? editTerminalDto.storeName : "");
        const [deviceOpen,setdeviceOpen] = useState(false);
        const [sunmiTerminalVersion,setSunmiTerminalVersion] = useState(editTerminalDto.sunmiTerminalVersion ? editTerminalDto.sunmiTerminalVersion :"");
 
        const [sunmiTerminalVersionOpen,setSunmiTerminalVersionOpen] = useState(false);
        const handleSubmitData=(e) =>{
            e.preventDefault();
            console.log("TerminalEditForm====")
            console.log("ownership===",ownership)
            console.log("serialNumber===",serialNumber)
            console.log("modelNumber===",modelNumber)
            console.log("simNumber===",simNumber)
            console.log("deviceType===",deviceType)
            console.log("status===",status)
            console.log("terminalLanguage===",terminalLanguage)
            console.log("userId===",userId)
            console.log("storeId===",storeId)
            

            
        
            let saveTerminalDto = {
                    "id":id,
                    "ownership":ownership,
                    "serialNumber":serialNumber,
                    "modelNumber":modelNumber,
                    "simNumber":simNumber,
                    "deviceType":deviceType,
                    "status":status,
                    "terminalLanguage":terminalLanguage,
                    "userId":userId,
                    "storeId":storeId,
                    "sunmiTerminalVersion":sunmiTerminalVersion,
            }
            updateTerminalDto(saveTerminalDto);

            handleEditSubmit();
        }


        const handleDeviceChange = (event) => {
            setDeviceType(event.target.value);
          };

          const handleSunmiTerminalVersionChange = (event) => {
            setSunmiTerminalVersion(event.target.value);
          };
        
          const handleDeviceClose = () => {
            setdeviceOpen(false);
          };

          const handleSunmiTerminalVersionClose = () => {
            setSunmiTerminalVersionOpen(false);
          };
        

          const handleDeviceOpen = () => {
            setdeviceOpen(true);
          };
          const handleSunmiTerminalVersionOpen = () => {
            setSunmiTerminalVersionOpen(true);
          };
        return (
            <div>


                <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>

                        {!id && <TextField id="id" hidden value={id}  />}
                    


                    <Grid container spacing={3}>

                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField
                                onChange={(e=> setOwnership(e.target.value))}
                                className={classes.field}
                                id="standard-disabled"
                                label="Ownership"
                                variant="standard"
                                value={ownership}
                            
                                />
                            </Paper>
                        
                        </Grid>

                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField id="modelNumber"  className={classes.field} label="Model Number"  onChange={(e=> setModelNumber(e.target.value))} variant="standard" value={modelNumber} />
                            </Paper>
                        
                        </Grid>


                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField id="serialNumber"  className={classes.field} 
                            label="Serial Number" variant="standard"   onChange={(e=> setSerialNumber(e.target.value))} value= {serialNumber} />
                            </Paper>
                        
                        </Grid>
                    </Grid>



                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-controlled-open-select-label">DeviceType</InputLabel>
                                    <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={deviceOpen}
                                    onClose={handleDeviceClose}
                                    onOpen={handleDeviceOpen}
                                    value={deviceType}
                                    onChange={handleDeviceChange}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>  
                                    <MenuItem value="N5">N5</MenuItem>
                                    <MenuItem value="imin">imin</MenuItem>
                                    <MenuItem value="sumi">sumi</MenuItem>
                                    <MenuItem value="mobile">mobile</MenuItem>
                                    <MenuItem value="other">other</MenuItem>
                                    </Select>
                                </FormControl>
                          </Paper>
                        </Grid>

                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField id="status"  className={classes.field} label="Status" variant="standard"   onChange={(e=> setStatus(e.target.value))} value= {status} />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField id="terminalLanguage"  className={classes.field} label="Terminal Language"  onChange={(e=> setTerminalLanguage(e.target.value))} variant="standard" value={terminalLanguage} />
                            </Paper>
                        </Grid>
                     </Grid>
                    <Box>
                 

                   
                    <Grid container spacing={3}>
                       
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <TextField id="terminalLanguage"  className={classes.field} label="Store Name"    variant="standard" value={storeName} disabled />
                            </Paper>
                        
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                            <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-controlled-open-select-label">SunmiTerminalVersion</InputLabel>
                                    <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select-sunmiTerminalVersion"
                                    open={sunmiTerminalVersionOpen}
                                    onClose={handleSunmiTerminalVersionClose}
                                    onOpen={handleSunmiTerminalVersionOpen}
                                    value={sunmiTerminalVersion}
                                    onChange={handleSunmiTerminalVersionChange}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>  
                                    <MenuItem value="YES">YES</MenuItem>
                                    <MenuItem value="NO">NO</MenuItem>
                                   
                                    </Select>
                                </FormControl>
                          </Paper>
                        </Grid>
                    </Grid>
                    {!storeId && <TextField id="storeId"  className={classes.field} label="Store Id"  onChange={(e=> setStoreId(e.target.value))} variant="standard" value={storeId} />}
                    </Box>
                 

                   
                    
                    <TerminalFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </TerminalFormBottonControl>
                       
                    </form>
                </Container>
            </div>
        );
   
}

  