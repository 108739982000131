import request from "./ConfigRequest";

export const pageRefundDto = (refundTransactionQueryVo) => {
  return request({
    url: "refund/pageRefundDto",
    params: {
      index: refundTransactionQueryVo.index,
      pageSize: refundTransactionQueryVo.size,
      acquirerRefundId: refundTransactionQueryVo.acquirerRefundId,
      transactionId: refundTransactionQueryVo.transactionId,
      externalTransactionId: refundTransactionQueryVo.refundExternalTransactionId,
      transactionExternalTransactionId: refundTransactionQueryVo.transactionExternalTransactionId,
      clientRefundId: refundTransactionQueryVo.clientRefundId,
      refundStatus: refundTransactionQueryVo.refundStatus,
      storeId: refundTransactionQueryVo.storeId,
      recordStatus: refundTransactionQueryVo.recordStatus,
      id: refundTransactionQueryVo.id,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const updateRefundDto = (refundDto) => {
  return request({
    url: "refund/updateRefundDto",
    method: "put",
    data: refundDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchRefundDto = async (index, size, id) => {
  return request({
    url: "refund/pageRefundDto",
    params: {
      index,
      size,
      id,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};
