import request from "./ConfigRequest";
export const pageAddressDto = (index, size) => {
  return request({
    url: "address/pageAddressDto",
    params: {
      index,
      size,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const saveAddress = (addressDto) => {
  return request({
    url: "address/saveAddressDto",
    method: "post",
    data: addressDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateAddressDto = (addressDto) => {
  return request({
    url: "address/updateAddressDto",
    method: "put",
    data: addressDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchAddressDto = (index, size, postalCode) => {
  return request({
    url: "address/pageAddressDto",
    params: {
      index,
      size,
      postalCode,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const searchAddressLikeDto = (index, size, searchLikeKey) => {
  return request({
    url: "address/searchLikeAddressDto",
    params: {
      index,
      size,
      searchLikeKey,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};
