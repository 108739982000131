import React, { useState } from "react";
import { saveAcquirer } from "../../service/AcquirerRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    AcquirerFormBottonControl,
    DropDownContainer
} from './style/AcquirerStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function AcquirerForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [acquirerName, setAcquirerName] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [acquirerDescription, setAcquirerDescription] = useState('');
    const [enabledSettlement, setEnabledSettlement] = useState('');
    const [givenChargeRate, setGivenChargeRate] = useState('');
    const [defaultChargeRate, setDefaultChargeRate] = useState('');
    const [parameterTemplateJson, setParameterTemplateJson] = useState('');
    const [acquirerType, setAcquirerType] = useState('');

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('AcquirerEditForm====');
        console.log('acquirerName===', acquirerName);
        console.log('defaultCurrency===', defaultCurrency);
        console.log('acquirerDescription===', acquirerDescription);
        console.log('enabledSettlement===', enabledSettlement);
        console.log('givenChargeRate===', givenChargeRate);
        console.log('defaultChargeRate===', defaultChargeRate);
        console.log('parameterTemplateJson===', parameterTemplateJson);
        console.log('acquirerType===', acquirerType);


        const saveAcquirerDto = {
            'acquirerName': acquirerName,
            'defaultCurrency': defaultCurrency,
            'acquirerDescription': acquirerDescription,
            'enabledSettlement': enabledSettlement,
            'givenChargeRate': givenChargeRate,
            'defaultChargeRate': defaultChargeRate,
            'parameterTemplateJson': parameterTemplateJson,
            'acquirerType': acquirerType,

        };
        await saveAcquirer(saveAcquirerDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setAcquirerName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Acquirer Name"
                            variant="standard"

                        />

                        <TextField id="defaultCurrency" className={classes.field} label="Default Currency" variant="standard" onChange={((e)=> setDefaultCurrency(e.target.value))} />
                        <TextField id="acquirerDescription" className={classes.field} label="Acquirer Description" onChange={((e)=> setAcquirerDescription(e.target.value))} variant="standard"/>
                        <TextField id="givenChargeRate" className={classes.field} label="Given Charge Rate" variant="standard" onChange={((e)=> setGivenChargeRate(e.target.value))}/>
                        <TextField id="defaultChargeRate" className={classes.field} label="Default Charge Rate" onChange={((e)=> setDefaultChargeRate(e.target.value))} variant="standard" />
                        <TextField id="parameterTemplateJson" className={classes.field} label="Parameter Template Json" onChange={((e)=> setParameterTemplateJson(e.target.value))} variant="standard"/>
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" onChange={((e)=> setAcquirerType(e.target.value))} variant="standard" />


                        <DropDownContainer>
                            
                            <label>
                                Enabled Settlement
                                
                                    <select
                                    onChange={((e)=> setEnabledSettlement(e.target.value))}
                                    id="enabledSettlement">
                                        <option>YES</option>
                                        <option>NO</option>
                                    </select>
                                
                            </label>
                        </DropDownContainer>
                    </Box>


                    <AcquirerFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </AcquirerFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

