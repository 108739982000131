import React, { useState } from "react";
import { saveDisplayMessage } from "../../service/DisplayMessageRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    DisplayMessageFormBottonControl,
} from './style/DisplayMessageStyle';



const displayMessageStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function DisplayMessageForm({handleAddClose, handleSubmit}) {
    const classes = displayMessageStyles();


    const [failureReasonCode, setFailureReasonCode] = useState("");
    const [failureMessage, setFailureMessage] = useState("");
    const [displayMessage, setDisplayMessage] = useState("");

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('DisplayMessageForm====');
        console.log('failureReasonCode===', failureReasonCode);
        console.log('failureMessage===', failureMessage);
        console.log('displayMessage===', displayMessage);


        const saveDisplayMessageDto = {
            'failureReasonCode': failureReasonCode,
            'failureMessage': failureMessage,
            'displayMessage': displayMessage
        };
        await saveDisplayMessage(saveDisplayMessageDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                        <TextField
                            onChange={((e)=> setFailureReasonCode(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Failure Reason Code"
                            variant="standard"

                        />

                        <TextField id="failureMessage" className={classes.field} label="Failure Message" variant="standard" onChange={((e)=> setFailureMessage(e.target.value))}/>
                        <TextField id="displayMessage" className={classes.field} label="DisplayMessage" onChange={((e)=> setDisplayMessage(e.target.value))} variant="standard" />
                    
                    </Box>


                    <DisplayMessageFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </DisplayMessageFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

