import React, { useState } from "react";
import { updateUserStoreAccessDto } from "../../service/UserStoreAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    UserStoreAccessFormBottonControl,
} from './style/UserStoreAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function UserStoreAccessEditForm({handleEditClose, handleEditSubmit, editUserStoreAccessDto}) {
    const classes = userStyles();

    debugger;
    console.log('editUserStoreAccessDto==', editUserStoreAccessDto.accessType);
    const [id, setId] = useState(editUserStoreAccessDto.id);
    const [accessType, setAccessType] = useState(editUserStoreAccessDto.accessType);
    const [userId, setUserId] = useState(editUserStoreAccessDto.userId);
    const [storeId, setStoreId] = useState(editUserStoreAccessDto.StoreId);



    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('UserStoreAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('userId===', userId);
        console.log('storeId===', storeId);


        const saveUserStoreAccessDto = {
            'id': id,
            'accessType': accessType,
            'userId': userId,
            'storeId': storeId,
        };
        updateUserStoreAccessDto(saveUserStoreAccessDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"
                            value={accessType}

                        />

                        <TextField id="userId" className={classes.field} label="User Id" variant="standard" onChange={((e)=> setUserId(e.target.value))} value= {userId} />
                        <TextField id="storeId" className={classes.field} label="Store Id" onChange={((e)=> setStoreId(e.target.value))} variant="standard" value={storeId} />
                    </Box>

                    <UserStoreAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </UserStoreAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

