import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { userLogin } from "../../service/LoginRequest";
import { createSlice } from "@reduxjs/toolkit";
import {USERDTO,USER_TYPE_COMPANY}  from "../../components/common/constant/ManageConstant";
import { BigNumber } from "bignumber.js";
// Slice

// const initialLogin = localStorage.getItem("login")
//   ? JSON.parse(localStorage.getItem("login"))
//   : null;

const initialLogin = null;

const loginSlice = createSlice({
  name: "login",
  initialState: {
    login: initialLogin,
  },
  reducers: {
    loginSuccess: (state, action) => {
      const { jwtToken } = action.payload;
      console.log(action.payload);
      let id = action.payload.id ;
      let idString = new BigNumber(id).toString();
      action.payload.id = idString;
      let userDto =JSON.stringify(action.payload);
      console.log("loginSuccess===",userDto);
      localStorage.setItem("login", jwtToken);
      localStorage.setItem("id_token", jwtToken);
      localStorage.setItem(USERDTO,userDto);
    },
    logoutSuccess: (state, action) => {
      //  state.user = null;
      // localStorage.removeItem("user");
    },
  },
});

export default loginSlice.reducer;

// Actions

const { loginSuccess, logoutSuccess } = loginSlice.actions;

export const login = ({
  firstName,
  loginPassword,
  userDispatch,
  history,
  setIsLoading,
  setError,
}) => async (dispatch) => {
  setError(false);
  setIsLoading(true);
  try {
    let userVo = {
      firstName,
      loginPassword,
    };
    
    let userResponse = await userLogin(userVo);
    console.log("userResponse", userResponse);
    if (userResponse.code == ERROR_CODE_SUCCESS) {
      const dataResult = userResponse.data;
      dispatch(loginSuccess(dataResult));
      setError(null);
      setIsLoading(false);
      userDispatch({ type: "LOGIN_SUCCESS" });

      let userType = dataResult.type;
      if(USER_TYPE_COMPANY == userType) {
        history.push("/app/transaction");
      }else{
        history.push("/app/dashboard");
      }
    
    } else {
      setError(true);
      setIsLoading(false);
    }
  } catch (e) {
    return console.error(e.message);
  }
};

export const logout = ({ userDispatch, history }) => async (dispatch) => {
  try {
    debugger;
    // await api.post('/api/auth/logout/')
    localStorage.removeItem("login");
    localStorage.removeItem("id_token");
    localStorage.removeItem(USERDTO);

    userDispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push("/login");
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
