import AddressIdSelect from "../common/AddressIdSelect";
import BankDetailIdSelect from "../common/BankDetailIdSelect";
import BusinessIdSelect from "../common/BusinessIdSelect";
import React, { useState } from "react";
import UserIdSelect from "../common/UserIdSelect";
import { saveStore } from "../../service/StoreRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    StoreFormBottonControl,
} from './style/StoreStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function StoreForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [status,setStatus] = useState("");
    const [name,setName] = useState("");
    const [foreignName,setForeignName] = useState("");
    const [industryCode,setIndustryCode] = useState("");
    const [storeDescription,setStoreDescription] = useState("");
    const [storeLanguage,setStoreLanguage] = useState("");
    const [operatingDays,setOperatingDays] = useState("");
    const [userId,setUserId] = useState("");
    
    const [operatingHours,setOperatingHours] = useState("");
    const [voidPassword,setVoidPassword] = useState("");
    const [isOnlineStore,setIsOnlineStore] = useState("");
    const [settlementEmails,setSettlementEmails] = useState("");
    const [bankDetailId,setBankDetailId] = useState("");
    const [addressId,setAddressId] = useState("");
    const [businessId,setBusinessId] = useState("");


    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log("StoreEditForm====")
        console.log("status===",status)
        console.log("name===",name)
        console.log("foreignName===",foreignName)
        console.log("industryCode===",industryCode)
        console.log("storeDescription===",storeDescription)
        console.log("storeLanguage===",storeLanguage)
        console.log("operatingDays===",operatingDays)
        console.log("operatingHours===",operatingHours)
        console.log("userId===",userId)
        console.log("voidPassword===",voidPassword)
        console.log("isOnlineStore===",isOnlineStore)
        console.log("settlementEmails===",settlementEmails)
        console.log("bankDetailId===",bankDetailId)
        console.log("addressId===",addressId)
        console.log("businessId===",businessId)
            

            
        
        let saveStoreDto = {
            "status":status,
            "name":name,
            "foreignName":foreignName,
            "industryCode":industryCode,
            "storeDescription":storeDescription,
            "storeLanguage":storeLanguage,
            "operatingDays":operatingDays,
            "operatingHours":operatingHours,
            "userId":userId,
            "voidPassword":voidPassword,
            "isOnlineStore":isOnlineStore,
            "settlementEmails":settlementEmails,
            "bankDetailId":bankDetailId,
            "addressId":addressId,
            "businessId":businessId
            
    }
        await saveStore(saveStoreDto);

        console.log('===save====');

        handleSubmit();
    };

    const setAddressIdChange = (addressText) => {
        console.log("setAddressIdChange===", addressText);
        if (addressText) {
          setAddressId(addressText.id);
        }
      };

    const setBankDetailIdChange = (bankDetailText) => {
        console.log("setbankDetailIdChange===", bankDetailText);
        if (bankDetailText) {
          setBankDetailId(bankDetailText.id);
        }
      };

    const setBusinessIdChange = (businessText) => {
        console.log("setBusinessIdChange===", businessText);
        if (businessText) {
          setBusinessId(businessText.id);
        }
    };

    const setUserIdChange = (userText) => {
        console.log("setUserIdChange===", userText);
        if (userText) {
          setUserId(userText.id);
        }
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                    <TextField
                        onChange={(e=> setName(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Name"
                        variant="standard"
                       
                        />

                    <TextField id="status"  className={classes.field} label="Status" variant="standard"   onChange={(e=> setStatus(e.target.value))}/>
                    <TextField id="foreignName"  className={classes.field} label="Foreign Name"  onChange={(e=> setForeignName(e.target.value))} variant="standard"/>
                    <TextField id="industryCode"  className={classes.field} label="Industry Code" variant="standard"   onChange={(e=> setIndustryCode(e.target.value))}/>
                    <TextField id="storeDescription"  className={classes.field} label="Store Description"  onChange={(e=> setStoreDescription(e.target.value))} variant="standard"/>
                    <TextField id="storeLanguage"  className={classes.field} label="Store Language" variant="standard"   onChange={(e=> setStoreLanguage(e.target.value))} />
                    <TextField id="operatingDays"  className={classes.field} label="Operating Days"  onChange={(e=> setOperatingDays(e.target.value))} variant="standard"/>
                    <TextField id="operatingHours"  className={classes.field} label="Operating Hours"  onChange={(e=> setOperatingHours(e.target.value))} variant="standard"/>
                    <TextField id="voidPassword"  className={classes.field} label="VoidPassword"  onChange={(e=> setVoidPassword(e.target.value))} variant="standard"/>
                    <TextField id="isOnlineStore"  className={classes.field} label="Is Online Store"  onChange={(e=> setIsOnlineStore(e.target.value))} variant="standard"/>
                    <TextField id="settlementEmails"  className={classes.field} label="SettlementEmails"  onChange={(e=> setSettlementEmails(e.target.value))} variant="standard"/>
                   
                    </Box>

                    <Box>
                        <BankDetailIdSelect setBankDetailIdChange={setBankDetailIdChange} />
                    </Box>

                    <Box>
                        <AddressIdSelect setAddressIdChange={setAddressIdChange} />
                    </Box>

                    <Box>
                        <BusinessIdSelect setBusinessIdChange={setBusinessIdChange} />
                    </Box>
                    <Box>
                        <UserIdSelect setUserIdChange={setUserIdChange} />
                    </Box>

                    <StoreFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </StoreFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

