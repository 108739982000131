import React, { useState } from "react";
import { updateTransactionDto } from "../../service/TransactionRequest";

import {TextField, Button,Box, Container,makeStyles
  } from "@material-ui/core";
import  {
    TransactionFormBottonControl
    } from './style/TransactionStyle';



  const userStyles = makeStyles({
      field:{
          marginTop:20,
          marginBottom:10,
        
      },
      hidden:{
        display:"none",
      }
  })
export  default function TransactionEditForm ({handleEditClose,handleEditSubmit,editTransactionDto}){
   
        const classes = userStyles();

        debugger
        console.log("editTransactionDto==",editTransactionDto.id);
        const [id,setId] = useState(editTransactionDto.id);
        const [paymentStatus,setPaymentStatus] = useState(editTransactionDto.paymentStatus);
        
 
      
        const handleSubmitData=(e) =>{
            e.preventDefault();
            console.log("TransactionEditForm====")

            console.log("paymentStatus===",paymentStatus)
            

            
        
            let saveTransactionDto = {
                    "id":id,
                    "paymentStatus":paymentStatus
            }
            updateTransactionDto(saveTransactionDto);

            handleEditSubmit();
        }
        return (
            <div>


                <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                    <TextField
                        onChange={(e=> setPaymentStatus(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Payment Status"
                        variant="standard"
                        value={paymentStatus}
                       
                        />
                    </Box>
            
                    
                    <TransactionFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </TransactionFormBottonControl>
                       
                    </form>
                </Container>
            </div>
        );
   
}

  