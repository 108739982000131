import React, { useState } from "react";
import { saveUser } from "../../service/UserRequest";

import {TextField,
    Button, Box, Container, makeStyles,MenuItem,Select,Grid,Paper
} from '@material-ui/core';
import {
    UserFormBottonControl,
} from './style/UserStyle';



 const userStyles = makeStyles((theme) => ({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    
    divider: {
        margin: theme.spacing(2, 0),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    }));
export default function UserForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [foreignName, setForeignName] = useState('');
    const [preferredLanguage, setPreferredLanguage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [loginPassword, setLoginPassword] = useState('');

    const [enabledTwoFactor, setEnabledTwoFactor] = useState('');
    const [addressId, setAddressId] = useState('');

    const [typeOpen,setTypeOpen] = useState(false);
   

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('UserForm====');
        console.log('type===', type);
        console.log('title===', title);
        console.log('firstName===', firstName);
        console.log('lastName===', lastName);
        console.log('foreignName===', foreignName);
        console.log('preferredLanguage===', preferredLanguage);
        console.log('phoneNumber===', phoneNumber);
        console.log('emaiLAddress===', emailAddress);
        console.log('loginPassword===', loginPassword);

        console.log('enabledTwoFactor===', enabledTwoFactor);
        console.log('addressId===', addressId);



        const saveUserDto = {
            'type': type,
            'title': title,
            'firstName': firstName,
            'lastName': lastName,
            'foreignName': foreignName,
            'preferredLanguage': preferredLanguage,
            'phoneNumber': phoneNumber,
            'emailAddress': emailAddress,
            'loginPassword': loginPassword,
            'enabledTwoFactor': enabledTwoFactor,
            'addressId': addressId,
        };
        await saveUser(saveUserDto);

        console.log('===save====');

        handleSubmit();
    };

    const handleTypeClose = () => {
        setTypeOpen(false);
      };

      const handleTypeOpen = () => {
        setTypeOpen(true);
      };

      const handleTypeChange = (event) => {
        setType(event.target.value);
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={typeOpen}
                                    onClose={handleTypeClose}
                                    onOpen={handleTypeOpen}
                                    value={type}
                                    onChange={handleTypeChange}
                                    >
                                   
                                    <MenuItem value="company">company</MenuItem>
                                    <MenuItem value="manage">manage</MenuItem>
                                   
                                    </Select>
                        </Paper>
                            
                    </Grid>

                    <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField
                            onChange={((e)=> setFirstName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="FirstName"
                            variant="standard"

                        />
                        </Paper>
                            
                    </Grid>

                    <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="loginPassword" className={classes.field} label="LoginPassword" onChange={((e)=> setLoginPassword(e.target.value))} variant="standard"/> 
                        </Paper>
                            
                    </Grid>

                </Grid>

                    
                  
                        {/* <TextField id="type" className={classes.field} label="Type" variant="standard" onChange={((e)=> setType(e.target.value))} /> */}
                        {/* <TextField id="title" className={classes.field} label="Title" variant="standard" onChange={((e)=> setTitle(e.target.value))} /> */}
                        {/* <TextField id="lastName" className={classes.field} label="LastName" variant="standard" onChange={((e)=> setLastName(e.target.value))} /> */}
                      

                        {/* <TextField id="foreignName" className={classes.field} label="ForeignName" variant="standard" onChange={((e)=> setForeignName(e.target.value))} />
                        <TextField id="preferredLanguage" className={classes.field} label="PreferredLanguage" onChange={((e)=> setPreferredLanguage(e.target.value))} variant="standard"/>
                        <TextField id="phoneNumber" className={classes.field} label="PhoneNumber" onChange={((e)=> setPhoneNumber(e.target.value))} variant="standard"/>
                        <TextField id="emailAddress" className={classes.field} label="EmailAddress" onChange={((e)=> setEmailAddress(e.target.value))} variant="standard"/>*/}
                       
                    


                    {/* <Box>
                        <TextField id="enabledTwoFactor" className={classes.field} label="EnabledTwoFactor" variant="standard" onChange={((e)=> setEnabledTwoFactor(e.target.value))}/>
                    </Box>


                    <Box>
                        <TextField id="addressId" className={classes.field} label="AddressId" variant="standard" onChange={((e)=> setAddressId(e.target.value))} />
                    </Box> */}


                    <UserFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </UserFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

