import request from "./ConfigRequest";

export const pageBankDetailDto = (bankDetailQueryVo)=>{
    return request({
        url: 'bankDetail/pageBankDetailDto',
        params: {
            "index":bankDetailQueryVo.index,
            "pageSize":bankDetailQueryVo.size,
            "bankNameLike":bankDetailQueryVo.bankNameLike ? bankDetailQueryVo.bankNameLike: null,
            "bankCodeLike":bankDetailQueryVo.bankCodeLike ? bankDetailQueryVo.bankCodeLike: null,
            "bankAccountLike":bankDetailQueryVo.bankAccountLike ? bankDetailQueryVo.bankAccountLike: null,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveBankDetail = (bankDetailDto)=>{
    return request({
        url: 'bankDetail/saveBankDetailDto',
        method: 'post',
        data: bankDetailDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateBankDetailDto = (bankDetailDto)=>{
    return request({
        url: 'bankDetail/updateBankDetailDto',
        method: 'put',
        data: bankDetailDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchBankDetailDto = (index, size, bankName)=>{
    return request({
        url: 'bankDetail/pageBankDetailDto',
        params: {
            index,
            size,
            bankName,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};

export const searchBankDetailLikeDto = (index, size, searchLikeKey) => {
    return request({
      url: "bankDetail/searchLikeBankDetailDto",
      params: {
        index,
        size,
        searchLikeKey,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  };