import React, { useState } from "react";
import { saveRoleAccess } from "../../service/RoleAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    RoleAccessFormBottonControl,
} from './style/RoleAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function RoleAccessForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [accessType, setAccessType] = useState('');
    const [roleName, setRoleName] = useState('');
    const [roleId, setRoleId] = useState('');


    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('RoleAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('roleName===', roleName);
        console.log('roleId===', roleId);


        const saveRoleAccessDto = {
            'accessType': accessType,
            'roleName': roleName,
            'roleId': roleId,
        };
        await saveRoleAccess(saveRoleAccessDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"

                        />

                        <TextField id="roleName" className={classes.field} label="Role Name" variant="standard" onChange={((e)=> setRoleName(e.target.value))}/>
                        <TextField id="roleId" className={classes.field} label="Role Id" onChange={((e)=> setRoleId(e.target.value))} variant="standard"/>
                    </Box>


                    <RoleAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </RoleAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

