import React, { useState } from "react";
import { saveUserStoreAccess } from "../../service/UserStoreAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    UserStoreAccessFormBottonControl,
} from './style/UserStoreAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function UserStoreAccessForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [accessType, setAccessType] = useState('');
    const [userId, setUserId] = useState('');
    const [storeId, setStoreId] = useState('');



    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('UserStoreAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('userId===', userId);
        console.log('storeId===', storeId);



        const saveUserStoreAccessDto = {
            'accessType': accessType,
            'userId': userId,
            'storeId': storeId,
        };
        await saveUserStoreAccess(saveUserStoreAccessDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"

                        />

                        <TextField id="userId" className={classes.field} label="User Id" variant="standard" onChange={((e)=> setUserId(e.target.value))}/>
                        <TextField id="storeId" className={classes.field} label="Store Id" onChange={((e)=> setStoreId(e.target.value))} variant="standard"/>
                    </Box>


                    <UserStoreAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </UserStoreAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

