import request from "./ConfigRequest";

export const pageRegionDto = (index, size)=>{
    return request({
        url: 'region/pageRegionDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveRegion = (regionDto)=>{
    return request({
        url: 'region/saveRegionDto',
        method: 'post',
        data: regionDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateRegionDto = (regionDto)=>{
    return request({
        url: 'region/updateRegionDto',
        method: 'put',
        data: regionDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchRegionDto = (index, size, name)=>{
    return request({
        url: 'region/pageRegionDto',
        params: {
            index,
            size,
            name,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};

export const searchRegionLikeDto = (index, size, searchLikeKey) => {
    return request({
      url: "region/searchLikeRegionDto",
      params: {
        index,
        size,
        searchLikeKey,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  };
