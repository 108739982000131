import AcquirerStoreEditForm from "../acquirerStore/AcquirerStoreEditForm";
import AcquirerStoreForm from "../acquirerStore/AcquirerStoreForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";

import {
    CircularProgress, Typography, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Paper, Box,
} from '@material-ui/core';




import {
    pageAcquirerStoreDto,
    searchAcquirerStoreDto,
} from '../../service/AcquirerStoreRequest';


import {
    AcquirerStoreAddButtonControl,
    AcquirerStoreCloseBottonControl
} from '../acquirerStore/style/AcquirerStoreStyle';




export default function StoreAcquirerStoreTable({
    handleAcquirerStoreEditClose,
    editAcquirerStoreDtoParameter,
    parameterStoreId,
}) {
    const [page, setPage] = useState(1);
    const [data, setData] = useState([['Loading Data...']]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState(0);
    const [open, setOpen] = useState(false);

    const [addOpen, setAddOpen] = useState(false);
    const [editAcquirerStoreDto, setEditAcquirerStoreDto] = useState(editAcquirerStoreDtoParameter);


    const [refreshFlag, setRefreshFlag] = useState(false);

    const [searchName, setSearchName] = useState('');


    const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);
    const [storeId, setStoreId] = useState(parameterStoreId);


    const searchAcquirerStore = () => {
        console.log('======searchAcquirerStore', searchName);
        setSearchRefreshFlag(true);
    };


    const addAcquirerStore = () => {
        console.log('======handle add addAcquirerStore');
        setAddOpen(!addOpen);
    };


    const handleClose = () => {
        console.log('======handle close');
        setOpen(!open);
    };

    const handleEditSubmit = () => {
        console.log('======handle edit submit');
        setOpen(!open);

        setTimeout(() => {
            setRefreshFlag(true);
        }, 10);
    };

    const handleSubmit = () => {
        console.log('==handleSubmit=');

        setAddOpen(!addOpen);


        setRefreshFlag(true);
    };

    const handleAddClose = () => {
        debugger;
        console.log('======handle add close');
        setAddOpen(!addOpen);
        setTimeout(() => {
            setRefreshFlag(true);
        }, 1000);
    };


    const changePage = (page, sortOrder) => {
        setIsLoading(true);
        console.log('===page', page);
        page = page + 1;
        initData(page, 10);
    };

    const editAcquirerStoreData = (tableMeta) => {
        const row = tableMeta.rowIndex;
        console.log('row', row);
        setOpen(!open);
        const editAcquirerStoreDto = data[row];
        setEditAcquirerStoreDto(editAcquirerStoreDto);
    };
    console.log('count===', count);
    const options = {
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: 10,
        rowsPerPageOptions: [],
        sortOrder: {sortOrder},
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
            case 'changePage':
                changePage(tableState.page, tableState.sortOrder);
                break;
            case 'sort':
                this.sort(tableState.page, tableState.sortOrder);
                break;
            default:
                console.log('action not handled.');
            }
        },
    };

    const initData = (index, pageSize) => {
        setIsLoading(true);
        debugger;
        let acquirerStoreVo = {
            index: 1,
            paseSize: 10,
            storeId: new BigNumber(storeId).toString(),
        }
        pageAcquirerStoreDto(acquirerStoreVo).then((res) => {
            // const albums = res.albums;
            console.log('====data', res.data);

            setData(res.data.records);
            setIsLoading(false);
            setCount(res.data.total);
            setPage(index);
        });
        setIsLoading(false);
    };


    const searchAcquirerStoreData = (index, pageSize, searchName) => {
        setIsLoading(true);
       
        searchAcquirerStoreDto(index, pageSize, searchName).then((res) => {
            // const albums = res.albums;
            console.log('====data', res.data);

            setData(res.data.records);
            setIsLoading(false);
            setCount(res.data.total);
            setPage(index);
        });
        setIsLoading(false);
    };
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API

        initData(page, 10);
        setRefreshFlag(false);
    }, [refreshFlag]);


    useEffect(() => {
        debugger;
        console.log('===searchAcquirerStoreData=');
        if (searchRefreshFlag) {
            console.log('searchName===', searchName);
        }

        if (!searchName) return;
        searchAcquirerStoreData(page, 10, searchName);

        setSearchRefreshFlag(false);
    }, [searchRefreshFlag]);

    const configurationData = {
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        data: [['Loading Data...']],
        columns: [
            {
                name: 'id',
                label: 'id',
                options: {

                    customBodyRender: (value, tableMeta, updateValue) => {
                        // Here you can render a more complex display.
                        // You're given access to tableMeta, which has
                        // the rowData (as well as the original object data).
                        // See the console for a detailed look at this object.

                        if (value) {
                            return value.toString();
                          }
                          return value;
                    },
                },
            },

            {
                name: 'storeCurrency',
                label: 'Store Currency',
                options: {},
            },
            {
                name: 'parameterOne',
                label: 'Parameter One',
                options: {},
            },
            {
                name: 'parameterTwo',
                label: 'ParameterTwo',
                options: {},
            },
          
            
            {
                name: 'rate',
                label: 'MDR',
                options: {},
            },
            {
                name: 'settlementMethod',
                label: 'Settlement Method',
                options: {},
            },
            
            {
                name: 'storeName',
                label: 'StoreName',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // Here you can render a more complex display.
                        // You're given access to tableMeta, which has
                        // the rowData (as well as the original object data).
                        // See the console for a detailed look at this object.

                        if (value) {
                            return value.toString();
                          }
                          return value;
                    },
                },
            },
            {
                name: 'acquirerType',
                label: 'Acquirer Type',
                options: {},
            },
            {
                name: 'parameterThree',
                label: 'ParameterThree',
                options: {},
            },
            {
                name: 'parameterFour',
                label: 'ParameterFour',
                options: {},
            },
            {
                name: 'acquirerMode',
                label: 'AcquirerMode',
                options: {},
            },
            {
                name: 'creationTime',
                label: 'Create Time',
                options: {},
            },
            {
                name: 'chargeRateId',
                label: 'Charge Rate Id',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // Here you can render a more complex display.
                        // You're given access to tableMeta, which has
                        // the rowData (as well as the original object data).
                        // See the console for a detailed look at this object.

                        if (value) {
                            return value.toString();
                          }
                          return value;
                    },
                },
            },
            {
                name: 'settlementConfigId',
                label: 'Settlement Config Id',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // Here you can render a more complex display.
                        // You're given access to tableMeta, which has
                        // the rowData (as well as the original object data).
                        // See the console for a detailed look at this object.

                        if (value) {
                            return value.toString();
                          }
                          return value;
                    },
                },
            },
            {
                name: 'Action',
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Box>
                                <Button variant="outlined" size="small" color="primary"
                                    onClick={(e) => editAcquirerStoreData(tableMeta)}>
                                    Edit
                                </Button>

                            </Box>


                        );
                    },

                },
            },
        ],
    };


    return (
        <div>
            <div>
                <TextField id="id" label="Id" value={searchName} variant="standard"
                    onChange={((e) => setSearchName(e.target.value))}/>
            </div>

            <AcquirerStoreAddButtonControl>
                <Button className="btn right" variant="outlined" onClick={searchAcquirerStore}
                    color="primary">Search</Button>
                <Button className="btn right" variant="outlined" onClick={addAcquirerStore} color="primary">Add</Button>
            </AcquirerStoreAddButtonControl>


            <MUIDataTable
        title={
          <Typography variant="h6">
            Acquirer Store list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Acquirer Store</DialogTitle>
        <DialogContent>
          <AcquirerStoreEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editAcquirerStoreDto={editAcquirerStoreDto}
            parameterStoreId={storeId}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose}
      fullWidth
      maxWidth="lg">
        <DialogTitle>Add Acquirer Store</DialogTitle>
        <DialogContent>
          <Paper>
            <AcquirerStoreForm
              handleSubmit={handleSubmit}
              handleAddClose={handleAddClose}
              parameterStoreId={storeId}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <AcquirerStoreCloseBottonControl>
        <Button
          variant="outlined"
          onClick={handleAcquirerStoreEditClose}
          className="btn right"
        >
          close
        </Button>
      </AcquirerStoreCloseBottonControl>
    </div>
    );
}

