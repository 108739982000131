import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchAddressLikeDto } from "../../service/AddressRequest";

const index = 1;
const pageSize = 10;

export default function AddressIdSelect({ setAddressIdChange, address }) {
  const [addressText, setAddressText] = useState("");
  const [data, setData] = useState([]);
  const addressTextChange = (event, addressTextValue) => {
    console.log("addressTextValue===", addressTextValue);
    if (addressTextValue) {
      initAddressData(index, pageSize, addressTextValue);
    }
  };
  const addressTextOnChange = (event, newAddressText, detail) => {
    console.log("newAddressText===", newAddressText);
    setAddressText(newAddressText);
    setAddressIdChange(newAddressText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (address) {
      setAddressText(address);
    }
    initAddressData(index, pageSize, "");
  }, []);

  const initAddressData = async (index, pageSize, searchText) => {
    try {
      debugger;
      let addressDataResponse = await searchAddressLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("addressDataResponse===", addressDataResponse);
      let responseCode = addressDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = addressDataResponse.data;
        setData(dataRecord);
      }
    } catch (e) {
      console.log("initAddressData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.address;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Address info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, addressTextValue) =>
          addressTextChange(event, addressTextValue)
        }
        value={addressText}
        onChange={(event, newAddressText, detail) => {
          addressTextOnChange(event, newAddressText, detail);
        }}
      />
    </Stack>
  );
}
