import request from "./ConfigRequest";

export const pageBusinessSettlementSummaryDto = (index, size)=>{
    return request({
        url: 'businessSettlementSummary/pageBusinessSettlementSummaryDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveBusinessSettlementSummary = (businessSettlementSummaryDto)=>{
    return request({
        url: 'businessSettlementSummary/saveBusinessSettlementSummaryDto',
        method: 'post',
        data: businessSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateBusinessSettlementSummaryDto = (businessSettlementSummaryDto)=>{
    return request({
        url: 'businessSettlementSummary/updateBusinessSettlementSummaryDto',
        method: 'put',
        data: businessSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchBusinessSettlementSummaryDto = (index, size, id)=>{
    return request({
        url: 'businessSettlementSummary/pageBusinessSettlementSummaryDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
