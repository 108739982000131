import request from './ConfigRequest';
export const pageApiConfigDto = (index, size)=>{
    return request({
        url: 'apiConfig/pageApiConfigDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveApiConfig = (ApiConfigDto)=>{
    return request({
        url: 'apiConfig/saveApiConfigDto',
        method: 'post',
        data: ApiConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateApiConfigDto = (ApiConfigDto)=>{
    return request({
        url: 'apiConfig/updateApiConfigDto',
        method: 'put',
        data: ApiConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchApiConfigDto = (index, size, id)=>{
    return request({
        url: 'apiConfig/pageApiConfigDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
