import React, { useState } from "react";
import { updateUserBusinessAccessDto } from "../../service/UserBusinessAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    UserBusinessAccessFormBottonControl,
} from './style/UserBusinessAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function UserBusinessAccessEditForm({handleEditClose, handleEditSubmit, editUserBusinessAccessDto}) {
    const classes = userStyles();

    debugger;
    console.log('editUserBusinessAccessDto==', editUserBusinessAccessDto.accessType);
    const [id, setId] = useState(editUserBusinessAccessDto.id);
    const [accessType, setAccessType] = useState(editUserBusinessAccessDto.accessType);
    const [userId, setUserId] = useState(editUserBusinessAccessDto.userId);
    const [businessId, setBusinessId] = useState(editUserBusinessAccessDto.businessId);



    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('UserBusinessAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('userId===', userId);
        console.log('businessId===', businessId);


        const saveUserBusinessAccessDto = {
            'id': id,
            'accessType': accessType,
            'userId': userId,
            'businessId': businessId,
        };
        updateUserBusinessAccessDto(saveUserBusinessAccessDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"
                            value={accessType}

                        />

                        <TextField id="userId" className={classes.field} label="User Id" variant="standard" onChange={((e)=> setUserId(e.target.value))} value= {userId} />
                        <TextField id="businessId" className={classes.field} label="Business Id" onChange={((e)=> setBusinessId(e.target.value))} variant="standard" value={businessId} />
                    </Box>



                    <UserBusinessAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </UserBusinessAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

