import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import SettlementConfigEditForm from "./SettlementConfigEditForm";
import SettlementConfigForm from "./SettlementConfigForm";

import {
    CircularProgress, Typography, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Paper, Box,
} from '@material-ui/core';




import {
    pageSettlementConfigDto,
    searchSettlementConfigDto,
} from '../../service/SettlementConfigRequest';


import {
    SettlementConfigAddButtonControl,
} from './style/SettlementConfigStyle';




export default function SettlementConfigTable() {
    const [page, setPage] = useState(1);
    const [data, setData] = useState([['Loading Data...']]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState(0);
    const [open, setOpen] = useState(false);

    const [addOpen, setAddOpen] = useState(false);
    const [editSettlementConfigDto, setEditSettlementConfigDto] = useState({});


    const [refreshFlag, setRefreshFlag] = useState(false);

    const [searchName, setSearchName] = useState('');


    const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);


    const searchSettlementConfig = () => {
        console.log('======searchSettlementConfig', searchName);
        setSearchRefreshFlag(true);
    };


    const addSettlementConfig = () => {
        console.log('======handle add addSettlementConfig');
        setAddOpen(!addOpen);
    };


    const handleClose = () => {
        console.log('======handle close');
        setOpen(!open);
    };

    const handleEditSubmit = () => {
        console.log('======handle edit submit');
        setOpen(!open);
        debugger;

        setTimeout(() => {
            setRefreshFlag(true);
        }, 10);
    };

    const handleSubmit = () => {
        debugger;
        console.log('==handleSubmit=');

        setAddOpen(!addOpen);


        setRefreshFlag(true);
    };

    const handleAddClose = () => {
        debugger;
        console.log('======handle add close');
        setAddOpen(!addOpen);
        setTimeout(() => {
            setRefreshFlag(true);
        }, 1000);
    };


    const changePage = (page, sortOrder) => {
        setIsLoading(true);
        debugger;
        console.log('===page', page);
        page = page + 1;
        initData(page, 10);
    };

    const editSettlementConfigData = (tableMeta) => {
        debugger;
        const row = tableMeta.rowIndex;
        console.log('row', row);
        setOpen(!open);
        const editSettlementConfigDto = data[row];
        setEditSettlementConfigDto(editSettlementConfigDto);
    };
    console.log('count===', count);
    const options = {
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: 10,
        rowsPerPageOptions: [],
        sortOrder: {sortOrder},
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
            case 'changePage':
                changePage(tableState.page, tableState.sortOrder);
                break;
            case 'sort':
                this.sort(tableState.page, tableState.sortOrder);
                break;
            default:
                console.log('action not handled.');
            }
        },
    };

    const initData = (index, pageSize) => {
        setIsLoading(true);
   
        pageSettlementConfigDto(index, pageSize).then((res) => {
            // const albums = res.albums;
            console.log('====data', res.data);
            if(res&&res.data){
                setCount(res.data.total);
                setData(res.data.records);
            }
           
            setIsLoading(false);
            
            setPage(index);
        });
        setIsLoading(false);
    };


    const searchSettlementConfigData = (index, pageSize, searchName) => {
        setIsLoading(true);
        debugger;
        searchSettlementConfigDto(index, pageSize, searchName).then((res) => {
            // const albums = res.albums;
            console.log('====data', res.data);

            setData(res.data.records);
            setIsLoading(false);
            setCount(res.data.total);
            setPage(index);
        });
        setIsLoading(false);
    };
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API

        initData(page, 10);
        setRefreshFlag(false);
    }, [refreshFlag]);


    useEffect(() => {
        debugger;
        console.log('===searchSettlementConfigData=');
        if (searchRefreshFlag) {
            console.log('searchName===', searchName);
        }

        if (!searchName) return;
        searchSettlementConfigData(page, 10, searchName);

        setSearchRefreshFlag(false);
    }, [searchRefreshFlag]);

    const configurationData = {
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        data: [['Loading Data...']],
        columns: [
            {
                name: 'id',
                label: 'id',
                options: {

                    customBodyRender: (value, tableMeta, updateValue) => {
                        // Here you can render a more complex display.
                        // You're given access to tableMeta, which has
                        // the rowData (as well as the original object data).
                        return value.toString();
                    },
                },
            },

            {
                name: 'settlementMethod',
                label: 'Settlement Method',
                options: {},
            },
            {
                name: 'tplusN',
                label: 'T Plus N',
                options: {},
            },
            {
                name: 'ndays',
                label: 'N Days',
                options: {},
            },
            {
                name: 'storeName',
                label: 'Store',
                options: {},
            },
            {
                name: 'acquirerType',
                label: 'Acquirer Type',
                options: {},
            },
            {
                name: 'creationTime',
                label: 'Create Time',
                options: {},
            },
            {
                name: 'Action',
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Box>
                                <Button variant="outlined" size="small" color="primary"
                                    onClick={(e) => editSettlementConfigData(tableMeta)}>
                                    Edit
                                </Button>

                            </Box>


                        );
                    },

                },
            },
        ],
    };


    return (
        <div>
            <div>
                <TextField id="id" label="Id" value={searchName} variant="standard"
                    onChange={((e) => setSearchName(e.target.value))}/>
            </div>

            <SettlementConfigAddButtonControl>
                <Button className="btn right" variant="outlined" onClick={searchSettlementConfig}
                    color="primary">Search</Button>
                <Button className="btn right" variant="outlined" onClick={addSettlementConfig}
                    color="primary">Add</Button>
            </SettlementConfigAddButtonControl>


            <MUIDataTable
                title={
                    <Typography variant="h6">
                        SettlementConfig list
                        {isLoading &&
                            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}}/>}
                    </Typography>
                }
                data={data}
                columns={configurationData.columns}
                options={options}
            />


            <Dialog open={open} onClose={handleClose} PaperProps={{
                style: {
                    width: '100%',
                    height: '50%',
                },
            }}
            fullWidth
            maxWidth="lg">
                <DialogTitle>Edit SettlementConfig</DialogTitle>
                <DialogContent>

                    <SettlementConfigEditForm handleEditClose={handleClose} handleEditSubmit={handleEditSubmit}
                        editSettlementConfigDto={editSettlementConfigDto}/>
                </DialogContent>
            </Dialog>


            <Dialog open={addOpen} onClose={handleAddClose}
            fullWidth
            maxWidth="lg">
                <DialogTitle>Add SettlementConfig</DialogTitle>
                <DialogContent>
                    <Paper>

                        <SettlementConfigForm handleSubmit={handleSubmit} handleAddClose={handleAddClose}/>

                    </Paper>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

