import request from "./ConfigRequest";

export const pageItemDetailDto = (index, size)=>{
    return request({
        url: 'itemDetail/pageItemDetailDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveItemDetail = (itemDetailDto)=>{
    return request({
        url: 'itemDetail/saveItemDetailDto',
        method: 'post',
        data: itemDetailDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateItemDetailDto = (itemDetailDto)=>{
    return request({
        url: 'itemDetail/updateItemDetailDto',
        method: 'put',
        data: itemDetailDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchItemDetailDto = (index, size, id)=>{
    return request({
        url: 'itemDetail/pageItemDetailDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
