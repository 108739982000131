import AddressIdSelect from "../common/AddressIdSelect";
import React, { useState } from "react";
import { saveBankDetail } from "../../service/BankDetailRequest";

import {TextField,
    Button, Box, Container, makeStyles,
    Grid,
    Paper,
    Typography,
    Select,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import {
    BankDetailFormBottonControl,
} from './style/BankDetailStyle';


import BankCodeSelect from "../common/BankCodeSelect";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  
    errorMessageStyle:{
      maxWidth: 800,
      margin: `${theme.spacing(1)}px auto`,
      color:"red",
    },
  
    selectControl: {
      margin: theme.spacing(1),
      width: '50%',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


export default function BankDetailForm({handleAddClose, handleSubmit}) {
    const classes = useStyles();

    const [bankName,setBankName] = useState("");
    const [bankCode,setBankCode] = useState("");
    const [bankSwiftCode,setBankSwiftCode] = useState("");
    const [bankAccount,setBankAccount] = useState("");
 
    const [bankCodeId,setBankCodeId] = useState("");


    const handleSubmitData=async (e) =>{
    
        console.log("BankDetailEditForm====")
        console.log("bankName===",bankName)
        console.log("bankCode===",bankCode)
        console.log("bankSwiftCode===",bankSwiftCode)
        console.log("bankAccount===",bankAccount)
 



        let saveBankDetailDto = {
            "bankName":bankName,
            "bankCode":bankCode,
            "bankSwiftCode":bankSwiftCode,
            "bankAccount":bankAccount,
        
        }
        await saveBankDetail(saveBankDetailDto);

        console.log('===save====');

        handleSubmit();
    };

    const setAddressIdChange = (addressText) => {
        console.log("setAddressIdChange===", addressText);
        if (addressText) {
          setBillingAddressId(addressText.id);
        }
      };


      const setBankCodeIdChange = (bankCodeText) => {
        console.log("setBankCodeIdChange===", bankCodeText);
        if (bankCodeText) {
          setBankCodeId(bankCodeText.id);
          setBankCode(bankCodeText.bankCode);
          setBankName(bankCodeText.bankName);
        }
      };
    return (
        <div>


           


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>

                <Grid container spacing={1}>

                    <Grid container item xs={12} spacing={3}>
                       

                        <Grid item xs={6}>
                            <Paper className={classes.paper}>  
                            <BankCodeSelect setBankCodeIdChange={setBankCodeIdChange} /> 

                            </Paper>

                        </Grid>

                        <Grid item xs={6}>
                            <Paper className={classes.paper}> <TextField
                                required
                                
                                    onChange={(e) => setBankAccount(e.target.value)}
                                    className={classes.field}
                                    id="standard-disabled"
                                    label="Bank account"
                                    variant="standard"
                                    InputProps={{
                                    inputProps: {
                                        style: { textAlign: "left" },
                                    },
                                    }}
                                /></Paper>

                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6}>
                            <Paper className={classes.paper}>
                            <Button type="submit" variant="outlined" className="btn right">
                                Submit
                            </Button>
                            </Paper>
                            </Grid>
                            <Grid item xs={6}>
                            <Paper className={classes.paper}>
                            <Button
                                variant="outlined"
                                onClick={handleAddClose}
                                className="btn right"
                            >
                                close
                            </Button>
                            </Paper>
                    </Grid>
        </Grid>
                </Grid>
             

                </form>
         
        </div>
    );
}

