import React, { useState } from "react";
import RegionIdSelect from "../common/RegionIdSelect";
import { updateAddressDto } from "../../service/AddressRequest";

import {TextField, Button,Box, Container,makeStyles
  } from "@material-ui/core";
import  {
    AddressFormBottonControl
    } from './style/AddressStyle';



  const userStyles = makeStyles({
      field:{
          marginTop:20,
          marginBottom:10,
        
      },
      hidden:{
        display:"none",
      }
  })
export  default function AddressEditForm ({handleEditClose,handleEditSubmit,editAddressDto}){
   
        const classes = userStyles();

        debugger
        console.log("editAddressDto==",editAddressDto.postalCode);
        const [id,setId] = useState(editAddressDto.id);
        const [postalCode,setPostalCode] = useState(editAddressDto.postalCode);
        const [coordinates,setCoordinates] = useState(editAddressDto.coordinates);
        const [address,setAddress] = useState(editAddressDto.address);
        const [regionId,setRegionId] = useState(editAddressDto.regionId);
        const [region,setRegion] = useState(editAddressDto.region);
        
 
      
        const handleSubmitData=(e) =>{
            e.preventDefault();
            console.log("AddressEditForm====")
            console.log("postalCode===",postalCode)
            console.log("coordinates===",coordinates)
            console.log("address===",address)
            console.log("regionId===",regionId)
            

            
        
            let saveAddressDto = {
                    "id":id,
                    "postalCode":postalCode,
                    "coordinates":coordinates,
                    "address":address,
                    "regionId":regionId
            }
            updateAddressDto(saveAddressDto);

            handleEditSubmit();
        }

        const setRegionIdChange = (regionText) => {
            console.log("setRegionIdChange===", regionText);
            if (regionText) {
              setRegionId(regionText.id);
            }
          };
        return (
            <div>


                <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                    <TextField
                        onChange={(e=> setPostalCode(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Postal Code"
                        variant="standard"
                        value={postalCode}
                       
                        />

                    <TextField id="coordinates"  className={classes.field} label="Coordinates" variant="standard"   onChange={(e=> setCoordinates(e.target.value))} value= {coordinates} />
                    <TextField id="address"  className={classes.field} label="Address"  onChange={(e=> setAddress(e.target.value))} variant="standard" value={address} />
                    </Box>


                    <Box>
                        <RegionIdSelect
                        setRegionIdChange={setRegionIdChange}
                        region={region}
                        />
                    </Box>
                 
            
                    
                    <AddressFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </AddressFormBottonControl>
                       
                    </form>
                </Container>
            </div>
        );
   
}

  