import React, { useState } from "react";
import RegionIdSelect from "../common/RegionIdSelect";
import { saveAddress } from "../../service/AddressRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    AddressFormBottonControl,
} from './style/AddressStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function AddressForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [postalCode, setPostalCode] = useState('');
    const [coordinates, setCoordinates] = useState('');
    const [address, setAddress] = useState('');


    const [regionId, setRegionId] = useState('');


    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('AddressEditForm====');
        console.log('postalCode===', postalCode);
        console.log('coordinates===', coordinates);
        console.log('address===', address);
        console.log('regionId===', regionId);


        const saveAddressDto = {
            'postalCode': postalCode,
            'coordinates': coordinates,
            'address': address,
            'regionId': regionId,
        };
        await saveAddress(saveAddressDto);

        console.log('===save====');

        handleSubmit();
    };

    const setRegionIdChange = (regionText) => {
        console.log("setRegionIdChange===", regionText);
        if (regionText) {
          setRegionId(regionText.id);
        }
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setPostalCode(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Postal Code"
                            variant="standard"

                        />

                        <TextField id="coordinates" className={classes.field} label="Coordinates" variant="standard" onChange={((e)=> setCoordinates(e.target.value))} />
                        <TextField id="address" className={classes.field} label="Address" onChange={((e)=> setAddress(e.target.value))} variant="standard" />
                    </Box>


                    <Box>
                        <RegionIdSelect setRegionIdChange={setRegionIdChange} />
                    </Box>


                    <AddressFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </AddressFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

