import request from "./ConfigRequest";

export const pageBankJobDto = (index, size)=>{
    return request({
        url: 'bankJob/pageBankJobDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveBankJob = (bankJobDto)=>{
    return request({
        url: 'bankJob/saveBankJobDto',
        method: 'post',
        data: bankJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateBankJobDto = (bankJobDto)=>{
    return request({
        url: 'bankJob/updateBankJobDto',
        method: 'put',
        data: bankJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchBankJobDto = (index, size, id)=>{
    return request({
        url: 'bankJob/pageBankJobDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
