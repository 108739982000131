import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchAddressLikeDto } from "../../service/AddressRequest";
import {searchLikeBankCodeDto} from "../../service/BankCodeRequest";

const index = 1;
const pageSize = 10;

export default function BankCodeSelect({ setBankCodeIdChange, bankCodeText }) {
  const [bankCodeDisplayText, setBankCodeDisplayText] = useState("");
  const [data, setData] = useState([]);
  const bankCodeTextChange = (event, bankCodeTextValue) => {
    console.log("bankCodeTextValue===", bankCodeTextValue);

    if(bankCodeTextValue&& bankCodeTextValue.includes("/")){
      bankCodeTextValue = bankCodeTextValue.split("/")[0];
    }
    initBankCodeData(index, pageSize, bankCodeTextValue);
  };
  const bankCodeTextOnChange = (event, newBankCodeText, detail) => {
    console.log("bankCodeTextOnChange===", newBankCodeText);
    setBankCodeDisplayText(newBankCodeText);
    setBankCodeIdChange(newBankCodeText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (bankCodeText) {
      setBankCodeDisplayText(bankCodeText);
    }
    initBankCodeData(index, pageSize, "");
  }, []);

  const initBankCodeData = async (index, pageSize, searchText) => {
    try {
     
      let bankCodeDataResponse = await searchLikeBankCodeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("bankCodeDataResponse===", bankCodeDataResponse);
      let responseCode = bankCodeDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = bankCodeDataResponse.data;
        if(dataRecord){
          setData(dataRecord);
        }else{
          setData([{"bankName":"","bankCode":""}]);
        }
        
      }
    } catch (e) {
      console.log("initBankCodeData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          if(option.bankName&&option.bankCode){
            return option.bankName+"/"+option.bankCode;
          }else{
            return "NONE";
          }

          
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Bank code info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, bankCodeTextValue) =>
          bankCodeTextChange(event, bankCodeTextValue)
        }
        value={bankCodeDisplayText}
        onChange={(event, newBankCodeText, detail) => {
          bankCodeTextOnChange(event, newBankCodeText, detail);
        }}
      />
    </Stack>
  );
}
