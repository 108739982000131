import request from "./ConfigRequest";

export const pageSettlementTimeRecordDto = (index, size)=>{
    return request({
        url: 'settlementTimeRecord/pageSettlementTimeRecordDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveSettlementTimeRecord = (settlementTimeRecordDto)=>{
    return request({
        url: 'settlementTimeRecord/saveSettlementTimeRecordDto',
        method: 'post',
        data: settlementTimeRecordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateSettlementTimeRecordDto = (settlementTimeRecordDto)=>{
    return request({
        url: 'settlementTimeRecord/updateSettlementTimeRecordDto',
        method: 'put',
        data: settlementTimeRecordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchSettlementTimeRecordDto = (index, size, id)=>{
    return request({
        url: 'settlementTimeRecord/pageSettlementTimeRecordDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
