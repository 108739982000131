import React, { useState } from "react";
import { updateDenormalizedSettlementTransactionDto } from "../../service/DenormalizedSettlementTransactionRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    DenormalizedSettlementTransactionFormBottonControl,
} from './style/DenormalizedSettlementTransactionStyle';



const denormalizedsettlementtransactionStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function DenormalizedSettlementTransactionEditForm({handleEditClose, handleEditSubmit, editDenormalizedSettlementTransactionDto}) {
    const classes = denormalizedsettlementtransactionStyles();

    const [id, setId] = useState(editDenormalizedSettlementTransactionDto.id);

    const [isRefund, setIsRefund] = useState(editDenormalizedSettlementTransactionDto.isRefund);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('DenormalizedSettlementTransactionForm====');
        console.log('isRefund===', isRefund);


        const saveDenormalizedSettlementTransactionDto = {
            'id': id,
            'isRefund': isRefund
        };

        updateDenormalizedSettlementTransactionDto(saveDenormalizedSettlementTransactionDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setIsRefund(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Is Refund"
                            variant="standard"
                            value={isRefund}

                        />
                    </Box>


                    <DenormalizedSettlementTransactionFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </DenormalizedSettlementTransactionFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

