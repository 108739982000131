import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchBusinessLikeDto } from "../../service/BusinessRequest";

const index = 1;
const pageSize = 10;

export default function BusinessIdSelect({ setBusinessIdChange, business,clearSelectBusinessId }) {
  const [businessText, setBusinessText] = useState("");
  const [data, setData] = useState([]);
  const businessTextChange = (event, businessTextValue) => {
    console.log("businessTextValue===", businessTextValue);
    if (businessTextValue) {
      initBusinessData(index, pageSize, businessTextValue);
    }
    if(clearSelectBusinessId){
      clearSelectBusinessId();
    }
    
  };
  const businessTextOnChange = (event, newBusinessText, detail) => {
    console.log("newBusinessText===", newBusinessText);
    setBusinessText(newBusinessText);
    setBusinessIdChange(newBusinessText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (business) {
      setBusinessText(business);
    }
    initBusinessData(index, pageSize, "");
  }, []);

  const initBusinessData = async (index, pageSize, searchText) => {
    try {
      let businessDataResponse = await searchBusinessLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("businessDataResponse===", businessDataResponse);
      let responseCode = businessDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        if(businessDataResponse.data){
          let dataRecord = businessDataResponse.data;
          setData(dataRecord);
        }
       
      }
    } catch (e) {
      console.log("initBusinessData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if(option.businessDescription){
            return option.name + " / " + option.businessDescription;  
          }
          return option.name ;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Company info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, businessTextValue) =>
          businessTextChange(event, businessTextValue)
        }
        value={businessText}
        onChange={(event, newBusinessText, detail) => {
          businessTextOnChange(event, newBusinessText, detail);
        }}
      />
    </Stack>
  );
}
