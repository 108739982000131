import React, { useState } from "react";
import { updateSettlementRecordDto } from "../../service/SettlementRecordRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    SettlementRecordFormBottonControl,
} from './style/SettlementRecordStyle';



const settlementrecordStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function SettlementRecordEditForm({handleEditClose, handleEditSubmit, editSettlementRecordDto}) {
    const classes = settlementrecordStyles();

    const [id, setId] = useState(editSettlementRecordDto.id);

    const [settlementProgressStatus, setSettlementProgressStatus] = useState(editSettlementRecordDto.settlementProgressStatus);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('SettlementRecordForm====');
        console.log('settlementProgressStatus===', settlementProgressStatus);


        const saveSettlementRecordDto = {
            'id': id,
            'settlementProgressStatus': settlementProgressStatus
        };

        updateSettlementRecordDto(saveSettlementRecordDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setSettlementProgressStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Settlement Progress Status"
                            variant="standard"
                            value={settlementProgressStatus}

                        />
                    </Box>


                    <SettlementRecordFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </SettlementRecordFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

