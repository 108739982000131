import React, { useState } from "react";
import StoreIdSelect from "../common/StoreIdSelect";
import { updateAcquirerStoreDto } from "../../service/AcquirerStoreRequest";

import {TextField,
    Button, Box, Container, makeStyles,Grid,Paper,
} from '@material-ui/core';
import {
    AcquirerStoreFormBottonControl,
} from './style/AcquirerStoreStyle';



const userStyles = makeStyles((theme) =>({ field: {
    marginTop: 20,
    marginBottom: 10,

},
hidden: {
    display: 'none',
},
container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

})) ;
export default function AcquirerStoreEditForm({handleEditClose, handleEditSubmit, editAcquirerStoreDto}) {
    const classes = userStyles();

    
    console.log('editAcquirerStoreDto==', editAcquirerStoreDto.id);
    console.log('editAcquirerStoreDto==', editAcquirerStoreDto);
    const [id, setId] = useState(editAcquirerStoreDto.id);
    const [storeCurrency, setStoreCurrency] = useState(editAcquirerStoreDto.storeCurrency);
    const [parameterJson, setParameterJson] = useState(editAcquirerStoreDto.parameterJson != null ? editAcquirerStoreDto.parameterJson :"");
    const [chargeRateId, setChargeRateId] = useState(editAcquirerStoreDto.chargeRateId);
    
    const [storeId, setStoreId] = useState(editAcquirerStoreDto.storeId);
    const [acquirerType, setAcquirerType] = useState(editAcquirerStoreDto.acquirerType);
    const [parameterOne, setParameterOne] = useState(editAcquirerStoreDto.parameterOne != null ? editAcquirerStoreDto.parameterOne : "" );
    const [parameterTwo, setParameterTwo] = useState(editAcquirerStoreDto.parameterTwo != null ? editAcquirerStoreDto.parameterTwo :"" );
    const [store, setStore] = useState(editAcquirerStoreDto.storeName);
    const [chargeRate, setChargeRate] = useState(editAcquirerStoreDto.rate != null ? editAcquirerStoreDto.rate : "");
    const [settlementConfigId, setSettlementConfigId] = useState(editAcquirerStoreDto.settlementConfigId != null ? editAcquirerStoreDto.settlementConfigId : "");
    const [settlementMethod, setSettlementMethod] = useState(editAcquirerStoreDto.settlementMethod != null ? editAcquirerStoreDto.settlementMethod : "");

    const [parameterThree, setParameterThree] = useState(editAcquirerStoreDto.parameterThree != null ? editAcquirerStoreDto.parameterThree : "");
    const [parameterFour, setParameterFour] = useState(editAcquirerStoreDto.parameterFour != null ? editAcquirerStoreDto.parameterFour : "");
    const [acquirerMode, setAcquirerMode] = useState(editAcquirerStoreDto.acquirerMode != null ? editAcquirerStoreDto.acquirerMode : "");

    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('AcquirerStoreEditForm====');
        console.log('storeCurrency===', storeCurrency);
        console.log('parameterJson===', parameterJson);
        console.log('chargeRateId===', chargeRateId);
        console.log('storeId===', storeId);
        console.log('acquirerType===', acquirerType);
        console.log('parameterOne===', parameterOne);
        console.log('parameterTwo===', parameterTwo);
        console.log('parameterThree===', parameterThree);
        console.log('parameterFour===', parameterFour);
        console.log('acquirerMode===', acquirerMode);

        const saveAcquirerStoreDto = {
            'id': id,
            'storeCurrency': storeCurrency,
            'parameterJson': parameterJson,
            'chargeRateId': chargeRateId,
            'settlementMethod': settlementMethod,
            'storeId': storeId,
            'acquirerType': acquirerType,
            'parameterOne': parameterOne,
            'parameterTwo': parameterTwo,
            'chargeRate':chargeRate,
            'settlementConfigId':settlementConfigId,
            'parameterThree':parameterThree,
            'parameterFour':parameterFour,
            'acquirerMode': acquirerMode,
        };
        updateAcquirerStoreDto(saveAcquirerStoreDto);

        handleEditSubmit();
    };
    const setStoreIdChange = (storeText) => {
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
        }
      };
    return (
        <div>


            <Container >

           
                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>
                    <Grid container spacing={3}>
                    
                        <Grid item xs={4}>
                        <TextField
                            onChange={((e)=> setStoreCurrency(e.target.value))}
                            className={classes.field}
                          
                            label="Store Currency"
                            variant="standard"
                            value={storeCurrency}

                        />
                        </Grid>

                        <Grid item xs={4}>
                        <TextField id="parameterJson" className={classes.field} label="Parameter Json" variant="standard" onChange={((e)=> setParameterJson(e.target.value))} value= {parameterJson} />
                        </Grid>

                        <Grid item xs={4}>
                        <TextField id="parameterOne" className={classes.field} label="Para1" variant="standard" onChange={((e)=> setParameterOne(e.target.value))} value = {parameterOne}/>
                        </Grid>
                        <Grid item xs={4}>
                        <TextField id="parameterTwo" className={classes.field} label="Para2" variant="standard" onChange={((e)=> setParameterTwo(e.target.value))} value = {parameterTwo}/>
                        </Grid>
                        <Grid item xs={4}>

                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" variant="standard" onChange={((e)=> setAcquirerType(e.target.value))} value = {acquirerType}/>
                        </Grid>

                        <Grid item xs={4}>
                        <TextField id="settlementMethod" className={classes.field} label="Settlement method" variant="standard" onChange={((e)=> setSettlementMethod(e.target.value))} value = {settlementMethod}/>
                        </Grid>
                        <TextField id="chargeRateId"  label="Charge Rate Id" onChange={((e)=> setChargeRateId(e.target.value))} variant="standard" value={chargeRateId}  className={classes.hidden}/>
                        <Grid item xs={4}>
                        
                        <TextField id="rate" className={classes.field} label="rate" variant="standard" onChange={((e)=> setChargeRate(e.target.value))} value = {chargeRate}/>
                      
                       
                        </Grid>
                        <Grid item xs={4}>
                        <TextField id="parameterThree" className={classes.field} label="parameterThree" variant="standard" onChange={((e)=> setParameterThree(e.target.value))} value = {parameterThree}/>
                        
                       
                        </Grid>

                        <Grid item xs={4}>
                        <TextField id="parameterFour" className={classes.field} label="parameterFour" variant="standard" onChange={((e)=> setParameterFour(e.target.value))} value = {parameterFour}/>
                        </Grid>

                        <Grid item xs={4}>
                        <TextField id="acquirerMode" className={classes.field} label="acquirerMode" variant="standard" onChange={((e)=> setAcquirerMode(e.target.value))} value = {acquirerMode}/>
                        </Grid>
                 </Grid>
                    {/* <Box>
                        <TextField
                            onChange={((e)=> setStoreCurrency(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Store Currency"
                            variant="standard"
                            value={storeCurrency}

                        />

                        
                        <TextField id="chargeRateId" className={classes.field} label="Charge Rate Id" onChange={((e)=> setChargeRateId(e.target.value))} variant="standard" value={chargeRateId} />
                    </Box>


                    <Box>
                        <TextField id="settlementConfig" className={classes.field} label="Settlement Config" variant="standard" onChange={((e)=> setSettlementConfig(e.target.value))} value = {settlementConfig}/>
                        <TextField id="parameterOne" className={classes.field} label="Para1" variant="standard" onChange={((e)=> setParameterOne(e.target.value))} value = {parameterOne}/>
                        <TextField id="parameterTwo" className={classes.field} label="Para2" variant="standard" onChange={((e)=> setParameterTwo(e.target.value))} value = {parameterTwo}/>
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" variant="standard" onChange={((e)=> setAcquirerType(e.target.value))} value = {acquirerType}/>
                    </Box> */}

                    {/* <Box>
                        <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store} />
                    </Box> */}


                    <AcquirerStoreFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </AcquirerStoreFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

