import styled from "styled-components";

export const RefundAddButtonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:10px;
column-gap: 20px;
.btn{

}


.right{
   
  
}`;


export const RefundFormBottonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:30px;
column-gap: 20px;
 
.btn{

}


.right{
    
}`;
