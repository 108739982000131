import React, { useState } from "react";
import { saveNegativeBalanceJob } from "../../service/NegativeBalanceJobRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    NegativeBalanceJobFormBottonControl,
} from './style/NegativeBalanceJobStyle';



const negativeBalanceJobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function NegativeBalanceJobForm({handleAddClose, handleSubmit}) {
    const classes = negativeBalanceJobStyles();


    const [status, setStatus] = useState("");

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('NegativeBalanceJobForm====');
        console.log('status===', status);


        const saveNegativeBalanceJobDto = {
            'status': status
        };
        await saveNegativeBalanceJob(saveNegativeBalanceJobDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                    <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"

                        />
                    </Box>

                    <NegativeBalanceJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </NegativeBalanceJobFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

