import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import SettlementRecordEditForm from "./SettlementRecordEditForm";
import {
  pageSettlementRecordDto,
  searchSettlementRecordDto,
} from "../../service/SettlementRecordRequest";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
} from "@material-ui/core";

import { SettlementRecordAddButtonControl } from "./style/SettlementRecordStyle";

export default function SettlementRecordTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editSettlementRecordDto, setEditSettlementRecordDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const searchSettlementRecord = () => {
    console.log("======searchSettlementRecord", searchName);
    setSearchRefreshFlag(true);
  };

  const addSettlementRecord = () => {
    console.log("======handle add addSettlementRecord");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
    debugger;

    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    debugger;
    console.log("===page", page);
    page = page + 1;
    initData(page, 10);
  };

  const editSettlementRecordData = (tableMeta) => {
    debugger;
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editSettlementRecordDto = data[row];
    setEditSettlementRecordDto(editSettlementRecordDto);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = (index, pageSize) => {
    setIsLoading(true);
    debugger;
    pageSettlementRecordDto(index, pageSize).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
  };

  const searchSettlementRecordData = (index, pageSize, searchName) => {
    setIsLoading(true);
    searchSettlementRecordDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API

    initData(page, 10);
  }, [refreshFlag]);

  useEffect(() => {
    debugger;
    console.log("===searchSettlementRecordData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchSettlementRecordData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      {
        name: "batchId",
        label: "Batch Id",
        options: {},
      },
      {
        name: "settlementProgressStatus",
        label: "Settlement Progress Status",
        options: {},
      },
      {
        name: "settlementFinishTime",
        label: "Settlement Finish Time",
        options: {},
      },
      {
        name: "adminUserId",
        label: "Admin User Id",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editSettlementRecordData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <TextField
          id="id"
          label="Id"
          value={searchName}
          variant="standard"
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>

      <SettlementRecordAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchSettlementRecord}
          color="primary"
        >
          Search
        </Button>
      </SettlementRecordAddButtonControl>

      <MUIDataTable
        title={
          <Typography variant="h6">
            Settlement Record list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Settlement Record</DialogTitle>
        <DialogContent>
          <SettlementRecordEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editSettlementRecordDto={editSettlementRecordDto}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
