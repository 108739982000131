import React, { useState } from "react";
import { updateAcquirerDto } from "../../service/AcquirerRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    AcquirerFormBottonControl,
    DropDownContainer
} from './style/AcquirerStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function AcquirerEditForm({handleEditClose, handleEditSubmit, editAcquirerDto}) {
    const classes = userStyles();

    console.log('editAcquirerDto==', editAcquirerDto.acquirerName);
    const [id, setId] = useState(editAcquirerDto.id);
    const [acquirerName, setAcquirerName] = useState(editAcquirerDto.acquirerName);
    const [defaultCurrency, setDefaultCurrency] = useState(editAcquirerDto.defaultCurrency);
    const [acquirerDescription, setAcquirerDescription] = useState(editAcquirerDto.acquirerDescription);
    const [enabledSettlement, setEnabledSettlement] = useState(editAcquirerDto.enabledSettlement);
    const [givenChargeRate, setGivenChargeRate] = useState(editAcquirerDto.givenChargeRate);
    const [defaultChargeRate, setDefaultChargeRate] = useState(editAcquirerDto.defaultChargeRate);
    const [parameterTemplateJson, setParameterTemplateJson] = useState(editAcquirerDto.parameterTemplateJson);
    const [acquirerType, setAcquirerType] = useState(editAcquirerDto.acquirerType);

    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('AcquirerEditForm====');
        console.log('acquirerName===', acquirerName);
        console.log('defaultCurrency===', defaultCurrency);
        console.log('acquirerDescription===', acquirerDescription);
        console.log('enabledSettlement===', enabledSettlement);
        console.log('givenChargeRate===', givenChargeRate);
        console.log('defaultChargeRate===', defaultChargeRate);
        console.log('parameterTemplateJson===', parameterTemplateJson);
        console.log('acquirerType===', acquirerType);

        const saveAcquirerDto = {
            'id': id,
            'acquirerName': acquirerName,
            'defaultCurrency': defaultCurrency,
            'acquirerDescription': acquirerDescription,
            'enabledSettlement': enabledSettlement,
            'givenChargeRate': givenChargeRate,
            'defaultChargeRate': defaultChargeRate,
            'parameterTemplateJson': parameterTemplateJson,
            'acquirerType': acquirerType,

        };
        updateAcquirerDto(saveAcquirerDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setAcquirerName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Acquirer Name"
                            variant="standard"
                            value={acquirerName}

                        />

                        <TextField id="defaultCurrency" className={classes.field} label="Default Currency" variant="standard" onChange={((e)=> setDefaultCurrency(e.target.value))} value= {defaultCurrency} />
                        <TextField id="acquirerDescription" className={classes.field} label="Acquirer Description" onChange={((e)=> setAcquirerDescription(e.target.value))} variant="standard" value={acquirerDescription} />
                        
                        <TextField id="givenChargeRate" className={classes.field} label="Given Charge Rate" variant="standard" onChange={((e)=> setGivenChargeRate(e.target.value))} value= {givenChargeRate} />
                        <TextField id="defaultChargeRate" className={classes.field} label="Default Charge Rate" onChange={((e)=> setDefaultChargeRate(e.target.value))} variant="standard" value={defaultChargeRate} />
                        <TextField id="parameterTemplateJson" className={classes.field} label="Parameter Template Json" onChange={((e)=> setParameterTemplateJson(e.target.value))} variant="standard" value={parameterTemplateJson} />
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" onChange={((e)=> setAcquirerType(e.target.value))} variant="standard" value={acquirerType} />
                        
                        <DropDownContainer>
                            
                            <label>
                                Enabled Settlement
                                
                                    <select
                                    value={enabledSettlement}
                                    onChange={((e)=> setEnabledSettlement(e.target.value))}
                                    id="enabledSettlement">
                                        <option>YES</option>
                                        <option>NO</option>
                                    </select>
                                
                            </label>
                        </DropDownContainer>
                    </Box>

                    


                    <AcquirerFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </AcquirerFormBottonControl>

                </form>
            </Container>
        </div>
    );
}
