import AddressIdSelect from "../common/AddressIdSelect";
import React, { useState } from "react";
import { updateBankDetailDto } from "../../service/BankDetailRequest";

import {TextField, Button,Box,
     Container,makeStyles, Grid,
     Paper,
  } from "@material-ui/core";
import  {
    BankDetailFormBottonControl
    } from './style/BankDetailStyle';

import BankCodeSelect from "../common/BankCodeSelect";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    
    errorMessageStyle:{
        maxWidth: 800,
        margin: `${theme.spacing(1)}px auto`,
        color:"red",
    },
    
    selectControl: {
        margin: theme.spacing(1),
        width: '50%',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    }));
export  default function BankDetailEditForm ({handleEditClose,handleEditSubmit,editBankDetailDto}){
   
        const classes = useStyles();

     
        console.log("editBankDetailDto==",editBankDetailDto.bankName);
        const [id,setId] = useState(editBankDetailDto.id);
        const [bankName,setBankName] = useState(editBankDetailDto.bankName);
        const [bankCode,setBankCode] = useState(editBankDetailDto.bankCode);
        const [bankSwiftCode,setBankSwiftCode] = useState(editBankDetailDto.bankSwiftCode);
        const [bankAccount,setBankAccount] = useState(editBankDetailDto.bankAccount);
       
        const [address, setAddress] = useState(editBankDetailDto.address);

        
        const [bankCodeId,setBankCodeId] = useState("");
      
        const handleSubmitData=(e) =>{
             
            console.log("BankDetailEditForm====")
            console.log("bankName===",bankName)
            console.log("bankCode===",bankCode)
            console.log("bankSwiftCode===",bankSwiftCode)
            console.log("bankAccount===",bankAccount)
       

            

            
        
            let saveBankDetailDto = {
                    "id":id,
                    "bankName":bankName,
                    "bankCode":bankCode,
                    "bankSwiftCode":bankSwiftCode,
                    "bankAccount":bankAccount,
                 
            }
            debugger;
            updateBankDetailDto(saveBankDetailDto);

            handleEditSubmit();
        }

        

          const setBankCodeIdChange = (bankCodeText) => {
            console.log("setBankCodeIdChange===", bankCodeText);
            if (bankCodeText) {
              setBankCodeId(bankCodeText.id);
              setBankCode(bankCodeText.bankCode);
              setBankName(bankCodeText.bankName);
            }
          };
        return (
            <div>


            <form noValidate autoComplete="off" onSubmit={handleSubmitData}>

            <Grid container spacing={1}>
                     <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                                <Paper className={classes.paper}>  
                                <BankCodeSelect setBankCodeIdChange={setBankCodeIdChange}  bankCodeText={bankName}/> 

                                </Paper>

                            </Grid>

                            <Grid item xs={6}>
                            <Paper className={classes.paper}> <TextField
                                required
                                
                                    onChange={(e) => setBankAccount(e.target.value)}
                                    className={classes.field}
                                    id="standard-disabled"
                                    label="Bank account"
                                    variant="standard"
                                    value={bankAccount}
                                    InputProps={{
                                    inputProps: {
                                        style: { textAlign: "left" },
                                    },
                                    }}
                                /></Paper>

                        </Grid>

                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    {bankCode}
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    {bankName}
                                </Paper>
                            </Grid>
                        </Grid>
                     </Grid>

                    
                     <Grid container item xs={12} spacing={3}>

                            <Grid container item xs={12} spacing={3}>
                                 <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                                    </Paper>
                                      
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>
                                    </Paper>
                                      
                                 </Grid>
                            </Grid>

                           
                     </Grid>
            </Grid>
            </form>

                {/* <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                    <TextField
                        onChange={(e=> setBankName(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Bank Name"
                        variant="standard"
                        value={bankName}
                       
                        />

                    <TextField id="bankCode"  className={classes.field} label="Bank Code" variant="standard"   onChange={(e=> setBankCode(e.target.value))} value= {bankCode} />
                    <TextField id="bankSwiftCode"  className={classes.field} label="Bank Swift Code"  onChange={(e=> setBankSwiftCode(e.target.value))} variant="standard" value={bankSwiftCode} />
                    <TextField id="bankAccount"  className={classes.field} label="Bank Account"  onChange={(e=> setBankAccount(e.target.value))} variant="standard" value={bankAccount} />
                    </Box>

                    <Box>
                        <AddressIdSelect
                            setAddressIdChange={setAddressIdChange}
                            address={address}
                        />
                    </Box>
            
                    
                    <BankDetailFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </BankDetailFormBottonControl>
                       
                    </form>
                </Container> */}
            </div>
        );
   
}

  