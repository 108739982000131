import React, { useState } from "react";
import { saveOneTimePassword } from "../../service/OneTimePasswordRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    OneTimePasswordFormBottonControl,
} from './style/OneTimePasswordStyle';



const OneTimePasswordStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function OneTimePasswordForm({handleAddClose, handleSubmit}) {
    const classes = OneTimePasswordStyles();

    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [channel, setChannel] = useState("");
    const [code, setCode] = useState("");
    const [targetUserId, setTargetUserId] = useState("");
    const [emailJobId, setEmailJobId] = useState("");



    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('OneTimePasswordForm====');
        console.log('type===', type);
        console.log('status===', status);
        console.log('channel===', channel);
        console.log('code===', code);
        console.log('targetUserId===', targetUserId);
        console.log('emailJobId===', emailJobId);



        const saveOneTimePasswordDto = {
            'type': type,
            'status': status,
            'channel': channel,
            'code': code,
            'targetUserId': targetUserId,
            'emailJobId': emailJobId,};

        await saveOneTimePassword(saveOneTimePasswordDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    
                <Box>
                        <TextField
                            onChange={((e)=> setType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Type"
                            variant="standard"

                        />

                        <TextField id="status" className={classes.field} label="Status" variant="standard" onChange={((e)=> setStatus(e.target.value))}/>
                        <TextField id="channel" className={classes.field} label="Channel" onChange={((e)=> setChannel(e.target.value))} variant="standard" />
                        <TextField id="code" className={classes.field} label="Code" onChange={((e)=> setCode(e.target.value))} variant="standard"/>
                        <TextField id="targetUserId" className={classes.field} label="Target User Id" onChange={((e)=> setTargetUserId(e.target.value))} variant="standard" />
                        <TextField id="emailJobId" className={classes.field} label="Email Job Id" onChange={((e)=> setEmailJobId(e.target.value))} variant="standard" />

                    </Box>

                    <OneTimePasswordFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </OneTimePasswordFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

