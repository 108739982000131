export const TERMINAL_STATUS = "new";
export const USERDTO = "userDto";
export const USER_TYPE_COMPANY = "company";
export const USER_TYPE_MANAGE = "manage";

export const PAYMENT_STATUS_SUCCESS = "success";

export const PAYMENT_REFUND_STATUS_PARTIAL_REFUND = "partial_refund";

export const ALIPAY_STATIC_TITLE = "Alipay static QrCode";

export const WECHAT_H5_TITLE = "Wechat H5 QrCode";

export const WECHAT_H5_WARNING_MESSAGE = "Please scan mobile browser !";