import styled from "styled-components";

export const AcquirerAddButtonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:10px;
column-gap: 20px;
.btn{

}


.right{
   
  
}`;


export const AcquirerFormBottonControl = styled.div`

margin-left: 50%;
display: flex;
 
margin-bottom:30px;
column-gap: 20px;
 
.btn{

}


.right{
    
}`;

export const DropDownContainer = styled("div")`
  display:flex;
  width: 100px;
`;