import React, { useState } from "react";
import { updateDeviceTokenDto } from "../../service/DeviceTokenRequest";

import {TextField, Button,Box, Container,makeStyles
  } from "@material-ui/core";
import  {
    DeviceTokenFormBottonControl
    } from './style/DeviceTokenStyle';



  const userStyles = makeStyles({
      field:{
          marginTop:20,
          marginBottom:10,
        
      },
      hidden:{
        display:"none",
      }
  })
export  default function DeviceTokenEditForm ({handleEditClose,handleEditSubmit,editDeviceTokenDto}){
   
        const classes = userStyles();

        debugger
        console.log("editDeviceTokenDto==",editDeviceTokenDto.id);
        const [id,setId] = useState(editDeviceTokenDto.id);
        const [token,setToken] = useState(editDeviceTokenDto.token);
        const [status,setStatus] = useState(editDeviceTokenDto.status);
        const [storeId,setStoreId] = useState(editDeviceTokenDto.storeId);
        const [terminalId,setTerminalId] = useState(editDeviceTokenDto.terminalId);
        
 
      
        const handleSubmitData=(e) =>{
            e.preventDefault();
            console.log("DeviceTokenEditForm====")
            console.log("token===",token)
            console.log("status===",status)
            console.log("storeId===",storeId)
            console.log("terminalId===",terminalId)
            

            
        
            let saveDeviceTokenDto = {
                    "id":id,
                    "token":token,
                    "status":status,
                    "storeId":storeId,
                    "terminalId":terminalId,
            }
            updateDeviceTokenDto(saveDeviceTokenDto);

            handleEditSubmit();
        }
        return (
            <div>


                <Container >
                  

                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                    <TextField
                        onChange={(e=> setToken(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Token"
                        variant="standard"
                        value={token}
                       
                        />

                    <TextField id="status"  className={classes.field} label="Status" variant="standard"   onChange={(e=> setStatus(e.target.value))} value= {status} />
                    <TextField id="storeId"  className={classes.field} label="Store Id"  onChange={(e=> setStoreId(e.target.value))} variant="standard" value={storeId} />
                    
                    <TextField id="terminalId"  className={classes.field} label="Terminal Id" variant="standard"   onChange={(e=> setTerminalId(e.target.value))} value = {terminalId}/>
                    </Box>
            
                    
                    <DeviceTokenFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </DeviceTokenFormBottonControl>
                       
                    </form>
                </Container>
            </div>
        );
   
}

  