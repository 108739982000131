import DeviceTokenEditForm from "../deviceToken/DeviceTokenEditForm";
import DeviceTokenForm from "../deviceToken/DeviceTokenForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { updateStoreDto } from "../../service/StoreRequest";
import { StoreFormBottonControl } from "./style/StoreStyle";
import { useForm, Controller } from "react-hook-form";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Grid,
  makeStyles,
} from "@material-ui/core";
import {
  pageDeviceTokenDto,
  searchDeviceTokenDto,
} from "../../service/DeviceTokenRequest";

import {
  DeviceTokenAddButtonControl,
  DeviceTokenCloseBottonControl,
} from "../deviceToken/style/DeviceTokenStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function StoreDeviceTokenTable({
  handleDeviceTokenEditClose,
  editDeviceTokenDtoParameter,
  parameterStoreId,
  storeNameMobilePasswd,
}) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editDeviceTokenDto, setEditDeviceTokenDto] = useState(
    editDeviceTokenDtoParameter,
  );

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [storeId, setStoreId] = useState(parameterStoreId);

  console.log("storeId====", storeId);
  const searchDeviceToken = () => {
    console.log("======searchDeviceToken", searchName);
    setSearchRefreshFlag(true);
  };

  const addDeviceToken = () => {
    console.log("======handle add addDeviceToken");
    setAddOpen(!addOpen);
    console.log("storeId====addDeviceToken==", storeId);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
    debugger;

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleSubmit = () => {
    debugger;
    console.log("==handleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
    debugger;
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    debugger;
    console.log("===page", page);
    page = page + 1;
    initData(page, 10);
  };

  const editDeviceTokenData = (tableMeta) => {
    debugger;
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editDeviceTokenDto = data[row];
    setEditDeviceTokenDto(editDeviceTokenDto);
    console.log("storeId====editDeviceTokenData==", storeId);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = (index, pageSize) => {
    setIsLoading(true);
    debugger;
    let deviceTokenVo = {
      index: index,
      pageSize: pageSize,
      storeId: new BigNumber(storeId).toString(),
    };

    pageDeviceTokenDto(deviceTokenVo).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };

  const searchDeviceTokenData = (index, pageSize, searchName) => {
    setIsLoading(true);
    debugger;
    searchDeviceTokenDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API

    initData(page, 10);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
    debugger;
    console.log("===searchDeviceTokenData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchDeviceTokenData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      {
        name: "token",
        label: "Token",
        options: {},
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender==value", value);
            console.log("customBodyRender==tableMeta", tableMeta);
            console.log("customBodyRender==updateValue", updateValue);
            console.log("customBodyRender");
            console.log(tableMeta);
            return value;
          },
        },
      },
      {
        name: "storeId",
        label: "Store Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return value.toString();
            }
            return value;
          },
        },
      },
      {
        name: "terminalId",
        label: "Terminal Id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return value.toString();
            }
            return value;
          },
        },
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editDeviceTokenData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
      
      <div>
        <TextField
          id="token"
          label="Token"
          value={searchName}
          variant="standard"
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>

      <DeviceTokenAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchDeviceToken}
          color="primary"
        >
          Search
        </Button>
        <Button
          className="btn right"
          variant="outlined"
          onClick={addDeviceToken}
          color="primary"
        >
          Add
        </Button>
      </DeviceTokenAddButtonControl>

      <MUIDataTable
        title={
          <Typography variant="h6">
            DeviceToken list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit DeviceToken</DialogTitle>
        <DialogContent>
          <DeviceTokenEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editDeviceTokenDto={editDeviceTokenDto}
            parameterStoreId={storeId}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle>Add DeviceToken</DialogTitle>
        <DialogContent>
          <Paper>
            <DeviceTokenForm
              handleSubmit={handleSubmit}
              handleAddClose={handleAddClose}
              parameterStoreId={storeId}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <DeviceTokenCloseBottonControl>
        <Button
          variant="outlined"
          onClick={handleDeviceTokenEditClose}
          className="btn right"
        >
          close
        </Button>
      </DeviceTokenCloseBottonControl>
    </div>
  );
}
