import request from './ConfigRequest';
export const pageUserStoreAccessDto = (index, size)=>{
    return request({
        url: 'userStoreAccess/pageUserStoreAccessDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveUserStoreAccess = (userStoreAccessDto)=>{
    return request({
        url: 'userStoreAccess/saveUserStoreAccessDto',
        method: 'post',
        data: userStoreAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateUserStoreAccessDto = (userStoreAccessDto)=>{
    return request({
        url: 'userStoreAccess/updateUserStoreAccessDto',
        method: 'put',
        data: userStoreAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchUserStoreAccessDto = (index, size, accessType)=>{
    return request({
        url: 'userStoreAccess/pageUserStoreAccessDto',
        params: {
            index,
            size,
            accessType,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
