import React, { useState } from "react";
import { updateNegativeBalanceJobDto } from "../../service/NegativeBalanceJobRequest";
import { SignalWifiStatusbar4BarSharp } from "@mui/icons-material";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    NegativeBalanceJobFormBottonControl,
} from './style/NegativeBalanceJobStyle';



const negativebalancejobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function NegativeBalanceJobEditForm({handleEditClose, handleEditSubmit, editNegativeBalanceJobDto}) {
    const classes = negativebalancejobStyles();

    const [id, setId] = useState(editNegativeBalanceJobDto.id);

    const [status, setStatus] = useState(editNegativeBalanceJobDto.status);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('NegativeBalanceJobForm====');
        console.log('status===', status);


        const saveNegativeBalanceJobDto = {
            'id': id,
            'status': status
        };

        updateNegativeBalanceJobDto(saveNegativeBalanceJobDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                    <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"
                            value={status}

                        />
                    </Box>


                    <NegativeBalanceJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </NegativeBalanceJobFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

