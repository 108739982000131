import React, { useState } from "react";
import { saveBankJob } from "../../service/BankJobRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    BankJobFormBottonControl,
} from './style/BankJobStyle';



const bankJobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function BankJobForm({handleAddClose, handleSubmit}) {
    const classes = bankJobStyles();


    const [bankAccount, setBankAccount] = useState("");
    const [bankSwiftCode, setBankSwiftCode] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [settlementRecordId, setSettlementRecordId] = useState("");
    

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('BankJobForm====');
        console.log('bankAccount===', bankAccount);
        console.log('bankSwiftCode===', bankSwiftCode);
        console.log('totalAmount===', totalAmount);
        console.log('settlementRecordId===', settlementRecordId);


        const saveBankJobDto = {
            'bankAccount': bankAccount,
            'bankSwiftCode': bankSwiftCode,
            'totalAmount': totalAmount,
            'settlementRecordId': settlementRecordId
        };
        await saveBankJob(saveBankJobDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                        <TextField
                            onChange={((e)=> setBankAccount(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Bank Account"
                            variant="standard"

                        />

                        <TextField id="bankSwiftCode" className={classes.field} label="Bank Swift Code" variant="standard" onChange={((e)=> setBankSwiftCode(e.target.value))} />
                        <TextField id="totalAmount" className={classes.field} label="Total Amount" onChange={((e)=> setTotalAmount(e.target.value))} variant="standard" />
                        <TextField id="settlementRecordId" className={classes.field} label="Settlement Record Id" variant="standard" onChange={((e)=> setSettlementRecordId(e.target.value))}/>
                    
                    </Box>

                    <BankJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </BankJobFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

