import request from './ConfigRequest';
export const pageUserBusinessAccessDto = (index, size)=>{
    return request({
        url: 'userBusinessAccess/pageUserBusinessAccessDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveUserBusinessAccess = (userBusinessAccessDto)=>{
    return request({
        url: 'userBusinessAccess/saveUserBusinessAccessDto',
        method: 'post',
        data: userBusinessAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateUserBusinessAccessDto = (userBusinessAccessDto)=>{
    return request({
        url: 'userBusinessAccess/updateUserBusinessAccessDto',
        method: 'put',
        data: userBusinessAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchUserBusinessAccessDto = (index, size, accessType)=>{
    return request({
        url: 'userBusinessAccess/pageUserBusinessAccessDto',
        params: {
            index,
            size,
            accessType,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
