import React, { useState } from "react";
import { saveUserBusinessAccess } from "../../service/UserBusinessAccessRequest";
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    UserBusinessAccessFormBottonControl,
} from './style/UserBusinessAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function UserBusinessAccessForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [accessType, setAccessType] = useState('');
    const [userId, setUserId] = useState('');
    const [businessId, setBusinessId] = useState('');



    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('UserBusinessAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('userId===', userId);
        console.log('businessId===', businessId);



        const saveUserBusinessAccessDto = {
            'accessType': accessType,
            'userId': userId,
            'businessId': businessId,
        };
        try{
            debugger;
            let saveUserBusinessAccessResponse = await saveUserBusinessAccess(saveUserBusinessAccessDto);
            let code = saveUserBusinessAccessResponse.code;
            let message = saveUserBusinessAccessResponse.message;
            if(code == ERROR_CODE_SUCCESS){
                handleSubmit();
            }else{
                console.log("message===",messages);
            }
        }catch(e){
            console.log("saveUserBusinessAccess==",e);
        }

        console.log('===save====');

       
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"

                        />

                        <TextField id="userId" className={classes.field} label="User Id" variant="standard" onChange={((e)=> setUserId(e.target.value))}/>
                        <TextField id="businessId" className={classes.field} label="Business Id" onChange={((e)=> setBusinessId(e.target.value))} variant="standard"/>
                    </Box>



                    <UserBusinessAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </UserBusinessAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

