import React, { useEffect, useState } from "react";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import classNames from "classnames";
import useStyles from "./styles";
import { withRouter } from "react-router-dom";
import { Drawer, IconButton, List } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  BorderAll as TableIcon,
  ArrowBack as ArrowBackIcon,
  Person as UserIcon,
  Map as RegionIcon,
  Business as BusinessIcon,
  LocationOn as AddressIcon,
  SupervisedUserCircle as UserBusinessAccessIcon,
  AccountCircle as UserStoreAccessIcon,
  AssignmentInd as RoleAccessIcon,
  AccountBox as UserRoleAccessIcon,
  Apps as ApiConfigIcon,
  Payment as AcquirerIcon,
  Money as SettlementConfigIcon,
  Store as AcquirerStoreIcon,
  StarBorder as ChargeRateIcon,
  Devices as TerminalIcon,
  AccountBalance as BankDetailIcon,
  Lock as DeviceTokenIcon,
  Keyboard as OneTimePasswordIcon,
  Storefront as StoreIcon,
  InsertChart as TransactionIcon,
  ShoppingBasket as ItemDetailIcon,
  MoneyOff as RefundIcon,
  Error as DisplayMessageIcon,
  LocalAtm as SettlementRecordIcon,
  Block as DenormalizedSettlementTransactionIcon,
  Email as EmailJobIcon,
  RemoveCircle as NegativeBalanceJobIcon,
} from "@material-ui/icons";

// styles

// components

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";


import {USERDTO,USER_TYPE_COMPANY} from "../common/constant/ManageConstant"

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // {
  //   id: 1,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 3,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },

 

  {
    id: 16,
    label: "Company",
    link: "/app/business",
    icon: <BusinessIcon />,
    children: [
      { label: "Company list", link: "/app/business/table" },
      { label: "Adress list", link: "/app/address/table" },
      { label: "Region list", link: "/app/region/table" },
      { label: "Bank detail list", link: "/app/bankDetail/table" },
      { label: "External partner configuration list", link: "/app/apiConfig/table" },
      { label: "Merchant acquirer list", link: "/app/acquirer/table" },
      { label: "Settlement configurationList", link: "/app/settlementConfig/table" },
      { label: "Change rate list", link: "/app/chargeRate/table" },
      
      
    ],
  },


  {
    id: 17,
    label: "Store",
    link: "/app/store",
    icon: <StoreIcon />,
    children: [
      { label: "Store list", link: "/app/store/table" },
      { label: "Store acquirer List", link: "/app/acquirerStore/table" },
      { label: "Bank code list", link: "/app/bankCode/table" }
     
    ],
  },
   
  {
    id: 18,
    label: "Transaction",
    link: "/app/transaction",
    icon: <TransactionIcon />,
    children: [
      { label: "Transaction list", link: "/app/transaction/table" },
      { label: "Refund list", link: "/app/refund/table" },
     
      
  ],
  },

 
  
  
 
 
  {
    id: 19,
    label: "Terminal",
    link: "/app/terminal",
    icon: <TerminalIcon />,
    children: [
      { label: "Terminal List", link: "/app/terminal/table" },
      { label: "Device token list", link: "/app/deviceToken/table" },
    
    ],
  },
  

  {
    id: 20,
    label: "Settlement",
    link: "/app/settlementRecord/table",
    icon: <ItemDetailIcon />,
    children: [
      { label: "Settlement record list", link: "/app/settlementRecord/table" },
      { label: "Settlement time record list", link: "/app/settlementTimeRecord/table" },
      { label: "Settlement summary list", link: "/app/businessSettlementSummary/table" },
      { label: "Store settlement summary list", link: "/app/storeSettlementSummary/table" },
      { label: "Store acquirer settlement summary list", link: "/app/storeAcquirerSettlementSummary/table" },
      { label: "Settlement Transaction List", link: "/app/acquireStoreSettlementDetail/table" },
    ],
  },

  {
    id: 15,
    label: "User",
    link: "/app/user",
    icon: <UserIcon />,
    children: [
      { label: "User list", link: "/app/user/table" },
      { label: "Role list", link: "/app/roleAccess/table" },
      { label: "User role list", link: "/app/userRoleAccess/table" },
      { label: "One time password List", link: "/app/oneTimePassword/table" },
      {label: 'User business role list', link: '/app/userBusinessAccess/table'},
      
    ],
  },
  
  {
    id: 21,
    label: "Monitor",
    link: "/app/itemDetail",
    icon: <ItemDetailIcon />,
    children: [
      { label: "Item detail list", link: "/app/itemDetail/table" },
      { label: "Display message list", link: "/app/displayMessage/table" },
      { label: "Denormalized settlement list", link: "/app/denormalizedSettlementTransaction/table" },
      { label: "Email job list", link: "/app/emailJob/table" },
      { label: "Bank job list", link: "/app/bankJob/table" },
      { label: "Negative balance job list", link: "/app/negativeBalanceJob/table" }
    ],
  },
];



const structureCompany = [
   
 
  {
    id: 18,
    label: "Transaction",
    link: "/app/transaction",
    icon: <TransactionIcon />,
    children: [
      { label: "Transaction list", link: "/app/transaction/table" },
      { label: "Refund list", link: "/app/refund/table" },
     
      
  ],
  },

 

    
];
function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);
  const [menuData, setMenuData] = useState([]);

  const loadMenuData =() => {
    try{
      let userDto = localStorage.getItem(USERDTO);
      let jsonUserDto =JSON.parse(userDto);
      console.log("loadMenuData==userDto: " + userDto);
      let userType = jsonUserDto.type;
      if(userType ==USER_TYPE_COMPANY){
        // company menu
        setMenuData(structureCompany);
      }else{
        // manage menu
        setMenuData(structure);
      }
    }catch(e){
      console.log("loadMenuData==error", e);    
    }

  };
  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    loadMenuData();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {menuData.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
  
}

export default withRouter(Sidebar);
