import React, { useState } from "react";
import StoreIdSelect from "../common/StoreIdSelect";
import { updateSettlementConfigDto } from "../../service/SettlementConfigRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    SettlementConfigFormBottonControl,
} from './style/SettlementConfigStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function SettlementConfigEditForm({handleEditClose, handleEditSubmit, editSettlementConfigDto}) {
    const classes = userStyles();

    console.log('editSettlementConfigDto==', editSettlementConfigDto.id);
    const [id, setId] = useState(editSettlementConfigDto.id);
    const [settlementMethod, setSettlementMethod] = useState(editSettlementConfigDto.settlementMethod);
    const [tplusN, setTPlusN] = useState(editSettlementConfigDto.tplusN);
    const [ndays, setNDays] = useState(editSettlementConfigDto.ndays);
    const [storeId, setStoreId] = useState(editSettlementConfigDto.storeId);
    const [acquirerType, setAcquirerType] = useState(editSettlementConfigDto.acquirerType);
    const [store, setStore] = useState(editSettlementConfigDto.storeName);

    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('SettlementConfigEditForm====');
        console.log('settlementMethod===', settlementMethod);
        console.log('tplusN===', tplusN);
        console.log('ndays===', ndays);
        console.log('storeId===', storeId);
        console.log('acquirerType===', acquirerType);


        const saveSettlementConfigDto = {
            'id': id,
            'settlementMethod': settlementMethod,
            'tplusN': tplusN,
            'ndays': ndays,
            'storeId': storeId,
            'acquirerType': acquirerType,

        };
        updateSettlementConfigDto(saveSettlementConfigDto);

        handleEditSubmit();
    };
    const setStoreIdChange = (storeText) => {
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
        }
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setSettlementMethod(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Settlement Method"
                            variant="standard"
                            value={settlementMethod}

                        />

                        <TextField id="tplusN" className={classes.field} label="t Plus N" variant="standard" onChange={((e)=> setTPlusN(e.target.value))} value= {tplusN} />
                        <TextField id="ndays" className={classes.field} label="N Days" onChange={((e)=> setNDays(e.target.value))} variant="standard" value={ndays} />
                        <TextField id="storeId" className={classes.field} label="Store Id" variant="standard" onChange={((e)=> setStoreId(e.target.value))} value= {storeId} />
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" variant="standard" onChange={((e)=> setAcquirerType(e.target.value))} value= {acquirerType} />
                        
                    </Box>

                    <Box>
                        <StoreIdSelect 
                        setStoreIdChange={setStoreIdChange}
                        store = {store} />
                    </Box>


                    <SettlementConfigFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </SettlementConfigFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

