import request from "./ConfigRequest";

export const pageStoreAcquirerSettlementSummaryDto = (index, size)=>{
    return request({
        url: 'storeAcquirerSettlementSummary/pageStoreAcquirerSettlementSummaryDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveStoreAcquirerSettlementSummary = (storeAcquirerSettlementSummaryDto)=>{
    return request({
        url: 'storeAcquirerSettlementSummary/saveStoreAcquirerSettlementSummaryDto',
        method: 'post',
        data: storeAcquirerSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateStoreAcquirerSettlementSummaryDto = (storeAcquirerSettlementSummaryDto)=>{
    return request({
        url: 'storeAcquirerSettlementSummary/updateStoreAcquirerSettlementSummaryDto',
        method: 'put',
        data: storeAcquirerSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchStoreAcquirerSettlementSummaryDto = (index, size, id)=>{
    return request({
        url: 'storeAcquirerSettlementSummary/pageStoreAcquirerSettlementSummaryDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
