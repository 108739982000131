import request from "./ConfigRequest";

export const pageStoreSettlementSummaryDto = (index, size)=>{
    return request({
        url: 'storeSettlementSummary/pageStoreSettlementSummaryDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveStoreSettlementSummary = (storeSettlementSummaryDto)=>{
    return request({
        url: 'storeSettlementSummary/saveStoreSettlementSummaryDto',
        method: 'post',
        data: storeSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateStoreSettlementSummaryDto = (storeSettlementSummaryDto)=>{
    return request({
        url: 'storeSettlementSummary/updateStoreSettlementSummaryDto',
        method: 'put',
        data: storeSettlementSummaryDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchStoreSettlementSummaryDto = (index, size, id)=>{
    return request({
        url: 'storeSettlementSummary/pageStoreSettlementSummaryDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
