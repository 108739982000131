import request from './ConfigRequest';
export const pageSettlementConfigDto = (index, size)=>{
    return request({
        url: 'settlementConfig/pageSettlementConfigDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveSettlementConfig = (settlementConfigDto)=>{
    return request({
        url: 'settlementConfig/saveSettlementConfigDto',
        method: 'post',
        data: settlementConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateSettlementConfigDto = (settlementConfigDto)=>{
    return request({
        url: 'settlementConfig/updateSettlementConfigDto',
        method: 'put',
        data: settlementConfigDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchSettlementConfigDto = (index, size, id)=>{
    return request({
        url: 'settlementConfig/pageSettlementConfigDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
