import React, { useState } from "react";
import { saveTerminal } from "../../service/TerminalRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    TerminalFormBottonControl,
} from './style/TerminalStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function TerminalForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [ownership,setOwnership] = useState("");
    const [serialNumber,setSerialNumber] = useState("");
    const [modelNumber,setModelNumber] = useState("");
    const [simNumber,setSimNumber] = useState("");
    const [deviceType,setDeviceType] = useState("");
    const [status,setStatus] = useState("");
    const [terminalLanguage,setTerminalLanguage] = useState("");
    const [userId,setuserId] = useState("");
    const [storeId,setStoreId] = useState("");


    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log("TerminalEditForm====")
            console.log("ownership===",ownership)
            console.log("serialNumber===",serialNumber)
            console.log("modelNumber===",modelNumber)
            console.log("simNumber===",simNumber)
            console.log("deviceType===",deviceType)
            console.log("status===",status)
            console.log("terminalLanguage===",terminalLanguage)
            console.log("userId===",userId)
            console.log("storeId===",storeId)

            

            
        
            let saveTerminalDto = {
                    "ownership":ownership,
                    "serialNumber":serialNumber,
                    "modelNumber":modelNumber,
                    "simNumber":simNumber,
                    "deviceType":deviceType,
                    "status":status,
                    "terminalLanguage":terminalLanguage,
                    "userId":userId,
                    "storeId":storeId
            }
        await saveTerminal(saveTerminalDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                <Box>
                    <TextField
                        onChange={(e=> setOwnership(e.target.value))}
                        className={classes.field}
                        id="standard-disabled"
                        label="Ownership"
                        variant="standard"
                       
                        />

                    <TextField id="serialNumber"  className={classes.field} label="Serial Number" variant="standard"   onChange={(e=> setSerialNumber(e.target.value))} />
                    <TextField id="modelNumber"  className={classes.field} label="Model Number"  onChange={(e=> setModelNumber(e.target.value))} variant="standard"/>
                    <TextField id="simNumber"  className={classes.field} label="Sim Number" variant="standard"   onChange={(e=> setSimNumber(e.target.value))}/>
                    <TextField id="deviceType"  className={classes.field} label="Device Type"  onChange={(e=> setDeviceType(e.target.value))} variant="standard" />
                    <TextField id="status"  className={classes.field} label="Status" variant="standard"   onChange={(e=> setStatus(e.target.value))}/>
                    <TextField id="terminalLanguage"  className={classes.field} label="Terminal Language"  onChange={(e=> setTerminalLanguage(e.target.value))} variant="standard"/>
                    <TextField id="userId"  className={classes.field} label="User Id" variant="standard"   onChange={(e=> setuserId(e.target.value))}/>
                    <TextField id="storeId"  className={classes.field} label="Store Id"  onChange={(e=> setStoreId(e.target.value))} variant="standard"/>
                    </Box>
                
                    <TerminalFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </TerminalFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

