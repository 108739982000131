import React, { useState } from "react";
import StoreIdSelect from "../common/StoreIdSelect";
import { saveSettlementConfig } from "../../service/SettlementConfigRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    SettlementConfigFormBottonControl
} from './style/SettlementConfigStyle';


const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function SettlementConfigForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [settlementMethod, setSettlementMethod] = useState('');
    const [tplusN, setTPlusN] = useState('');
    const [ndays, setNDays] = useState('');
    const [storeId, setStoreId] = useState('');
    const [acquirerType, setAcquirerType] = useState('');


    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('SettlementConfigEditForm====');
        console.log('settlementMethod===', settlementMethod);
        console.log('tPlusN===', tplusN);
        console.log('nDays===', ndays);
        console.log('storeId===', storeId);
        console.log('acquirerType===', acquirerType);


        const saveSettlementConfigDto = {
            'settlementMethod': settlementMethod,
            'tplusN': tplusN,
            'tPlusN': ndays,
            'storeId': storeId,
            'acquirerType': acquirerType,

        };
        await saveSettlementConfig(saveSettlementConfigDto);

        console.log('===save====');

        handleSubmit();
    };

    const setStoreIdChange = (storeText) => {
        console.log("setStoreIdChange===", storeText);
        if (storeText) {
          setStoreId(storeText.id);
        }
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setSettlementMethod(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Settlement Method"
                            variant="standard"

                        />

                        <TextField id="tplusN" className={classes.field} label="t Plus N" variant="standard" onChange={((e)=> setTPlusN(e.target.value))}/>
                        <TextField id="ndays" className={classes.field} label="N Days" onChange={((e)=> setNDays(e.target.value))} variant="standard"/>
                        <TextField id="acquirerType" className={classes.field} label="Acquirer Type" variant="standard" onChange={((e)=> setAcquirerType(e.target.value))}/>
                        
                    </Box>

                    <Box>
                        <StoreIdSelect setStoreIdChange={setStoreIdChange} />
                    </Box>


                    <SettlementConfigFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </SettlementConfigFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

