import React, { useState } from "react";
import { saveChargeRate } from "../../service/ChargeRateRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    ChargeRateFormBottonControl,
} from './style/ChargeRateStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function ChargeRateForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [storeId, setStoreId] = useState('');
    const [acquirerCode, setAcquirerCode] = useState('');

    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('ChargeRateEditForm====');
        console.log('storeId===', storeId);
        console.log('acquirerCode===', acquirerCode);


        const saveChargeRateDto = {
            'storeId': storeId,
            'acquirerCode': acquirerCode,
        };
        await saveChargeRate(saveChargeRateDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>

                    <Box>
                        <TextField id="storeId" className={classes.field} label="Store Id" variant="standard" onChange={((e)=> setStoreId(e.target.value))}/>
                        <TextField id="acquirerCode" className={classes.field} label="Acquirer Code" variant="standard" onChange={((e)=> setAcquirerCode(e.target.value))}/>

                    </Box>


                    <ChargeRateFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </ChargeRateFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

