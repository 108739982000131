import React, { useState } from "react";
import { updateRoleAccessDto } from "../../service/RoleAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    RoleAccessFormBottonControl,
} from './style/RoleAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function RoleAccessEditForm({handleEditClose, handleEditSubmit, editRoleAccessDto}) {
    const classes = userStyles();

    debugger;
    console.log('editRoleAccessDto==', editRoleAccessDto.postalCode);
    const [id, setId] = useState(editRoleAccessDto.id);
    const [accessType, setAccessType] = useState(editRoleAccessDto.accessType);
    const [roleName, setRoleName] = useState(editRoleAccessDto.roleName);
    const [roleId, setRoleId] = useState(editRoleAccessDto.roleId);


    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('RoleAccessEditForm====');
        console.log('accessType===', accessType);
        console.log('roleName===', roleName);
        console.log('roleId===', roleId);

        const saveRoleAccessDto = {
            'id': id,
            'accessType': accessType,
            'roleName': roleName,
            'roleId': roleId,
        };
        updateRoleAccessDto(saveRoleAccessDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setAccessType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Access Type"
                            variant="standard"
                            value={accessType}

                        />

                        <TextField id="roleName" className={classes.field} label="Role Name" variant="standard" onChange={((e)=> setRoleName(e.target.value))} value= {roleName} />
                        <TextField id="roleId" className={classes.field} label="Role Id" onChange={((e)=> setRoleId(e.target.value))} variant="standard" value={roleId} />
                    </Box>


                    <RoleAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </RoleAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

