import React, { useState } from "react";
import { updateUserRoleAccessDto } from "../../service/UserRoleAccessRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    UserRoleAccessFormBottonControl,
} from './style/UserRoleAccessStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function UserRoleAccessEditForm({handleEditClose, handleEditSubmit, editUserRoleAccessDto}) {
    const classes = userStyles();

    debugger;
    console.log('editUserRoleAccessDto==', editUserRoleAccessDto.id);
    const [id, setId] = useState(editUserRoleAccessDto.id);
    const [userId, setUserId] = useState(editUserRoleAccessDto.userId);
    const [roleId, setRoleId] = useState(editUserRoleAccessDto.roleId);



    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('UserRoleAccessEditForm====');
        console.log('userId===', userId);
        console.log('roleId===', roleId);


        const saveUserRoleAccessDto = {
            'id': id,
            'userId': userId,
            'roleId': roleId,
        };
        updateUserRoleAccessDto(saveUserRoleAccessDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField
                            onChange={((e)=> setUserId(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="User Id"
                            variant="standard"
                            value={userId}

                        />
                        <TextField id="roleId" className={classes.field} label="Role Id" onChange={((e)=> setRoleId(e.target.value))} variant="standard" value={roleId} />
                    </Box>


                    <UserRoleAccessFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </UserRoleAccessFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

