import request from "./ConfigRequest";

export const pageAcquirerStoreDto = (acquirerStoreVo)=>{
    return request({
        url: 'acquirerStore/pageAcquirerStoreDto',
        params: acquirerStoreVo,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveAcquirerStore = (acquirerStoreDto)=>{
    return request({
        url: 'acquirerStore/saveAcquirerStoreDto',
        method: 'post',
        data: acquirerStoreDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateAcquirerStoreDto = (acquirerStoreDto)=>{
    return request({
        url: 'acquirerStore/updateAcquirerStoreDto',
        method: 'put',
        data: acquirerStoreDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchAcquirerStoreDto = (index, size, id)=>{
    return request({
        url: 'acquirerStore/pageAcquirerStoreDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
