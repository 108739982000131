import request from './ConfigRequest';
export const pageChargeRateDto = (index, size)=>{
    return request({
        url: 'chargeRate/pageChargeRateDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveChargeRate = (chargeRateDto)=>{
    return request({
        url: 'chargeRate/saveChargeRateDto',
        method: 'post',
        data: chargeRateDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateChargeRateDto = (chargeRateDto)=>{
    return request({
        url: 'chargeRate/updateChargeRateDto',
        method: 'put',
        data: chargeRateDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchChargeRateDto = (index, size, id)=>{
    return request({
        url: 'chargeRate/pageChargeRateDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
