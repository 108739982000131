import AcquirerStoreEditForm from "../acquirerStore/AcquirerStoreEditForm";
import AcquirerStoreForm from "../acquirerStore/AcquirerStoreForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";

import {
    CircularProgress, Typography, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Paper, Box,makeStyles,
} from '@material-ui/core';




 
import QRCode from 'react-qr-code';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function StaticQrcode({
    size,
    linkUrl,
    staticQrcodeClose,
    warningMessage
     
}) {
   
    const classes = useStyles();


    return (
        <div >
            <div  className={classes.paper}>
            {linkUrl&&<QRCode
            title="title"
            value={linkUrl}
           size={size}
          />}
            </div>
            
           {
            warningMessage&&(<div className={classes.paper} >
            {warningMessage}
            </div>)
           } 
            
            
            <Paper className={classes.paper}><Button onClick={() => staticQrcodeClose()} variant={"outlined"} color="primary">Close</Button></Paper>
    </div>
    );
}

