import AddressIdSelect from "../common/AddressIdSelect";
import BankDetailIdSelect from "../common/BankDetailIdSelect";
import React, { useState } from "react";
import UserIdSelect from "../common/UserIdSelect";
import { saveBusiness } from "../../service/BusinessRequest";
import { BusinessFormBottonControl } from "./style/BusinessStyle";
import { styled } from "@mui/material/styles";

import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";

import {
    TextField,
  Button,
  Box,
  Container,
  makeStyles,
  Grid,
  Paper,
  Typography,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  errorMessageStyle:{
    maxWidth: 800,
    margin: `${theme.spacing(1)}px auto`,
    color:"red",
  },

  selectControl: {
    margin: theme.spacing(1),
    width: '50%',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function BusinessForm({ handleAddClose, handleSubmit }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [foreignName, setForeignName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");

  const [isApiPartner, setIsApiPartner] = useState("");
  const [settlementEmails, setSettlementEmails] = useState("");
  const [contractFilePath, setContractFilePath] = useState("");

  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [defaultBankDetailId, setDefaultBankDetailId] = useState("");
  const [addressId, setAddressId] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [phoneNumber,setPhoneNumber] = useState("");

  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [businessIdentificationNumber,setBusinessIdentificationNumber] = useState("");

  const [contactName,setContactName] = useState("");

  const [address,setAddress] = useState("");
  const [bankName,setBankName] = useState("");
  const [bankAccount,setBankAccount] = useState("");

  const [businessSettlementType,setBusinessSettlementType] = useState("");
  const handleSubmitData = async (e) => {
     
    try{
      console.log("BusinessForm====");
      console.log("name===", name);
      console.log("foreignName===", foreignName);
      console.log("businessDescription===", businessDescription);
  
      console.log("isApiPartner===", isApiPartner);
      console.log("settlementEmails===", settlementEmails);
      console.log("contractFilePath===", contractFilePath);
  
      console.log("defaultLanguage===", defaultLanguage);
      console.log("defaultBankDetailId===", defaultBankDetailId);
      console.log("addressId===", addressId);
      console.log("userId===", userId);
  
      if(!name){
        setErrorFlag(true);
        setErrorMessage("Name not empty");
        return;
      }
      const saveBusinessDto = {
        name: name,
        foreignName: foreignName,
        businessDescription: businessDescription,
        isApiPartner: isApiPartner,
        settlementEmails: settlementEmails,
        contractFilePath: contractFilePath,
        defaultLanguage: defaultLanguage,
        defaultBankDetailId: defaultBankDetailId,
        addressId: addressId,
        userId: userId,
        phoneNumber: phoneNumber,
        businessIdentificationNumber:businessIdentificationNumber,
        address: address,
        contactName:contactName,
        bankAccount:bankAccount,
        bankName:bankName,
        businessSettlementType,businessSettlementType,
      };

      

      let saveBusinessResponse = await saveBusiness(saveBusinessDto);

      let code = saveBusinessResponse.code;
    
      if(ERROR_CODE_SUCCESS==code) {
        handleSubmit();
      }else{

        setErrorFlag(true);
        setErrorMessage(saveBusinessResponse.message);
      }
  
      
    }catch (e){
      console.log("handleSubmitData====error: " ,e);
    }
    
  };

  const setAddressIdChange = (addressText) => {
    console.log("setAddressIdChange===", addressText);
    if (addressText) {
      setAddressId(addressText.id);
    }
  };

  const setBankDetailIdChange = (bankDetailText) => {
    console.log("setbankDetailIdChange===", bankDetailText);
    if (bankDetailText) {
      setDefaultBankDetailId(bankDetailText.id);
    }
  };

  const setUserIdChange = (userText) => {
    console.log("setUserIdChange===", userText);
    if (userText) {
      setUserId(userText.id);
    }
  };


  const businessSettlementTypeChange = async (event) => {

    console.log("setBusinessSettlementTypeChange===", event.target.value);

    setBusinessSettlementType(event.target.value);
  }

  return (
    // <div>
    <div className={classes.root}>
      <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


      <Grid container spacing={1}>
      {
        errorFlag&&<Grid container item xs={12} spacing={3}>
        <Typography className={classes.errorMessageStyle}>{errorMessage}</Typography>  
      </Grid>
      }
      
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}> <TextField
          required
          
            onChange={(e) => setName(e.target.value)}
            className={classes.field}
            id="standard-disabled"
            label="Company name"
            variant="standard"
            InputProps={{
              inputProps: {
                style: { textAlign: "left" },
              },
            }}
          /></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>

          
         
         <TextField
            id="Phone"
            className={classes.field}
            label="Contact"
            variant="standard"
            onChange={(e) => setPhoneNumber(e.target.value)}
            InputProps={{
              inputProps: {
                margin: 10,
                style: { textAlign: "left" },
              },
            }}
          />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
          
            id="Description"
            className={classes.field}
            label="Remark"
            onChange={(e) => setBusinessDescription(e.target.value)}
            variant="standard"
          />
          </Paper>
        </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}> 
          

          <TextField
          required
            id="settlementEmails"
            className={classes.field}
            label="SettlementEmail"
            variant="standard"
            onChange={(e) => setSettlementEmails(e.target.value)}
            InputProps={{
              inputProps: {
                style: { textAlign: "left" },
              },
            }}
          />
          </Paper>
        </Grid>
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <AddressIdSelect setAddressIdChange={setAddressIdChange} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <BankDetailIdSelect setBankDetailIdChange={setBankDetailIdChange} />
         
          </Paper>
        </Grid> */}
        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
          required
            id="businessIdentificationNumber"
            className={classes.field}
            label="UEN"
            variant="standard"
            onChange={(e) => setBusinessIdentificationNumber(e.target.value)}
            InputProps={{
              inputProps: {
                style: { textAlign: "left" },
              },
            }}
          />
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
            id="contactName"
            className={classes.field}
            label="ContactName"
            variant="standard"
            onChange={(e) => setContactName(e.target.value)}
          />
          </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
            id="address"
            className={classes.field}
            label="Address"
            variant="standard"
            onChange={(e) => setAddress(e.target.value)}
          />
          </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
            id="bankAccount"
            className={classes.field}
            label="BankAccount"
            variant="standard"
            onChange={(e) => setBankAccount(e.target.value)}
          />
          </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>  
          <TextField
            id="bankName"
            className={classes.field}
            label="BankName"
            variant="standard"
            onChange={(e) => setBankName(e.target.value)}
          />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.selectControl}>  
          <InputLabel id="businessSettlementType">SettlementType</InputLabel>
        <Select
        className={classes.selectControl}
          labelId="SettlementType"
          id="businessSettlementType"
          value={businessSettlementType}
          onChange={businessSettlementTypeChange}
        >
        <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="company">Company</MenuItem>
          <MenuItem value="store">Store</MenuItem>
    
        </Select>
          </Paper>
        </Grid>
        
        </Grid>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
          <Button type="submit" variant="outlined" className="btn right">
            Submit
          </Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
          <Button
            variant="outlined"
            onClick={handleAddClose}
            className="btn right"
          >
            close
          </Button>
          </Paper>
        </Grid>
        </Grid>
      </Grid>
        
       
      </form>
    </div>
  );
}
