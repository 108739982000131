import React, { useState } from "react";
import { saveApiConfig } from "../../service/ApiConfigRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    ApiConfigFormBottonControl,
} from './style/ApiConfigStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
});
export default function ApiConfigForm({handleAddClose, handleSubmit}) {
    const classes = userStyles();

    const [status, setStatus] = useState('');
    const [encryptionScheme, setEncryptionScheme] = useState('');
    const [isBased64Encoded, setIsBased64Encoded] = useState('');
    const [isWhitelistArray, setIsWhitelistArray] = useState('');

    const [secretKey, setSecretKey] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [legacyPartnerEmail, setLegacyPartnerEmail] = useState('');
    const [legacyPartnerSecret, setLegacyPartnerSecret] = useState('');
    const [defaultNotificationUrl, setDefaultNotificationUrl] = useState('');
    const [defaultRedirectUrl, setDefaultRedirectUrl] = useState('');
    const [sslCertificateBlob, setSslCertificateBlob] = useState('');
    const [isTestAccount, setIsTestAccount] = useState('');
    const [failedAttemptThreshold, setFailedAttemptThreshold] = useState('');
    const [rateLimitThreshold, setRateLimitThreshold] = useState('');



    const handleSubmitData=async (e) =>{
        e.preventDefault();
        console.log('ApiConfigEditForm====');
        console.log('status===', status);
        console.log('encryptionScheme===', encryptionScheme);
        console.log('isBased64Encoded===', isBased64Encoded);
        console.log('isWhitelistArray===', isWhitelistArray);
        console.log('secretKey===', secretKey);
        console.log('accessToken===', accessToken);
        console.log('legacyPartnerEmail===', legacyPartnerEmail);
        console.log('legacyPartnerSecret===', legacyPartnerSecret);
        console.log('defaultNotificationUrl===', defaultNotificationUrl);
        console.log('defaultRedirectUrl===', defaultRedirectUrl);
        console.log('sslCertificateBlob===', sslCertificateBlob);
        console.log('isTestAccount===', isTestAccount);
        console.log('failedAttemptThreshold===', failedAttemptThreshold);
        console.log('rateLimitThreshold===', rateLimitThreshold);



        const saveApiConfigDto = {
            'status': status,
            'encryptionScheme': encryptionScheme,
            'isBased64Encoded': isBased64Encoded,
            'isWhitelistArray': isWhitelistArray,
            'secretKey': secretKey,
            'accessToken': accessToken,
            'legacyPartnerEmail': legacyPartnerEmail,
            'legacyPartnerSecret': legacyPartnerSecret,
            'defaultNotificationUrl': defaultNotificationUrl,
            'defaultRedirectUrl': defaultRedirectUrl,
            'sslCertificateBlob': sslCertificateBlob,
            'isTestAccount': isTestAccount,
            'failedAttemptThreshold': failedAttemptThreshold,
            'rateLimitThreshold': rateLimitThreshold,
        };
        await saveApiConfig(saveApiConfigDto);

        console.log('===save====');

        handleSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"

                        />

                        <TextField id="encryptionScheme" className={classes.field} label="Encryption Scheme" variant="standard" onChange={((e)=>setEncryptionScheme(e.target.value))} />
                        <TextField id="isBased64Encoded" className={classes.field} label="Is Based64 Encoded" onChange={((e)=> setIsBased64Encoded(e.target.value))} variant="standard" />
                        <TextField id="isWhitelistArray" className={classes.field} label="Is Whitelist Array" onChange={((e)=> setIsWhitelistArray(e.target.value))} variant="standard" />
                        <TextField id="secretKey" className={classes.field} label="Secret Key" onChange={((e)=> setSecretKey(e.target.value))} variant="standard" />
                        <TextField id="accessToken" className={classes.field} label="Access Token" onChange={((e)=> setAccessToken(e.target.value))} variant="standard" />
                        <TextField id="legacyPartnerEmail" className={classes.field} label="Legacy Partner Email" onChange={((e)=> setLegacyPartnerEmail(e.target.value))} variant="standard" />
                        <TextField id="legacyPartnerSecret" className={classes.field} label="Legacy Partner Secret" onChange={((e)=> setLegacyPartnerSecret(e.target.value))} variant="standard"/>
                        <TextField id="defaultNotificationUrl" className={classes.field} label="Default Notification Url" onChange={((e)=> setDefaultNotificationUrl(e.target.value))} variant="standard" />
                        <TextField id="defaultRedirectUrl" className={classes.field} label="Default Redirect Url" onChange={((e)=> setDefaultRedirectUrl(e.target.value))} variant="standard" />
                        <TextField id="sslCertificateBlob" className={classes.field} label="Ssl Certificate Blob" onChange={((e)=> setSslCertificateBlob(e.target.value))} variant="standard" />
                        <TextField id="isTestAccount" className={classes.field} label="Is Test Account" onChange={((e)=> setIsTestAccount(e.target.value))} variant="standard" />
                        <TextField id="failedAttemptThreshold" className={classes.field} label="Failed Attempt Threshold" onChange={((e)=> setFailedAttemptThreshold(e.target.value))} variant="standard" />
                        <TextField id="rateLimitThreshold" className={classes.field} label="Rate Limit Threshold" onChange={((e)=> setRateLimitThreshold(e.target.value))} variant="standard"/>
                    </Box>


                    <ApiConfigFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleAddClose} className="btn right">close</Button>

                    </ApiConfigFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

