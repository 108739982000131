import React, { useState } from "react";
import { updateBankJobDto } from "../../service/BankJobRequest";
import { SignalWifiStatusbar4BarSharp } from "@mui/icons-material";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    BankJobFormBottonControl,
} from './style/BankJobStyle';



const bankJobStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function BankJobEditForm({handleEditClose, handleEditSubmit, editBankJobDto}) {
    const classes = bankJobStyles();

    const [id, setId] = useState(editBankJobDto.id);

    const [bankAccount, setBankAccount] = useState(editBankJobDto.bankAccount);
    const [bankSwiftCode, setBankSwiftCode] = useState(editBankJobDto.bankSwiftCode);
    const [totalAmount, setTotalAmount] = useState(editBankJobDto.totalAmount);
    const [settlementRecordId, setSettlementRecordId] = useState(editBankJobDto.settlementRecordId);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('BankJobForm====');
        console.log('bankAccount===', bankAccount);
        console.log('bankSwiftCode===', bankSwiftCode);
        console.log('totalAmount===', totalAmount);
        console.log('settlementRecordId===', settlementRecordId);


        const saveBankJobDto = {
            'id': id,
            'bankAccount': bankAccount,
            'bankSwiftCode': bankSwiftCode,
            'totalAmount': totalAmount,
            'settlementRecordId': settlementRecordId
        };

        updateBankJobDto(saveBankJobDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setBankAccount(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Bank Account"
                            variant="standard"
                            value={bankAccount}

                        />

                        <TextField id="bankSwiftCode" className={classes.field} label="Bank Swift Code" variant="standard" onChange={((e)=> setBankSwiftCode(e.target.value))} value= {bankSwiftCode} />
                        <TextField id="totalAmount" className={classes.field} label="Total Amount" onChange={((e)=> setTotalAmount(e.target.value))} variant="standard" value={totalAmount} />
                        <TextField id="settlementRecordId" className={classes.field} label="Settlement Record Id" variant="standard" onChange={((e)=> setSettlementRecordId(e.target.value))} value= {settlementRecordId} />
                    
                    </Box>


                    <BankJobFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </BankJobFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

