import request from "./ConfigRequest";

export const pageNegativeBalanceJobDto = (index, size)=>{
    return request({
        url: 'negativeBalanceJob/pageNegativeBalanceJobDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveNegativeBalanceJob = (negativeBalanceJobDto)=>{
    return request({
        url: 'negativeBalanceJob/saveNegativeBalanceJobDto',
        method: 'post',
        data: negativeBalanceJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateNegativeBalanceJobDto = (negativeBalanceJobDto)=>{
    return request({
        url: 'negativeBalanceJob/updateNegativeBalanceJobDto',
        method: 'put',
        data: negativeBalanceJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchNegativeBalanceJobDto = (index, size, id)=>{
    return request({
        url: 'negativeBalanceJob/pageNegativeBalanceJobDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
