import request from "./ConfigRequest";

export const pageDeviceTokenDto = (deviceTokenVo) => {
  return request({
    url: "deviceToken/pageDeviceTokenDto",
    params: deviceTokenVo,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const saveDeviceToken = (deviceTokenDto) => {
  return request({
    url: "deviceToken/saveDeviceTokenDto",
    method: "post",
    data: deviceTokenDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateDeviceTokenDto = (deviceTokenDto) => {
  return request({
    url: "deviceToken/updateDeviceTokenDto",
    method: "put",
    data: deviceTokenDto,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const searchDeviceTokenDto = (index, size, token) => {
  return request({
    url: "deviceToken/pageDeviceTokenDto",
    params: {
      index,
      size,
      token,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};
