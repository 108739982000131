import React, { useState } from "react";
import TypographyPage from "../typography/Typography";
import { updateOneTimePasswordDto } from "../../service/OneTimePasswordRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    OneTimePasswordFormBottonControl,
} from './style/OneTimePasswordStyle';



const OneTimePasswordStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function OneTimePasswordEditForm({handleEditClose, handleEditSubmit, editOneTimePasswordDto}) {
    const classes = OneTimePasswordStyles();

    const [id, setId] = useState(editOneTimePasswordDto.id);

    const [type, setType] = useState(editOneTimePasswordDto.type);
    const [status, setStatus] = useState(editOneTimePasswordDto.status);
    const [channel, setChannel] = useState(editOneTimePasswordDto.channel);
    const [code, setCode] = useState(editOneTimePasswordDto.code);
    const [targetUserId, setTargetUserId] = useState(editOneTimePasswordDto.targetUserId);
    const [emailJobId, setEmailJobId] = useState(editOneTimePasswordDto.emailJobId);

    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('OneTimePasswordForm====');
        console.log('type===', type);
        console.log('status===', status);
        console.log('channel===', channel);
        console.log('code===', code);
        console.log('targetUserId===', targetUserId);
        console.log('emailJobId===', emailJobId);


        const saveOneTimePasswordDto = {
            'id': id,
            'type': type,
            'status': status,
            'channel': channel,
            'code': code,
            'targetUserId': targetUserId,
            'emailJobId': emailJobId,};

        updateOneTimePasswordDto(saveOneTimePasswordDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Type"
                            variant="standard"
                            value={type}

                        />

                        <TextField id="status" className={classes.field} label="Status" variant="standard" onChange={((e)=> setStatus(e.target.value))} value= {status} />
                        <TextField id="channel" className={classes.field} label="Channel" onChange={((e)=> setChannel(e.target.value))} variant="standard" value={channel} />
                        <TextField id="code" className={classes.field} label="Code" onChange={((e)=> setCode(e.target.value))} variant="standard" value={code} />
                        <TextField id="targetUserId" className={classes.field} label="Target User Id" onChange={((e)=> setTargetUserId(e.target.value))} variant="standard" value={targetUserId} />
                        <TextField id="emailJobId" className={classes.field} label="Email Job Id" onChange={((e)=> setEmailJobId(e.target.value))} variant="standard" value={emailJobId} />

                    </Box>


                    <OneTimePasswordFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </OneTimePasswordFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

