import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchUserLikeDto } from "../../service/UserRequest";

const index = 1;
const pageSize = 10;

export default function UserIdSelect({ setUserIdChange, user }) {
  const [userText, setUserText] = useState("");
  const [data, setData] = useState([]);
  const userTextChange = (event, userTextValue) => {
    console.log("userTextValue===", userTextValue);
    if (userTextValue) {
      initUserData(index, pageSize, userTextValue);
    }
  };
  const userTextOnChange = (event, newUserText, detail) => {
    console.log("newUserText===", newUserText);
    setUserText(newUserText);
    setUserIdChange(newUserText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (user) {
      setUserText(user);
    }
    initUserData(index, pageSize, "");
  }, []);

  const initUserData = async (index, pageSize, searchText) => {
    try {
      debugger;
      let userDataResponse = await searchUserLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("userDataResponse===", userDataResponse);
      let responseCode = userDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = userDataResponse.data;
        setData(dataRecord);
      }
    } catch (e) {
      console.log("initUserData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.firstName;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, userTextValue) =>
          userTextChange(event, userTextValue)
        }
        value={userText}
        onChange={(event, newUserText, detail) => {
          userTextOnChange(event, newUserText, detail);
        }}
      />
    </Stack>
  );
}
