import request from './ConfigRequest';
export const pageUserRoleAccessDto = (index, size)=>{
    return request({
        url: 'userRoleAccess/pageUserRoleAccessDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveUserRoleAccess = (userRoleAccessDto)=>{
    return request({
        url: 'userRoleAccess/saveUserRoleAccessDto',
        method: 'post',
        data: userRoleAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateUserRoleAccessDto = (userRoleAccessDto)=>{
    return request({
        url: 'userRoleAccess/updateUserRoleAccessDto',
        method: 'put',
        data: userRoleAccessDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchUserRoleAccessDto = (index, size, userId)=>{
    return request({
        url: 'userRoleAccess/pageUserRoleAccessDto',
        params: {
            index,
            size,
            userId,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
