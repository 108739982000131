import React, { useState } from "react";
import { updateChargeRateDto } from "../../service/ChargeRateRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    ChargeRateFormBottonControl,
} from './style/ChargeRateStyle';



const userStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function ChargeRateEditForm({handleEditClose, handleEditSubmit, editChargeRateDto}) {
    const classes = userStyles();

    debugger;
    console.log('editChargeRateDto==', editChargeRateDto.id);
    const [id, setId] = useState(editChargeRateDto.id);
    const [storeId, setStoreId] = useState(editChargeRateDto.storeId);
    const [acquirerCode, setAcquirerCode] = useState(editChargeRateDto.acquirerCode);


    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('ChargeRateEditForm====');
        console.log('storeId===', storeId);
        console.log('acquirerCode===', acquirerCode);


        const saveChargeRateDto = {
            'id': id,
            'storeId': storeId,
            'acquirerCode': acquirerCode,
        };
        updateChargeRateDto(saveChargeRateDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>

                    <Box>
                        <TextField id="storeId" className={classes.field} label="Store Id" variant="standard" onChange={((e)=> setStoreId(e.target.value))} value = {storeId}/>
                        <TextField id="acquirerCode" className={classes.field} label="Acquirer Code" variant="standard" onChange={((e)=> setAcquirerCode(e.target.value))} value = {acquirerCode}/>
                    </Box>


                    <ChargeRateFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </ChargeRateFormBottonControl>

                </form>
            </Container>
        </div>
    );
}

