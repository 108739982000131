import request from "./ConfigRequest";

export const pageSettlementRecordDto = (index, size)=>{
    return request({
        url: 'settlementRecord/pageSettlementRecordDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveSettlementRecord = (settlementRecordDto)=>{
    return request({
        url: 'settlementRecord/saveSettlementRecordDto',
        method: 'post',
        data: settlementRecordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateSettlementRecordDto = (settlementRecordDto)=>{
    return request({
        url: 'settlementRecord/updateSettlementRecordDto',
        method: 'put',
        data: settlementRecordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchSettlementRecordDto = (index, size, id)=>{
    return request({
        url: 'settlementRecord/pageSettlementRecordDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
