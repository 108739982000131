import React, { useState } from "react";
import { updateTransactionDto } from "../../service/TransactionRequest";

import {TextField, Button,Box, Container,makeStyles,Grid,Paper
  } from "@material-ui/core";
import  {
    TransactionFormBottonControl
    } from './style/TransactionStyle';


import {PAYMENT_STATUS_SUCCESS,PAYMENT_REFUND_STATUS_PARTIAL_REFUND} from "../../components/common/constant/ManageConstant";

import {transactionRefund} from "../../service/TransactionRequest";
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";



  const userStyles = makeStyles((theme) =>({
      field:{
          marginTop:20,
          marginBottom:10,
        
      },
      hidden:{
        display:"none",
      },
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      },
  }))
export  default function TransactionRefundForm ({handleRefundClose,handleRefundSubmit,refundTransactionDto}){
   
        const classes = userStyles();

       
        console.log("TransactionRefundForm==",refundTransactionDto.id);
        const [id,setId] = useState(refundTransactionDto.id);
        const [paymentStatus,setPaymentStatus] = useState(refundTransactionDto.paymentStatus);
        const [refundStatus,setRefundStatus] =useState(refundTransactionDto.refundStatus);
        const [amount,setAmount] = useState(refundTransactionDto.amount);
        const [errorMessage,setErrorMessage] = useState();

        
 
      
        const handleSubmitData=async (e) =>{
            e.preventDefault();
            console.log("TransactionEditForm====")

            console.log("paymentStatus===",paymentStatus)
            

            
        
            let saveTransactionDto = {
                    "id":id,
                    "amount":amount
            }
            let refundResponseDto = await transactionRefund(saveTransactionDto);


            let code = refundResponseDto.code;

            if(ERROR_CODE_SUCCESS == code){
              handleRefundSubmit();
            }else{
              let errorMessage = refundResponseDto.message;
              setErrorMessage(errorMessage);
            }
            
        }
        return (
            <div className={classes.root}>
                    <form  noValidate autoComplete="off" onSubmit={handleSubmitData}>
                    <TextField id="id" hidden value={id} className={classes.hidden}/>


<Grid
  container
  spacing={3}
>
        <Grid item xs={4}>
          PaymentStatus<Paper className={classes.paper}>
          {paymentStatus}  </Paper>
        </Grid>
        <Grid item xs={4} >
          
          <TextField
                        onChange={(e=> setAmount(e.target.value))}
                        className={classes.paper}
                        id="standard-disabled"
                        label="Amount"
                        variant="standard"
                        value={amount}
                       
                        />
          
        </Grid>
        {errorMessage && (
          <Grid item xs={4}>
          PaymentStatus<Paper className={classes.paper}>
          {paymentStatus}  </Paper>
        </Grid>

        )} 
        

    </Grid>
                    <Box>
                   
                    </Box>
            
                    
                    <TransactionFormBottonControl>

                        {paymentStatus && paymentStatus==PAYMENT_STATUS_SUCCESS &&!refundStatus &&(<Button type="submit" variant="outlined" className="btn right">Refund</Button>)   }
                        {paymentStatus && paymentStatus==PAYMENT_STATUS_SUCCESS && refundStatus && refundStatus==PAYMENT_REFUND_STATUS_PARTIAL_REFUND  && (<Button type="submit" variant="outlined" className="btn right">Refund</Button>)   }
                        
                        
                        <Button variant ="outlined" onClick={handleRefundClose} className="btn right">close</Button>

                    </TransactionFormBottonControl>
                       
                    </form>
                
            </div>
        );
   
}

  