import React, { useState } from "react";
import { updateRegionDto } from "../../service/RegionRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    RegionFormBottonControl,
} from './style/RegionStyle';



const regionStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function RegionEditForm({handleEditClose, handleEditSubmit, editRegionDto}) {
    const classes = regionStyles();

    const [id, setId] = useState(editRegionDto.id);

    const [shortCode, setShortCode] = useState(editRegionDto.shortCode);
    const [name, setName] = useState(editRegionDto.name);
    const [defaultCurrency, setDefaultCurrency] = useState(editRegionDto.defaultCurrency);
    const [defaultLanguage, setDefaultLanguage] = useState(editRegionDto.defaultLanguage);


    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('RegionForm====');
        console.log('name===', name);
        console.log('shortCode===', shortCode);
        console.log('defaultCurrency===', defaultCurrency);
        console.log('defaultLanguage===', defaultLanguage);


        const saveRegionDto = {
            'id': id,
            'shortCode': shortCode,
            'name': name,
            'defaultCurrency': defaultCurrency,
            'defaultLanguage': defaultLanguage,};

        updateRegionDto(saveRegionDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="name"
                            variant="standard"
                            value={name}

                        />

                        <TextField id="shortCode" className={classes.field} label="Short Code" variant="standard" onChange={((e)=> setShortCode(e.target.value))} value= {shortCode} />
                        <TextField id="defaultCurrency" className={classes.field} label="Default Currency" onChange={((e)=> setDefaultCurrency(e.target.value))} variant="standard" value={defaultCurrency} />
                        <TextField id="defaultLanguage" className={classes.field} label="Default Language" onChange={((e)=> setDefaultLanguage(e.target.value))} variant="standard" value={defaultLanguage} />
                    </Box>


                    <RegionFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </RegionFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

