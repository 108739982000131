import request from "./ConfigRequest";

export const pageEmailJobDto = (index, size)=>{
    return request({
        url: 'emailJob/pageEmailJobDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveEmailJob = (emailJobDto)=>{
    return request({
        url: 'emailJob/saveEmailJobDto',
        method: 'post',
        data: emailJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateEmailJobDto = (emailJobDto)=>{
    return request({
        url: 'emailJob/updateEmailJobDto',
        method: 'put',
        data: emailJobDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchEmailJobDto = (index, size, id)=>{
    return request({
        url: 'emailJob/pageEmailJobDto',
        params: {
            index,
            size,
            id,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
