import request from "./ConfigRequest";

export const pageOneTimePasswordDto = (index, size)=>{
    return request({
        url: 'oneTimePassword/pageOneTimePasswordDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveOneTimePassword = (oneTimePasswordDto)=>{
    return request({
        url: 'oneTimePassword/saveOneTimePasswordDto',
        method: 'post',
        data: oneTimePasswordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateOneTimePasswordDto = (oneTimePasswordDto)=>{
    return request({
        url: 'oneTimePassword/updateOneTimePasswordDto',
        method: 'put',
        data: oneTimePasswordDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchOneTimePasswordDto = (index, size, code)=>{
    return request({
        url: 'oneTimePassword/pageOneTimePasswordDto',
        params: {
            index,
            size,
            code,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
