import React, { useState } from "react";
import { updateUserDto } from "../../service/UserRequest";

import {TextField,
    Button, Box, Container, makeStyles,MenuItem,Select,Grid,Paper
} from '@material-ui/core';
import {
    UserFormBottonControl,
} from './style/UserStyle';

const userStyles = makeStyles((theme) => ({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    
    divider: {
        margin: theme.spacing(2, 0),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    }));

 
export default function UserEditForm({handleEditClose, handleEditSubmit, editUserDto}) {
    const classes = userStyles();

    const [id, setId] = useState(editUserDto.id);

    const [type, setType] = useState(editUserDto.type);
    const [title, setTitle] = useState(editUserDto.title);
    const [firstName, setFirstName] = useState(editUserDto.firstName);
    const [lastName, setLastName] = useState(editUserDto.lastName);
    const [foreignName, setForeignName] = useState(editUserDto.foreignName);
    const [preferredLanguage, setPreferredLanguage] = useState(editUserDto.preferredLanguage);
    const [phoneNumber, setPhoneNumber] = useState(editUserDto.phoneNumber);
    const [emailAddress, setEmailAddress] = useState(editUserDto.emailAddress);
    const [loginPassword, setLoginPassword] = useState(editUserDto.loginPassword);

    const [enabledTwoFactor, setEnabledTwoFactor] = useState('');
    const [addressId, setAddressId] = useState('');
    const [typeOpen,setTypeOpen] = useState(false);
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('UserForm====');
        console.log('type===', type);
        console.log('title===', title);
        console.log('firstName===', firstName);
        console.log('lastName===', lastName);
        console.log('foreignName===', foreignName);
        console.log('preferredLanguage===', preferredLanguage);
        console.log('phoneNumber===', phoneNumber);
        console.log('emailAddress===', emailAddress);
        console.log('loginPassword===', loginPassword);

        console.log('enabledTwoFactor===', enabledTwoFactor);
        console.log('addressId===', addressId);



        const saveUserDto = {
            'id': id,
            'type': type,
            'title': title,
            'firstName': firstName,
            'lastName': lastName,
            'foreignName': foreignName,
            'preferredLanguage': preferredLanguage,
            'phoneNumber': phoneNumber,
            'emailAddress': emailAddress,
            'loginPassword': loginPassword,
            'enabledTwoFactor': enabledTwoFactor,
            'addressId': addressId,};

        updateUserDto(saveUserDto);

        handleEditSubmit();
    };

    const handleTypeClose = () => {
        setTypeOpen(false);
      };

      const handleTypeOpen = () => {
        setTypeOpen(true);
      };

      const handleTypeChange = (event) => {
        setType(event.target.value);
      };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>

                <Grid container spacing={3}>

                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        {/* <TextField
                            onChange={((e)=> setType(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="type"
                            variant="standard"
                            value={type}

                        /> */}

                        <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={typeOpen}
                                    onClose={handleTypeClose}
                                    onOpen={handleTypeOpen}
                                    value={type}
                                    onChange={handleTypeChange}
                                    >
                                   
                                    <MenuItem value="company">company</MenuItem>
                                    <MenuItem value="manage">manage</MenuItem>
                                   
                                    </Select>
                        </Paper>
                </Grid>

                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField
                            onChange={((e)=> setTitle(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="title"
                            variant="standard"
                            value={title}

                        />
                        </Paper>
                </Grid>

                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField
                            onChange={((e)=> setFirstName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="firstName"
                            variant="standard"
                            value={firstName}

                        />
                        </Paper>
                </Grid>





                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField
                            onChange={((e)=> setLastName(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="lastName"
                            variant="standard"
                            value={lastName}

                        />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="foreignName" className={classes.field} label="ForeignName" variant="standard" onChange={((e)=> setForeignName(e.target.value))} value= {foreignName} />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="preferredLanguage" className={classes.field} label="PreferredLanguage" onChange={((e)=> setPreferredLanguage(e.target.value))} variant="standard" value={preferredLanguage} />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="phoneNumber" className={classes.field} label="PhoneNumber" onChange={((e)=> setPhoneNumber(e.target.value))} variant="standard" value={phoneNumber} />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="emailAddress" className={classes.field} label="EmailAddress" onChange={((e)=> setEmailAddress(e.target.value))} variant="standard" value={emailAddress} />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="loginPassword" className={classes.field} label="loginPassword" onChange={((e)=> setLoginPassword(e.target.value))} variant="standard" value={loginPassword} />
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="enabledTwoFactor" className={classes.field} label="EnabledTwoFactor" variant="standard" onChange={((e)=> setEnabledTwoFactor(e.target.value))}/>
                        </Paper>
                </Grid>


                <Grid item xs={4}>
                        <Paper className={classes.paper}>
                        <TextField id="addressId" className={classes.field} label="AddressId" variant="standard" onChange={((e)=> setAddressId(e.target.value))} />
                        </Paper>
                </Grid>

                </Grid>
                  
 


                    <UserFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </UserFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

