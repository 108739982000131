import request from './ConfigRequest';
export const pageAcquirerDto = (index, size)=>{
    return request({
        url: 'acquirer/pageAcquirerDto',
        params: {
            index,
            size,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};


export const saveAcquirer = (acquirerDto)=>{
    return request({
        url: 'acquirer/saveAcquirerDto',
        method: 'post',
        data: acquirerDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const updateAcquirerDto = (acquirerDto)=>{
    return request({
        url: 'acquirer/updateAcquirerDto',
        method: 'put',
        data: acquirerDto,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const searchAcquirerDto = (index, size, acquirerName)=>{
    return request({
        url: 'acquirer/pageAcquirerDto',
        params: {
            index,
            size,
            acquirerName,
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });
};
