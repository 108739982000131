import React, { useState } from "react";
import { updateItemDetailDto } from "../../service/ItemDetailRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    ItemDetailFormBottonControl,
} from './style/ItemDetailStyle';



const itemdetailStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function ItemDetailEditForm({handleEditClose, handleEditSubmit, editItemDetailDto}) {
    const classes = itemdetailStyles();

    const [id, setId] = useState(editItemDetailDto.id);

    const [itemType, setItemType] = useState(editItemDetailDto.itemType);
    const [itemCode, setItemCode] = useState(editItemDetailDto.itemCode);
    const [itemQuantity, setItemQuantity] = useState(editItemDetailDto.itemQuantity);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('ItemDetailForm====');
        console.log('itemType===', itemType);
        console.log('itemCode===', itemCode);
        console.log('itemQuantity===', itemQuantity);


        const saveItemDetailDto = {
            'id': id,
            'itemType': itemType,
            'itemCode': itemCode,
            'itemQuantity': itemQuantity
        };

        updateItemDetailDto(saveItemDetailDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setItemCode(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Item Code"
                            variant="standard"
                            value={itemCode}

                        />

                        <TextField id="itemType" className={classes.field} label="Item Type" variant="standard" onChange={((e)=> setItemType(e.target.value))} value= {itemType} />
                        <TextField id="itemQuantity" className={classes.field} label="Item Quantity" onChange={((e)=> setItemQuantity(e.target.value))} variant="standard" value={itemQuantity} />
                    
                    </Box>


                    <ItemDetailFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </ItemDetailFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

