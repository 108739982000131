import axios from "axios";

import * as JSONBig from "json-bigint";

import {
  BASE_URL,
  TIMEOUT,
} from "../components/common/configuration/ConfigEnvironment";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  transformResponse: (data) => {
    try {
      // 如果转换成功，则直接把结果返回

      return JSONBig.parse(data);
    } catch (err) {
      console.log("parse fail", err);
      // 如果转换失败了，则进入这里
      // 我们在这里把数据原封不动的直接返回给请求使用
      return data;
    }
  },
});

instance.interceptors.request.use(
  (config) => {
    // 1.发送网络请求时, 在界面的中间位置显示Loading的组件

    // 2.某一些请求要求用户必须携带token, 如果没有携带, 那么直接跳转到登录页面

    // 3.params/data序列化的操作
    const loginToken = localStorage.getItem("login");

    if (loginToken) {
      config.headers["Authorization"] = `Bearer ${loginToken}`;
    }

  
    return config;
  },
  (err) => {},
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          console.log("request error");
          break;
        case 401:
          console.log("unauthorize");
          break;
        default:
          console.log("other error");
      }
    }
    return err;
  },
);

export default instance;
