import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { pageTransactionDto, searchTransactionDto, updateTransactionDto } from "../../service/TransactionRequest";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialTransaction = null;

const transactionSlice = createSlice({
    name: "transaction",
  initialState: {
    transaction: initialTransaction,
  },
  reducers: {
    pageTransactionSuccess: (state, action) => {
        console.log("name====", action);
    },
    searchTransactionSuccess: (state, action) => {
    },
    updateTransactionSuccess: (state, action) => {
    },
  },
});

export default transactionSlice.reducer;

const { pageTransactionSuccess, updateTransactionSuccess, searchTransactionSuccess} = transactionSlice.actions;

export const pageTransaction = ({
    index,
    pageSize,
  }) => async (dispatch) => {
    try {
        let pageTransactionResponse = await pageTransactionDto(index, pageSize);
        console.log("pageTransactionResponse", pageTransactionResponse);
        debugger;
        dispatch(pageTransactionSuccess());
    } catch (e) {
      return console.error(e.message);
    }
  };

  export const updateTransaction = ({
    editTransactionDto,
  }) => async (dispatch) => {
    try {
      let updateTransactionResponse = await updateTransactionDto(editTransactionDto);
      console.log("updateTransactionDto", updateTransactionResponse);
      dispatch(updateTransactionSuccess());
    } catch (e) {
      return console.error(e.message);
    }
  };

  export const searchTransaction = ({
    index,
    pageSize,
    searchName,
  }) => async (dispatch) => {
    try {
      ///createAsyncThunk()
    } catch (e) {
      return console.error(e.message);
    }
  };


  