import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchRegionLikeDto } from "../../service/RegionRequest";

const index = 1;
const pageSize = 10;

export default function RegionIdSelect({ setRegionIdChange, region }) {
  const [regionText, setRegionText] = useState("");
  const [data, setData] = useState([]);
  const regionTextChange = (event, regionTextValue) => {
    console.log("regionTextValue===", regionTextValue);
    if (regionTextValue) {
      initRegionData(index, pageSize, regionTextValue);
    }
  };
  const regionTextOnChange = (event, newRegionText, detail) => {
    console.log("newRegionText===", newRegionText);
    setRegionText(newRegionText);
    setRegionIdChange(newRegionText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (region) {
      setRegionText(region);
    }
    initRegionData(index, pageSize, "");
  }, []);

  const initRegionData = async (index, pageSize, searchText) => {
    try {
      debugger;
      let regionDataResponse = await searchRegionLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("regionDataResponse===", regionDataResponse);
      let responseCode = regionDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = regionDataResponse.data;
        setData(dataRecord);
      }
    } catch (e) {
      console.log("initRegionData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Region info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, regionTextValue) =>
          regionTextChange(event, regionTextValue)
        }
        value={regionText}
        onChange={(event, newRegionText, detail) => {
          regionTextOnChange(event, newRegionText, detail);
        }}
      />
    </Stack>
  );
}
