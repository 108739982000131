import React, { useState } from "react";
import { updateStoreSettlementSummaryDto } from "../../service/StoreSettlementSummaryRequest";

import {TextField,
    Button, Box, Container, makeStyles,
} from '@material-ui/core';
import {
    StoreSettlementSummaryFormBottonControl,
} from './style/StoreSettlementSummaryStyle';


const storesettlementsummaryStyles = makeStyles({
    field: {
        marginTop: 20,
        marginBottom: 10,

    },
    hidden: {
        display: 'none',
    },
});
export default function StoreSettlementSummaryEditForm({handleEditClose, handleEditSubmit, editStoreSettlementSummaryDto}) {
    const classes = storesettlementsummaryStyles();

    const [id, setId] = useState(editStoreSettlementSummaryDto.id);

    const [status, setStatus] = useState(editStoreSettlementSummaryDto.status);
    
    const handleSubmitData=(e) =>{
        e.preventDefault();
        console.log('StoreSettlementSummaryForm====');
        console.log('status===', status);


        const saveStoreSettlementSummaryDto = {
            'id': id,
            'status': status
        };

        updateStoreSettlementSummaryDto(saveStoreSettlementSummaryDto);

        handleEditSubmit();
    };
    return (
        <div>


            <Container >


                <form noValidate autoComplete="off" onSubmit={handleSubmitData}>


                    <Box>
                        <TextField
                            onChange={((e)=> setStatus(e.target.value))}
                            className={classes.field}
                            id="standard-disabled"
                            label="Status"
                            variant="standard"
                            value={status}

                        />
                    </Box>


                    <StoreSettlementSummaryFormBottonControl>
                        <Button type="submit" variant="outlined" className="btn right">Submit</Button>
                        <Button variant ="outlined" onClick={handleEditClose} className="btn right">close</Button>

                    </StoreSettlementSummaryFormBottonControl>

                </form>
            </Container>

        </div>
    );
}

