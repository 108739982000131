import BankCodeEditForm from "./BankCodeEditForm";
import BankCodeAddForm from "./BankCodeAddForm";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  makeStyles,
  Grid,
  Input,
} from "@material-ui/core";

import {
  pageBankCodeDto,
  searchBankCodeDto,
} from "../../service/BankCodeRequest";

import { BankCodeAddButtonControl } from "./style/BankCodeStyle";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function BankCodeTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editBankCodeDto, setEditBankCodeDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [bankCodeLike,setBankCodeLike] = useState();
  const [bankNameLike, setBankNameLike] = useState();


  const classes = useStyles();
  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      bankCodeLike: '',
      bankNameLike:'',
    }

    
  });

  const searchBankCode = () => {
    console.log("======searchBankDetail", searchName);
    setSearchRefreshFlag(true);
  };

  const addBankCode = () => {
    console.log("======handle add addBankDetail");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
    debugger;

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const handleSubmitData = () => {
    debugger;
    console.log("==handleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
 
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
 
    console.log("=changePage==page", page);
    page = page + 1;

    let bankAccountQueryVo = {
      index:1,
      pageSize:page,
      bankNameLike:  bankNameLike ? bankNameLike : null,
      bankCodeLike:  bankCodeLike ? bankCodeLike : null,
    }

    initData(bankAccountQueryVo);
  };

  const editBankCodeData = (tableMeta) => {
  
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editBankCodeDto = data[row];
    setEditBankCodeDto(editBankCodeDto);
  };
  console.log("count===", count);
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async (bankAccountQueryVo) => {
    setIsLoading(true);


    try{


      let bankAccountResponseDto = await pageBankCodeDto(bankAccountQueryVo);
      console.log("===bankAccountResponseDto=data", bankAccountResponseDto);
        if (bankAccountResponseDto && bankAccountResponseDto.data) {
          setData(bankAccountResponseDto.data.records);
          setCount(bankAccountResponseDto.data.total);
        } 
    }catch(e){
      console.log("initData====error====", e);
    }
     setPage(bankAccountQueryVo.index);
     setIsLoading(false);
  };

  const searchBankCodeData = (index, pageSize, searchName) => {
    setIsLoading(true);
 
    searchBankCodeDto(index, pageSize, searchName).then((res) => {
      // const albums = res.albums;
      console.log("====data", res.data);
      if (res && res.data) {
        setData(res.data.records);
        setCount(res.data.total);
      }

      setIsLoading(false);

      setPage(index);
    });
    setIsLoading(false);
  };

  const bankAccountSubmit = (data) =>{


      console.log("==bankAccountSubmit==data", data);

      setBankCodeLike(data.bankCodeLike);
      setBankNameLike(data.bankNameLike);



      let bankAccountQueryVo = {
        index:1,
        pageSize:10,
        bankNameLike: data.bankNameLike ? data.bankNameLike : null,
        bankCodeLike: data.bankCodeLike ? data.bankCodeLike : null,
      }

      initData(bankAccountQueryVo);
  }


  const bankAccountReset = () =>{
    reset();
    setBankCodeLike();
    setBankNameLike();
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let bankAccountQueryVo = {
      index:1,
      pageSize:10,
 
    }
    initData(bankAccountQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
    console.log("===searchBankDetailData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchBankCodeData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      {
        name: "bankName",
        label: "Bank Name",
        options: {},
      },
      {
        name: "bankCode",
        label: "Bank Code",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            console.dir(tableMeta);
            return value;
          },
        },
      },
      {
        name: "bankType",
        label: "Bank Type",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },

      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => editBankCodeData(tableMeta)}
                >
                  Edit
                </Button>
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
       <form >
       <Grid container spacing={3}>
          <Grid item xs={4}>

            <Paper className={classes.paper}>Bank name:
              <Controller
              name="bankNameLike"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
            </Paper>
          </Grid>

          <Grid item xs={4}>

            <Paper className={classes.paper}>Bank code:
              <Controller
              name="bankCodeLike"
              control={control}
              render={({ field }) => <Input {...field} />}
              />
            </Paper>
          </Grid>


          <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(bankAccountSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => bankAccountReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>

        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => addBankCode()} variant={"outlined"} color="primary">Add</Button></Paper>
        </Grid>
       </Grid>
       </form>
      
 

      <MUIDataTable
        title={
          <Typography variant="h6">
            BankDetail list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit BankCode</DialogTitle>
        <DialogContent>
          <BankCodeEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editBankCodeDto={editBankCodeDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg">
        <DialogTitle>Add BankCode</DialogTitle>
        <DialogContent>
          <Paper>
            <BankCodeAddForm
              handleSubmit={handleSubmitData}
              handleAddClose={handleAddClose}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
